import { SxProps } from '@mui/material/styles';

export const styles = {
  boxContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    maxHeight: '100%',
    p: 8,
    ':focus-visible': {
      outline: 'none'
    }
  },
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background.paper',
    width: 800,
    borderRadius: 1,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  title: {
    margin: 0,
    p: 3,
    borderBottom: '1px solid rgb(31, 50, 62, 0.3)',
    textAlign: 'center',
  },
  body: {
    py: 4,
    px: 6,
    overflow: 'auto',
  },
  agreeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: 8,
    p: 3,
    borderTop: '1px solid rgb(31, 50, 62, 0.3)',
  },
  acceptButton: {
    background: 'rgb(235,71,35)',
    color: 'white',
    px: 6,
    borderRadius: 8,
  },
} satisfies Record<string, SxProps>;
