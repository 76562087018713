import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { SxProps } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const linkStyle: SxProps = {
    transition: '.2s ease',
    '&:hover': {
      color: 'hsl(17, 100%, 70%)',
    },
  };

  return (
    <Stack bgcolor="#1f323e" width="100%" alignItems="center" p={2} gap={1}>
      <Typography color="white" fontSize={10} align="center">
        © RP Data Pty Limited, trading as CoreLogic Asia Pacific (ABN 67 087 759
        171) and CoreLogic NZ Limited (NZCN 1129102) {new Date().getFullYear()}. All rights reserved.
      </Typography>

      <Stack
        direction="row"
        divider={
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: 'white' }}
          />
        }
        gap={1}
      >
        <Link to="/terms-and-conditions">
          <Typography color="white" fontSize={10} sx={linkStyle}>
            Terms and Conditions
          </Typography>
        </Link>

        <Link
          to="https://www.corelogic.com.au/legals/privacy-policy"
          target="_blank"
        >
          <Typography color="white" fontSize={10} sx={linkStyle}>
            Privacy Policy
          </Typography>
        </Link>

        <Link to="/contact-us">
          <Typography color="white" fontSize={10} sx={linkStyle}>
            Contact Us
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};
