import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import { TermsAndConditionsBody } from './TermsAndConditionsBody';
import { styles } from './TermsAndConditions.styles';

type TermsAndConditionsProps = {
  open: boolean;
  onClose: () => void;
  acceptLabel?: string;
  onAccept?: () => any;
};

export const TermsAndConditionsModal = ({
  open,
  acceptLabel,
  onClose,
  onAccept,
}: TermsAndConditionsProps) => {
  const [agreed, setAgreed] = useState(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.box}>
          <IconButton data-testid="terms-and-conditions-close-button" size="small" sx={styles.closeButton} onClick={onClose}>
            <Close />
          </IconButton>

          <Typography sx={styles.title} variant="h4">
            Terms and Conditions
          </Typography>

          <Box sx={styles.body}>
            <TermsAndConditionsBody />
          </Box>

          {acceptLabel && onAccept && (
            <FormGroup sx={styles.agreeContainer}>
              <Stack direction="row">
                <FormControlLabel
                  label="Accept Terms and Conditions"
                  control={
                    <Checkbox
                      data-testid="accept-terms-and-conditions-checkbox"
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                    />
                  }
                />

                <Button
                  sx={styles.acceptButton}
                  variant="contained"
                  disabled={!agreed}
                  onClick={() => {
                    onAccept();
                    onClose();
                  }}
                >
                  {acceptLabel}
                </Button>
              </Stack>
            </FormGroup>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
