import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../../stores/rootStore';

export const EnvironmentDetailsPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | Environment details | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Environment details
          </Typography>

          <Typography fontWeight={500}>
            Learn about our three different environments.
          </Typography>

          <br />
          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Sandbox
          </Typography>

          <br />

          <Typography variant="body2">
            Use the sandbox to develop your ideas and explore our APIs, before
            you progress to a test environment. These APIs are identical to
            production with a limited data set and usage request quota.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Sandbox Data Limits
          </Typography>

          <br />

          <Typography variant="body2">
            Our sandbox includes property-related data for a limited subset of
            properties. These properties are distributed across 29 suburbs (3
            suburbs per state in Australia and 5 suburbs for New Zealand) with
            about 1,000 properties in each suburb.
          </Typography>

          <br />

          <Typography variant="body2">
            Our sandbox also includes limited market trends data. This data
            includes geographic location types, including suburb, postcode,
            local government area and territory authority (in NZ). This data is
            limited to the past three years for the suburb, postcode, local
            government area and territory authority and one year for state and
            country. This data is sourced from the same 29 suburbs used for
            property-related data.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Sandbox API Limits
          </Typography>

          <br />

          <Typography variant="body2">
            Our sandbox mirrors the APIs found on our UAT and production
            servers. However, our sandbox does not include the following API
            collections:
          </Typography>

          <ul>
            <li>AVM - origination type</li>
            <li>Geospatial services</li>
            <li>Property Monitor and Notification</li>
            <li>Property Owner Verification</li>
            <li>Update</li>
          </ul>

          <Typography variant="body2">
            You will be able to access these services when we promote you to the
            UAT environment.
          </Typography>

          <br />

          <Typography variant="body2">
            For more information on how to integrate our APIs into your
            application, refer to the API documentation.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Included Sandbox API Services
          </Typography>

          <br />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Application
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Domain
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Access Authentication service</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/access</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Commercial API</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/commercial-api
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Construction API</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/construction-api
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>Cordell Sum Sure</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/sumsure/au
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    https://api-sbox.corelogic.asia/sumsure/nz
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Market Trends Charts</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/charts</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Market Trends Statistics</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/statistics
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Location Search Services</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/location
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>IntelliVal AVM Services</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/avm</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Places API</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/places</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Details services</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/property-details
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>
                    Property services (deprecated)
                  </TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/property/au
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    https://api-sbox.corelogic.asia/property/nz
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Timeline services</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/property-timeline
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property profile report services</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/property-profile-report
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Risks</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/risk</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Search services</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/search</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Building Consent Services</TableCell>
                  <TableCell>
                    https://api-sbox.corelogic.asia/building-consent
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Suggest title and owner services</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/suggest</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Title details services</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/titles</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content services</TableCell>
                  <TableCell>https://api-sbox.corelogic.asia/content</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Geospatial Services</TableCell>
                  <TableCell rowSpan={4}>
                    Sandbox environment not applicable for these applications.
                    <br />
                    <br />
                    You will be given access to the UAT environment for
                    integration and testing purposes once you have been
                    on-boarded and authorised.
                    <br />
                    <br />
                    Talk to us if you want to use any of these services.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Update services</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notification services</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Verification services</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            CoreLogic API Services - UAT and Production
          </Typography>

          <br />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Application
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Production
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    UAT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Access Authentication service</TableCell>
                  <TableCell>https://api.corelogic.asia/access</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/access</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Commercial API</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/commercial-api
                  </TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/commercial-api
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Construction API</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/construction-api
                  </TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/construction-api
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>Cordell Sum Sure</TableCell>
                  <TableCell>https://api.corelogic.asia/sumsure/au</TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/sumsure/au
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>https://api.corelogic.asia/sumsure/nz</TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/sumsure/nz
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content Legal & Disclaimers</TableCell>
                  <TableCell>https://api.corelogic.asia/content</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/content</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>IntelliVal AVM Services</TableCell>
                  <TableCell>https://api.corelogic.asia/avm</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/avm</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Location Search Services</TableCell>
                  <TableCell>https://api.corelogic.asia/location</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/location</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2}>
                    Market Trends Charts & Statistics
                  </TableCell>
                  <TableCell>https://api.corelogic.asia/charts</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/charts</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>https://api.corelogic.asia/statistics</TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/statistics
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Owner Verfication services</TableCell>
                  <TableCell>https://api.corelogic.asia/verification</TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/verification
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notification services</TableCell>
                  <TableCell>https://api.corelogic.asia/notification</TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/notification
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Places services</TableCell>
                  <TableCell>https://api.corelogic.asia/places</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/places</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Property Details services</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/property-details
                  </TableCell>
                  <TableCell>
                    https://api-ua  t.corelogic.asia/property-details
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Timeline services</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/property-timeline
                  </TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/property-timeline
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Profile Report services</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/property-profile-report
                  </TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/property-profile-report
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property Risk services</TableCell>
                  <TableCell>https://api.corelogic.asia/risk</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/risk</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Property services (deprecated)</TableCell>
                  <TableCell>https://api.corelogic.asia/property</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/property</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Search services</TableCell>
                  <TableCell>https://api.corelogic.asia/search</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/search</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Update services</TableCell>
                  <TableCell>https://api.corelogic.asia/update</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/update</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Building Consent Services</TableCell>
                  <TableCell>
                    https://api.corelogic.asia/building-consent
                  </TableCell>
                  <TableCell>
                    https://api-uat.corelogic.asia/building-consent
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Suggest title and owner services</TableCell>
                  <TableCell>https://api.corelogic.asia/suggest</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/suggest</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NZ Title details services</TableCell>
                  <TableCell>https://api.corelogic.asia/titles</TableCell>
                  <TableCell>https://api-uat.corelogic.asia/titles</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
