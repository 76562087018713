import { Entity } from '@backstage/catalog-model';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios, { AxiosRequestConfig } from 'axios';

export const getEntities = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  filters: string,
) => {
  const url = await discoveryApi.getBaseUrl('catalog');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/entities?filter=${filters}`, config);

  return response.data as Entity[];
};
