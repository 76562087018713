import React, { useEffect } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { CatalogIndexPage } from '@backstage/plugin-catalog';
import { apis } from './plugins';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInProviderConfig,
  AutoLogout,
} from '@backstage/core-components';
import { UnifiedThemeProvider } from '@backstage/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { corelogicTheme } from './theme';
import { oidcAuthApiRef } from './plugins/oidc-auth-provider';
import { Root } from './components/root/Root';
import { entityPage } from './components/entity/EntityPage';
import { ProductEntityPage } from './components/entity/ProductEntityPage';
import { ApiEntityPage } from './components/entity/ApiEntityPage';
import { SignupPage } from './components/signup';
import { ApplicationsPage } from './components/applications';
import { CreateApplicationPage } from './components/applications/CreateApplicationPage';
import { GettingStartedPage } from './components/getting-started/GettingStartedPage';
import { TermsAndConditionsPage } from './components/terms-and-conditions/TermsAndConditionsPage';
import { ProductsPage } from './components/products/ProductsPage';
import { ContactUsPage } from './components/contact-us/ContactUsPage';
import { ApiPage } from './components/api/ApiPage';
import { SignInPage } from './components/signin/SignInPage';
import { ErrorPage } from './components/error/ErrorPage';
import { StandardsAndConventionsPage } from './components/guides/standards-and-conventions/StandardsAndConventionsPage';
import { QuickStartPage } from './components/guides/quick-start/QuickStartPage';
import { EnvironmentDetailsPage } from './components/guides/environment-details/EnvironmentDetailsPage';
import { SandboxTestDataPage } from './components/guides/sandbox-test-data/SandboxTestDataPage';
import { MetricTypesPage } from './components/guides/metric-types/MetricTypesPage';
import { ApplicationSilentLoginPage } from './components/guides/application-silent-login/ApplicationSilentLoginPage';
import { APIAuthenticationPage } from './components/guides/api-authentication/APIAuthenticationPage';
import { ProjectsPage, ProjectsEmailPage } from './components/projects';
import { ProjectAcceptInvitePage } from './components/projects/ProjectAcceptInvitePage';
import { ChartsCustomisationPage } from './components/guides/charts/ChartsCustomisationPage';
import { MappingServicesPage } from './components/guides/mapping-services/MappingServicesPage';
import { PsxImplementationPage } from './components/guides/psx-implementation/PsxImplementationPage';
import { FaqPage } from './components/faq/FaqPage';
import { useIdentityStore } from './stores/identityStore';
import { useRootStore } from './stores/rootStore';

const oidcProvider: SignInProviderConfig = {
  id: 'oidc-auth-provider',
  title: 'OIDC',
  message: 'Sign in using OIDC',
  apiRef: oidcAuthApiRef,
};

const app = createApp({
  apis,
  themes: [
    {
      id: 'corelogic-theme',
      title: 'CoreLogic Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={corelogicTheme}>
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => <SignInPage {...props} provider={oidcProvider} />,
    NotFoundErrorPage: () => <ErrorPage />,
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AddressMatcherNavigate = () => {
  const country = useRootStore(state => state.country);

  return <Navigate to={`/apis/corelogic-apis/address-match-${country}`} />;
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<GettingStartedPage />} />

    <Route path="/products" element={<CatalogIndexPage />}>
      <ProductsPage />
    </Route>

    <Route path="/products/:name" element={<ProductEntityPage />}>
      {entityPage}
    </Route>

    <Route path="/apis" element={<CatalogIndexPage />}>
      <ApiPage />
    </Route>

    <Route path="/apis/:namespace" element={<CatalogIndexPage />}>
      <ApiPage />
    </Route>

    <Route path="/apis/:namespace/:name" element={<ApiEntityPage />}>
      {entityPage}
    </Route>

    <Route path="/guides" element={<Navigate to="/guides/quick-start" />} />

    <Route path="/guides/quick-start" element={<QuickStartPage />} />

    <Route
      path="/guides/api-authentication"
      element={<APIAuthenticationPage />}
    />

    <Route
      path="/guides/standards-and-conventions"
      element={<StandardsAndConventionsPage />}
    />

    <Route
      path="/guides/environment-details"
      element={<EnvironmentDetailsPage />}
    />

    <Route path="/guides/sandbox-test-data" element={<SandboxTestDataPage />} />

    <Route
      path="/guides/application-silent-login"
      element={<ApplicationSilentLoginPage />}
    />

    <Route path="/guides/metric-types" element={<MetricTypesPage />} />

    {/* START: Old route for metric types page from legacy developer portal */}
    <Route path="/metrics" element={<Navigate to="/guides/metric-types" />} />
    <Route
      path="/metrics/census"
      element={<Navigate to="/guides/metric-types" />}
    />
    <Route
      path="/metrics/marketTrends"
      element={<Navigate to="/guides/metric-types" />}
    />
    {/* END */}

    <Route
      path="/guides/charts-customisation"
      element={<ChartsCustomisationPage />}
    />

    {/* START: Old route for charts customisation page from legacy developer portal */}
    <Route
      path="/explore_charts_customisation"
      element={<Navigate to="/guides/charts-customisation" />}
    />
    {/* END */}

    <Route
      path="/guides/mapping-services"
      element={<MappingServicesPage />}
    />

    <Route
      path="/guides/psx-implementation"
      element={<PsxImplementationPage />}
    />

    <Route path="/signup" element={<SignupPage />} />

    <Route path="/apps" element={<ApplicationsPage />} />

    <Route path="/projects" element={<ProjectsPage />} />

    <Route path="/projects/email" element={<ProjectsEmailPage />} />

    <Route path="/user/register" element={<ProjectAcceptInvitePage />} />

    <Route path="/apps/create" element={<CreateApplicationPage />} />

    <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />

    <Route path="/contact-us" element={<ContactUsPage />} />

    <Route path="/faq" element={<FaqPage />} />

    {/* START: Old route for address matcher from legacy developer portal */}
    <Route path="/address_matcher" element={<AddressMatcherNavigate />} />
    {/* END */}
  </FlatRoutes>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const GuestAutoLogout = () => {
  const isGuest = useIdentityStore(state => state.isGuest);

  if (!isGuest) {
    return <></>;
  }

  return <AutoLogout />;
};

export default app.createRoot(
  <>
    <AlertDisplay />
    <GuestAutoLogout />
    <OAuthRequestDialog />
    <AppRouter>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <Root>{routes}</Root>
      </QueryClientProvider>
    </AppRouter>
  </>,
);
