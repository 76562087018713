import React, { useCallback, useState } from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import { styles } from './Projects.styles';
import {
  getApigeeCompanyDevelopers,
  removeApigeeCompanyDevelopers,
} from '../../api/apigee';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIdentityStore } from '../../stores/identityStore';

const columns: TableColumn[] = [
  {
    title: 'Name',
    field: 'name',
    highlight: true,
  },
  {
    title: 'Email',
    field: 'email',
  },
];

type DeleteButtonProps = {
  id: string;
  removeFn: () => void;
};
const DeleteButton = ({ id, removeFn }: DeleteButtonProps) => {
  return (
    <PopupState variant="popover" popupId="confirmDelete">
      {popupState => (
        <>
          <Stack
            id={id}
            direction="row"
            alignItems="center"
            gap={0.75}
            sx={{ cursor: 'pointer' }}
            {...bindTrigger(popupState)}
          >
            <DeleteIcon sx={{ width: 20, height: 20, color: 'primary.main' }} />

            <Typography
              variant="body2"
              fontWeight={500}
              color="primary.main"
              sx={{ ':hover': { textDecoration: 'underline' } }}
            >
              Remove
            </Typography>
          </Stack>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Stack p={2} gap={2} alignItems="center" justifyContent="center">
              <Typography variant="body2">
                Are you sure to delete the developer?
              </Typography>
              <Stack direction="row" gap={2}>
                <Button
                  sx={styles.confirmButton}
                  size="small"
                  onClick={popupState.close}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  id={`${id}-btn`}
                  sx={styles.confirmButton}
                  size="small"
                  onClick={removeFn}
                  variant="outlined"
                >
                  Yes
                </Button>
              </Stack>
            </Stack>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export const ProjectsDevelopersTable = () => {
  const [developerToRemove, setDeveloperToRemove] = useState('');

  const profile = useIdentityStore(state => state.profile);

  const queryClient = useQueryClient();

  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const alert = useApi(alertApiRef);

  const removeApigeeCompanyDevelopersMutation = useMutation({
    mutationFn: async (body: CompanyInviteRequest) =>
      await removeApigeeCompanyDevelopers(discovery, identity, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['apigeeCompanyDevelopers'],
      });

      alert.post({
        message: 'Developer has been removed.',
        display: 'transient',
      });
    },
    onError: e =>
      alert.post({
        message: isAxiosError(e)
          ? e.response?.data?.error
          : 'Something went wrong when removing the developer.',
        severity: 'error',
        display: 'transient',
      }),
    onSettled: () => setDeveloperToRemove(''),
  });

  const apigeeCompanyApps = useQuery<ApigeeCompanyApps>({
    queryKey: ['apigeeCompanyApps'],
  });

  const firstCompany =
    Object.keys(apigeeCompanyApps.data ?? {}).find(company => !!company) ?? '';
  const firstCompanyApp = apigeeCompanyApps.data?.[firstCompany]?.[0];
  const companyName = firstCompanyApp?.company ?? '';

  const apigeeCompanyDevelopers = useQuery({
    queryKey: ['apigeeCompanyDevelopers'],
    queryFn: () => getApigeeCompanyDevelopers(discovery, identity, companyName),
    enabled: !!companyName,
  });

  const removeDeveloper = useCallback(
    (email: string) => {
      setDeveloperToRemove(email);

      removeApigeeCompanyDevelopersMutation.mutate({
        email,
        companyName,
      });
    },
    [companyName, removeApigeeCompanyDevelopersMutation],
  );

  return (
    <div style={styles.tableContainer}>
      <Table
        title="Project Developers"
        options={{ paging: false }}
        data={(apigeeCompanyDevelopers.data ?? [])
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(d => ({
            name: `${d.firstName} ${d.lastName}`,
            email: (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pr={4}
              >
                {d.email}
                {d.email === developerToRemove ? (
                  <CircularProgress size={20} />
                ) : (
                  d.email !== profile?.email && (
                    <DeleteButton
                      id={`delete-${d.email}`}
                      removeFn={() => removeDeveloper(d.email)}
                    />
                  )
                )}
              </Stack>
            ),
          }))}
        isLoading={apigeeCompanyDevelopers.isLoading}
        columns={columns}
      />
    </div>
  );
};
