import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../../stores/rootStore';
import { MetricTypesMarketTrends } from './MetricTypesMarketTrends';
import { MetricTypesCensus } from './MetricTypesCensus';

export const MetricTypesPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | Metric Types | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Metric Types
          </Typography>

          <Typography fontWeight={500}>
            Learn all about the different metric types we provide for Market
            Trends & Census data.
          </Typography>

          <ul>
            <li>
              <Link href="#metric-types-market-trends">Market Trends</Link>
            </li>
            <li>
              <Link href="#metric-types-census">Census</Link>
            </li>
          </ul>

          <br />

          <MetricTypesMarketTrends />

          <br />
          <br />

          <MetricTypesCensus />
        </Box>
      </Box>
    </>
  );
};
