import React, { useEffect } from 'react';
import { Progress } from '@backstage/core-components';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { acceptCompanyInvite } from '../../api/email';
import { useIdentityStore } from '../../stores/identityStore';
import { ErrorPage } from '../error/ErrorPage';
import { AxiosError } from 'axios';

export const ProjectAcceptInvitePage = () => {
  const [params] = useSearchParams();
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const profile = useIdentityStore(state => state.profile);

  const acceptInviteMutation = useMutation({
    mutationFn: () =>
      acceptCompanyInvite(discovery, identity, params.get('companyName') ?? ''),
  });

  useEffect(() => {
    if (profile?.email === params.get('mail') && acceptInviteMutation.isIdle) {
      acceptInviteMutation.mutate();
    }
  }, [profile, params, acceptInviteMutation]);

  if (profile?.email !== params.get('mail')) {
    return (
      <ErrorPage
        status={403}
        message="You're not allowed to accept this project invitation."
      />
    );
  }

  if (acceptInviteMutation.isPending) {
    return <Progress />;
  }

  if (acceptInviteMutation.error) {
    const defaultStatus = 500;
    const defaultMessage =
      'Accepting this invitation has encountered an error.';

    if (acceptInviteMutation.error instanceof AxiosError) {
      const status =
        acceptInviteMutation.error.response?.status ?? defaultStatus;

      const message =
        acceptInviteMutation.error.response?.data.error ??
        acceptInviteMutation.error.response?.data.msg ??
        defaultMessage;

      return <ErrorPage status={status} message={message} />;
    }

    return <ErrorPage status={defaultStatus} message={defaultMessage} />;
  }

  if (acceptInviteMutation.isSuccess) {
    return <Navigate to="/projects" />;
  }

  return <></>;
};
