import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useIdentityStore } from '../../stores/identityStore';
import { useLogout } from '../../hooks/useLogout';

export const HeaderAvatar = () => {
  const profile = useIdentityStore(state => state.profile);
  const isGuest = useIdentityStore(state => state.isGuest);

  const logout = useLogout();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {isGuest ? (
        <>
          <Button variant="outlined" onClick={logout}>
            Login
          </Button>

          <Link to="/signup">
            <Button variant="contained">Sign Up</Button>
          </Link>
        </>
      ) : (
        <>
          <Stack
            alignItems="flex-end"
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            <Typography
              className="user-full-name"
              fontSize={14}
              lineHeight={1.25}
              fontWeight={500}
            >
              {profile?.displayName}
            </Typography>

            <Typography
              className="user-email"
              fontSize={14}
              lineHeight={1.25}
              fontWeight={300}
              color="rgba(31, 50, 62, 0.7)"
            >
              {profile?.email}
            </Typography>
          </Stack>

          <Avatar />

          <Button
            size="small"
            variant="contained"
            sx={{ ml: 1.5, mr: 3 }}
            onClick={logout}
          >
            Log Out
          </Button>
        </>
      )}
    </Stack>
  );
};
