import React from 'react';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { NavigateNext } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';
import { useIdentityStore } from '../../stores/identityStore';
import { CreateApplicationForm } from './CreateApplicationForm';

export const CreateApplicationPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isGuest) navigate('/');
  }, [isGuest, navigate]);

  return (
    <>
      <Helmet>
        <title>
          Sandbox Clients | Create a Sandbox Client | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <Stack flex={1} p={4} gap={2}>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link component={RouterLink} to="/apps">
              <Typography>My Sandbox Clients</Typography>
            </Link>
            <Typography color="text.primary">
              Create a Sandbox Client
            </Typography>
          </Breadcrumbs>

          <Typography variant="h4">Create a Sandbox Client</Typography>

          <CreateApplicationForm />
        </Stack>
      </Stack>
    </>
  );
};
