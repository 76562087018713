import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { ApiTable } from './ApiTable';
import { getEntities } from '../../api/catalog';
import { useRootStore } from '../../stores/rootStore';
import { ErrorPage } from '../error/ErrorPage';
import { formatNamespace } from '../../utilities/formatNamespace';
import { ApiEntityPage } from '../entity/ApiEntityPage';
import { entityPage } from '../entity/EntityPage';

export const ApiPage = () => {
  const { namespace } = useParams();
  const navigate = useNavigate();

  const country = useRootStore(state => state.country);
  const countryOnRender = useRef(country);
  
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apis = useQuery({
    queryKey: ['apis'],
    queryFn: () => getEntities(discovery, identity, 'kind=API'),
  });

  if (countryOnRender.current !== country) {
    countryOnRender.current = country;
    navigate('/apis');
  }

  // check if an API matches the namespace in path params.
  // if so, render the swagger doc immediately.
  const apiWithoutGroup = apis.data?.find(
    api => api.metadata.name === namespace,
  );

  if (apiWithoutGroup) {
    return <ApiEntityPage>{entityPage}</ApiEntityPage>;
  }

  let subtitle: React.ReactElement = <></>;

  switch (namespace) {
    case 'corelogic-apis':
      subtitle = (
        <>
          CoreLogic APIs allow you to get real-time access to the best property
          data, insights, and analytics straight from the largest provider of
          property information in
          {country === 'au' ? ' Australia' : ' New Zealand'}.
        </>
      );
      break;
    case 'psx-apis':
      subtitle = (
        <>
          The Property Services eXchange (PSX) provides a web service interface
          for loan origination platforms and aggregator platforms to order,
          interact with, and retrieve valuations in accordance with a lender's
          valuation policy. Lenders and banking-related platform providers
          (Customer) can integrate with the PSX to automate valuation ordering
          and related workflows.
          <br />
          <br />
          <Link component={RouterLink} to="/guides/psx-implementation">
            PSX Implementation Guide
          </Link>
        </>
      );
      break;
    default:
  }

  let data = apis.data
    ?.filter(
      e =>
        e.metadata.tags?.includes(country) &&
        e.metadata.namespace !== 'api-product',
    )
    .map(entity => ({
      entity,
      name: entity.metadata.title ?? entity.metadata.name,
      description: entity.metadata.description,
      namespace: formatNamespace(entity.metadata.namespace ?? ''),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => a.namespace.localeCompare(b.namespace));

  if (namespace) {
    data = data?.filter(d => d.namespace === formatNamespace(namespace));
  }

  if (data?.length === 0) {
    return <ErrorPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          APIs {namespace ? `| ${formatNamespace(namespace)}` : ''} | CoreLogic
          Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1} p={4}>
        <ApiTable
          title={namespace ? formatNamespace(namespace) : 'APIs'}
          subtitle={subtitle}
          data={data ?? []}
          isLoading={apis.isLoading}
        />
      </Stack>
    </>
  );
};
