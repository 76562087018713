import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useRootStore } from '../../../stores/rootStore';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ComponentDescriptionsTable = () => {
  const rows = [
    {
      component: 'Integrating Platform',
      description: `The Integrating Platform is the Customer’s Platform that is making the API calls to PSX. Each Customer is only allowed to access their authorised channels. The Integrating Platform is required to comply with the requirements in this Solution Design.`,
    },
    {
      component: 'PSX',
      description: `PSX is an abstraction layer providing a JSON Rest Webservice interface between the Integrating Platform and the other components including ValEx, RuleEx and PanelEx. The PSX interacts with other components as required.`,
    },
    {
      component: 'PropertyHub',
      description: `PropertyHub provides a graphical User Interface (GUI) for ordering valuations. A lender will typically have one or more PropertyHub channels for different users. E.g. Brokers, internal staff. PropertyHub implements PSX’s web services allowing for consistent behaviour across a lender’s ordering`,
    },
    {
      component: 'ValEx',
      description: `ValEx is the valuation workflow orchestration component of the solution. Jobs ordered via the PSX are created in ValEx prior to being allocated to a valuation firm. ValEx is also used by administration staff to interact with jobs where manual intervention is required to progress.`,
    },
    {
      component: 'RuleEx',
      description: `RuleEx is a rules engine used to apply a lender’s business rules relating to its valuation policy.`,
    },
    {
      component: 'PanelEx',
      description: `PanelEx host a lender’s panel. The panel includes details of valuation firms, their allocations, and fees that will be applicable for a given postcode, estimated market value and service type (as agreed between the lender and the valuation firm).`,
    },
    {
      component: 'Valuation Firms',
      description: `Suppliers on a lenders panel that will fulfil the valuation. Note that these may also be AVM providers, or similar automated providers that produce a related report.`,
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Component
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.component}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.component}
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PsxTerminologyTable = () => {
  const data = [
    {
      component: 'Channel',
      description:
        'A Channel is a specific setup for an ordering entity, typically a lender. The Channel ID is typically needed to construct the URL of a PSX endpoint.',
    },
    {
      component: 'Category',
      description:
        'A Category is a group of related products e.g. valuations. Each Category belongs to one Channel and each Channel can have multiple Categories. If business rules are applied they are set at the Category level. Typically, lenders will have a Category for each security Category applicable to them e.g. (Residential, Commercial, Rural). The Category ID is often needed to construct the URL to for PSX endpoints',
    },
    {
      component: 'Product',
      description:
        'The Product is the service type being ordered (e.g. Desktop, Residential Shortform etc.). Each Product belongs to one Category and each Category can have many Products. Each Product in a Channel will have its own Product ID.',
    },
    {
      component: 'Order',
      description:
        'An Order is a request for Products to be delivered, this object encapsulates all of the data for a valuation request for a given property. Each Order created using PSX will be assigned an Order ID which is used in other methods to interact with the job.',
    },
    {
      component: 'Item',
      description:
        'An Item is a line item on an Order for a Product. Currently PSX only supports a 1 to 1 between Items and Orders. Each Order created using PSX will be assigned an Item ID which is used in other methods to interact with the job.',
    },
    {
      component: 'Supplier Reference',
      description:
        'The Supplier Reference is the ValEx ID for a job which is the unique identifier for a valuation assigned by ValEx. Jobs created in ValEx have a ValEx ID and this is the job identifier most commonly used by lenders, valuers and administrators to reference a valuation.',
    },
    {
      component: 'Integrator Reference',
      description:
        'The Integrator Reference is an identifier constructed and submitted by the Integrating Platform, typically a loan reference number.',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Component
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.component}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.component}
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type Methods = {
  method: string[];
  name: string;
  description: string;
  documentation: {
    link: string;
    name: string;
  }[];
}[];

const preOrderingMethods = [
  {
    method: ['POST'],
    name: 'expandedSearch',
    description:
      'Runs a lender’s business rules, and searches for existing jobs for the address',
    documentation: [
      {
        link: '/apis/psx-apis/psx-expanded-search#/Business%20Rules/getAvailableProducts',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'Get Categories',
    description:
      'If expandedSearch is not used, this can be used to list available Categories and Products in a Channel',
    documentation: [
      {
        link: '/apis/psx-apis/psx-explore#/Explore/getCategories',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'Subscribe',
    description:
      'Subscribe to notifications for a job that has been found but is still in progress',
    documentation: [
      {
        link: '/apis/psx-apis/psx-subscribe#/Subscribe/subscribe',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'Item/Order',
    description:
      'Retrieve the completed job where one is found by the expandedSearch',
    documentation: [
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getOrderIdByItemId',
        name: 'Item Documentation Link',
      },
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getOrder',
        name: 'Order Documentation Link',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'Find Call',
    description:
      'Retrieve the completed job where one is found by the expandedSearch (Non PSX originated jobs)',
    documentation: [
      {
        link: '/apis/psx-apis/psx-find#/Find%20Existing%20Job/getItem',
        name: 'Documentation Link',
      },
    ],
  },
];

const orderingMethods = [
  {
    method: ['POST'],
    name: 'orders',
    description: 'Orders a new valuation',
    documentation: [
      {
        link: '/apis/psx-apis/psx-order#/Order/createOrder',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'events',
    description:
      'Pull notifications approach to receive instructed and subsequent status updates. Can be performed at both channel or item level',
    documentation: [
      {
        link: '/apis/psx-apis/psx-pull-notifications#/Pull%20Notifications/getEventsByLastProcessedEventId',
        name: 'Channel Notifications',
      },
      {
        link: '/apis/psx-apis/psx-pull-notifications#/Pull%20Notifications/getEvents',
        name: 'Item Notifications',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'notificationsWebhookURI',
    description:
      'Push notifications approach to receive instructed and subsequent status updates.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-push-notifications#/Push%20Notifications/post_notificationsWebhookURI',
        name: 'Documentation Link',
      },
    ],
  },
];

const feeMethods = [
  {
    method: ['POST'],
    name: 'fee-lookup',
    description:
      'Can be used to lookup in advance of ordering what the fee will be where a fixed price is applicable.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-panel-lookup#/Panel%20Lookup/getValexPanelFees',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'panel-lookup',
    description:
      'Can be used to list valuation firms on the lender’s panel for a given postcode/estimate price/service type.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-panel-lookup#/Panel%20Lookup/valexPanelLookUp',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'accept-quote',
    description: 'Accept one of the quotes returned',
    documentation: [
      {
        link: '/apis/psx-apis/psx-accept-quote#/Accept%20Quote/acceptQuote',
        name: 'Documentation Link',
      },
    ],
  },
];

const notificationMethods = [
  {
    method: ['GET'],
    name: 'events',
    description:
      'Pull notifications approach to receive instructed and subsequent status updates. Can be performed at both Channel or Item level.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-pull-notifications#/Pull%20Notifications/getEventsByLastProcessedEventId',
        name: 'Channel Notifications',
      },
      {
        link: '/apis/psx-apis/psx-pull-notifications#/Pull%20Notifications/getEvents',
        name: 'Item Notifications',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'notificationsWebhookURI',
    description:
      'Push notifications approach to receive instructed and subsequent status updates.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-push-notifications#/Push%20Notifications/post_notificationsWebhookURI',
        name: 'Documentation Link',
      },
    ],
  },
];

const workflowMethods = [
  {
    method: ['POST'],
    name: 'cancel',
    description: 'Cancel a job where cancellation request has been raised.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-cancel#/Cancel%20Job/cancelOrder',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['POST', 'PATCH'],
    name: 'update',
    description:
      'Update the job with requested information (e.g. new inspection contact).',
    documentation: [
      {
        link: '/apis/psx-apis/psx-update-order#/Update%20job/update',
        name: 'Documentation Link POST',
      },
      {
        link: '/apis/psx-apis/psx-update-order#/Update%20job/update_1',
        name: 'Documentation Link PATCH',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'attach',
    description: 'Attach requested documentation to the job.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-attach-or-retrieve-documents#/Attached%20%26%20Retrieve%20Documents/attach',
        name: 'Documentation Link',
      },
    ],
  },
];

const retrievalMethods = [
  {
    method: ['GET'],
    name: 'Item/Order',
    description: 'Retrieve the completed job using Order/Item ID.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getOrder',
        name: 'Order Documentation Link',
      },
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getOrderIdByItemId',
        name: 'Item Documentation Link',
      },
    ],
  },
  {
    method: ['POST'],
    name: 'ValEx find',
    description:
      'Will search for jobs ordered via legacy channels (ValEx, VXAPI) as well as PSX channels. This will search by job ID or Address.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-find#/Find%20Existing%20Job/getItem',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'SupplierReference',
    description: 'Retrieve the completed job using ValEx ID.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getItem_1',
        name: 'Documentation Link',
      },
    ],
  },
  {
    method: ['GET'],
    name: 'IntegratorReference',
    description:
      'Retrieve the completed job using Integrator reference supplied in the order.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-get-order#/Get%20Order/getItemByIntegratorReference',
        name: 'Documentation Link',
      },
    ],
  },
];

const queryMethods = [
  {
    method: ['POST'],
    name: 'query',
    description: 'Open a query on a completed job.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-query#/Query/queryItem',
        name: 'Documentation Link',
      },
    ],
  },
];

const conversionReportingMethods = [
  {
    method: ['POST'],
    name: 'set retrieved',
    description:
      'Stamps a job as being retrieved for broker conversion purposes.',
    documentation: [
      {
        link: '/apis/psx-apis/psx-set-retrieved#/Set%20Retrieved/setRetrieved',
        name: 'Documentation Link',
      },
    ],
  },
];

type RelatedMethodsTableProps = {
  data: Methods;
};

const RelatedMethodsTable = ({ data }: RelatedMethodsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              HTTP
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Method
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Use
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Method Documentation
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.name}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.method.map(method => (
                  <React.Fragment key={method}>
                    {method}

                    {row.method.at(-1) !== method && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                {row.documentation.map(doc => (
                  <React.Fragment key={JSON.stringify(doc)}>
                    <Link component={RouterLink} to={doc.link}>
                      {doc.name}
                    </Link>

                    {row.documentation.at(-1) !== doc && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ActionUpdatesTable = () => {
  const data = [
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'RECEIVED',
      valExStatus: '',
      scenario: 'Job has been received in PSX but not yet created in ValEx',
      action:
        'If the job does not progress to IN_PROGRESS from this status, this may suggest an issue creating the job.',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'IN_PROGRESS',
      valExStatus: 'INSTRUCTED',
      scenario: 'Job has been received in ValEx (Instructed)',
      action: 'Informational, no action required',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'QUOTES_AVAILABLE',
      valExStatus: 'QUOTES PROVIDED',
      scenario: 'Quotes have been provided by panel valuation firm.',
      action: 'Use acceptquote method to accept one of the quotes.',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'IN_PROGRESS',
      valExStatus: 'ACCEPTED',
      scenario:
        'Job has been accepted by valuation firm (and assigned to a valuer)',
      action: 'Informational, no action required',
    },
    {
      eventType: 'ITEM_APPOINTMENT_BOOKED',
      status: 'IN_PROGRESS',
      valExStatus: 'ASSIGNED',
      scenario:
        'An appointment to inspect the property has been made by the valuer',
      action: 'Informational, no action required',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'IN_PROGRESS',
      valExStatus: 'INSPECTED',
      scenario: 'Property has been inspected by the valuer',
      action: 'Informational, no action required',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'IN_PROGRESS',
      valExStatus: 'AWAITINGAUTHORISATION',
      scenario: 'Job has been held in QA (compliance)',
      action: 'Informational, no action required',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'COMPLETED',
      valExStatus: 'VALUATIONCOMPLETED',
      scenario: 'Job has been completed',
      action: 'Retrieve completed job',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'CANCELLED',
      valExStatus: 'CANCELLED',
      scenario: 'Job has been Cancelled',
      action: 'Informational, no action required.',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'REQUIRES_ACTION',
      valExStatus: 'DELAYED',
      scenario:
        'Job has been delayed (cannot proceed further without an action being performed by the job orderer)',
      action: 'See section 4.4 (Delays)',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'REJECTED',
      valExStatus: '',
      scenario:
        'Job has been rejected, indicating that there is an error with the order.',
      action:
        'In the case of a synchronous order, it may not be possible to complete the Order of that Product type.',
    },
    {
      eventType: 'ITEM_STATUS_CHANGED',
      status: 'IN_PROGRESS',
      valExStatus: 'IN_PROGRESS',
      scenario: 'When a delay has been removed on the job and is continuing.',
      action: 'Informational, no action required.',
    },
    {
      eventType: 'ITEM_QUERY_ADDED',
      status: 'COMPLETED',
      valExStatus: 'INITIAL',
      scenario: 'A query has been raised against the completed job.',
      action: 'Informational, no action required.',
    },
    {
      eventType: 'ITEM_QUERY_CLOSED',
      status: 'COMPLETED',
      valExStatus: 'VALUATIONCOMPLETED',
      scenario: 'Query on the job has been closed by the valuer.',
      action: 'Review query response (see section 5.2)',
    },
    {
      eventType: 'ITEM_QUERY_CLOSED',
      status: 'IN_PROGRESS',
      valExStatus: 'VALUATIONCOMPLETED',
      scenario: 'Job is being modified by the valuer following a query.',
      action: 'Review query response (see section 5.2)',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Event Type
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Status
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ValEx Status
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Scenario
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.eventType}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.eventType}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.status}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.valExStatus}
              </TableCell>
              <TableCell>{row.scenario}</TableCell>
              <TableCell>{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DelaysTable = () => {
  const data = [
    {
      delay: 'Access Issues',
      statusReason: 'TenantDeniedAccess',
      description: 'Valuer is unable to access the property to be inspected.',
      action: (
        <>
          Provide an alternate inspection contact using{' '}
          <Link component={RouterLink} to="/apis/psx-apis/psx-update-order">
            update
          </Link>
          .
        </>
      ),
    },
    {
      delay: 'Cancellation Request',
      statusReason: 'AtCustomersRequest',
      description: 'Valuer is requesting the job is cancelled.',
      action: (
        <>
          <Link component={RouterLink} to="/apis/psx-apis/psx-cancel">
            Cancel
          </Link>{' '}
          the order
        </>
      ),
    },
    {
      delay: 'Request To Change Service Type and Fee Approval',
      statusReason: 'AwaitingFeeChange',
      description:
        'Valuer has determined the current service type to be unsuitable, and is requesting that this is changed to a different service type which has a corresponding higher fee that also need to be approved.',
      action:
        'This will be accompanied by a higher fee request email. The recipient of the email will need to approve or cancel the job.',
    },
    {
      delay: 'Fee Approval',
      statusReason: 'AwaitingFeeChange',
      description:
        'A different, typically higher fee has been requested to complete the job and will need to be approved for the job to continue.',
      action:
        'This will be accompanied by a higher fee request email. The recipient of the email will need to approve or cancel the job.',
    },
    {
      delay: 'Inspection Contacting Issues',
      statusReason: 'IncorrectContactDetails',
      description:
        'Valuer is unable to contact the inspection contact in order to book in inspection appointment.',
      action: (
        <>
          Provide an alternate inspection contact using{' '}
          <Link component={RouterLink} to="/apis/psx-apis/psx-update-order">
            update
          </Link>
          .
        </>
      ),
    },
    {
      delay: 'Insufficient Documentation',
      statusReason: 'AwaitingBuildingInformation',
      description:
        'Additional documentation needs to be attached in order for the valuer to be able to continue the job.',
      action: (
        <>
          <Link
            component={RouterLink}
            to="/apis/psx-apis/psx-attach-or-retrieve-documents"
          >
            Attach
          </Link>{' '}
          requested documentation.
        </>
      ),
    },
    {
      delay: 'Property Identification',
      statusReason: 'IncorrectAccessDetails',
      description:
        'Clarification is needed to ensure the subject property is correctly identified',
      action: (
        <>
          Provide correct address using{' '}
          <Link component={RouterLink} to="/apis/psx-apis/psx-update-order">
            update
          </Link>
          .
        </>
      ),
    },
    {
      delay: 'Request to Change Service Type',
      statusReason: 'AwaitingAuthorisation',
      description:
        'Valuer has determined the current service type to be unsuitable, and is requesting that this is changed to a different service type, with the same fee.',
      action:
        'This will be accompanied by a higher fee request email. The recipient of the email will need to approve or cancel the job.',
    },
    {
      delay: 'Request is Outside of Lender Policy',
      statusReason: 'Other',
      description:
        'Valuer has determined that the valuation may fall out of the lenders policy and is seeking clarification.',
      action: (
        <>
          <Link component={RouterLink} to="/apis/psx-apis/psx-cancel">
            Cancel
          </Link>{' '}
          the order.
        </>
      ),
    },
    {
      delay: 'Awaiting Credit Management Approval',
      statusReason: 'Other',
      description:
        'The job has triggered a rule event that requires approval from a user with approval permissions.',
      action:
        'This will be accompanied by a higher fee request email. The recipient of the email will need to approve or cancel the job.',
    },
    {
      delay: 'Other',
      statusReason: 'Other',
      description:
        'Miscellaneous reason for applying a delay, which will need to be determined by the comments provided with the delay.',
      action: '',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Delay
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Status Reason
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Expected Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.delay}>
              <TableCell sx={{ fontWeight: 600 }}>{row.delay}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.statusReason}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PsxImplementationPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | PSX Implementation Guide | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            PSX Implementation Guide
          </Typography>

          <Typography fontWeight={500}>
            Learn how to interact with PSX APIs.
          </Typography>

          <br />
          <br />

          <Typography variant="body2">
            The Property Services eXchange (PSX) provides a web service
            interface for loan origination platforms and aggregator platforms to
            order, interact with, and retrieve valuations in accordance with a
            lender's valuation policy. Lenders and banking-related platform
            providers (Customer) can integrate with the PSX to automate
            valuation ordering and related workflows.
            <br />
            <br />
            This article is intended to provide Customers with an understanding
            of the high-level architecture and valuation workflow pertaining to
            the PSX. This includes overviews of key workflows and the relevant
            PSX web services that can be implemented to interact in these
            workflows.
          </Typography>

          <br />
          <br />
          <br />

          <Typography
            variant="h4"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Solution Overview
          </Typography>

          <br />

          <Typography variant="body2">
            The following section provides a general overview of the solution
            components, job workflow and key concepts pertaining to a PSX
            integration.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Solution Components
          </Typography>

          <br />

          <Typography variant="body2">
            Fundamentally, the PSX serves as an abstraction layer between a
            number of application components setup for a customer. The web
            services available in the PSX interface will interact with these
            components. Figure 2.1 provides a general overview of how these
            components relate and table 2.1.1 provides a more detailed
            explanation of each component.
          </Typography>

          <br />

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-component-overview.png"
              alt="Figure 2.1 - Component Overview"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 2.1 - Component Overview
            </Typography>
          </Stack>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Component Descriptions
          </Typography>

          <br />

          <ComponentDescriptionsTable />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Valuation Workflow
          </Typography>

          <br />

          <Typography variant="body2">
            Valuations ordered in the PSX will go through the workflow outlined
            in figure 2.2. Note that a job will not necessarily transition
            through all of these states. For example, an AVM will typically
            transition directly from instructed to completed. Most of the job’s
            status are informational, providing details of the current state of
            the job, however those highlighted in red require action from the
            job order for the job to proceed. Section 4 details the different
            workflow status, and expected actions.
          </Typography>

          <br />

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-valuation-workflow.png"
              alt="Figure 2.2 - Valuation Workflow"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 2.2 - Valuation Workflow
            </Typography>
          </Stack>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            PSX Terminology
          </Typography>

          <br />

          <Typography variant="body2">
            The following table lists definitions for key terms relevant to a
            PSX integration.
          </Typography>

          <br />

          <PsxTerminologyTable />

          <br />
          <br />
          <br />

          <Typography
            variant="h4"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Valuation Ordering
          </Typography>

          <br />

          <Typography variant="body2">
            Valuation ordering is the fundamental task performed by Customers
            implementing the PSX. Generally speaking, ordering a valuation
            results in a valuation request being sent to a Valuation firm (known
            as an Order), and an appraisal being performed on the subject
            property. This appraisal is returned as a PDF report when the job is
            completed. There are different types of valuations that can be
            ordered. These are setup as different Products in the lender’s
            Channel and have their own identifier as set out in section 6.1.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Pre-Ordering Calls
          </Typography>

          <br />

          <Typography variant="body2">
            Prior to ordering a job, Customers can perform calls to PSX to check
            the correct service type to order according to the business rules
            set up to execute a lender’s valuation policy, and avoid ordering
            new valuations when an existing valuation might exist.
            <br />
            <br />
            The recommended order of calls is to first run the{' '}
            <Link
              component={RouterLink}
              to="/apis/psx-apis/psx-expanded-search"
            >
              expandedSearch
            </Link>
            , which will look for existing jobs within a lender’s supported job
            age and also run a request to confirm the lender’s business rules
            and return the recommended service type.
            <br />
            <br />
            If an existing job is found, the details of that job will be
            included in the existingValuationDetails node. If the found job is
            still in progress, the Customer can{' '}
            <Link component={RouterLink} to="/apis/psx-apis/psx-subscribe">
              subscribe
            </Link>{' '}
            to notifications for this job to avoid ordering a duplicate (see
            case “Existing job in progress” in Figure 3.1). If a completed job
            is found, this can be retrieved using{' '}
            <Link component={RouterLink} to="/apis/psx-apis/psx-get-order">
              Get Order
            </Link>{' '}
            (see case “Existing completed job” in Figure 3.1)
            <br />
            <br />
            <Alert icon={false} severity="error">
              <b>Important note</b>: Where the lender uses non-PSX ordering
              channels, additional setup may be required to make these jobs
              available via the get Order/Item methods. Alternatively, the find
              method can be used to retrieve non-PSX ordered jobs.
            </Alert>
            <br />
            <br />
            The result of the lender’s business rules can be found in the
            availableProducts node, with the ‘id’ sub node being the Product ID
            of the Product recommended by the lender’s business rules. There can
            be a few different scenarios depending on the structure of a
            lender’s rules:
          </Typography>

          <ul>
            <li>
              Typically, one Product will be returned, which is the correct
              service type based on a lender's business rule. This is the
              Product the end user should be prompted to order.
            </li>
            <li>
              Note some lenders can have business rules that recommend multiple
              Products, in which case all Products returned can be available for
              the user to order.
            </li>
            <li>
              If a request falls outside of a lender's business rules, no
              Products can potentially be returned with a message that should be
              rendered to the user.
            </li>
          </ul>

          <Typography variant="body2">
            Where no business rules are in place for a lender, all Products
            available in the Channel will be listed. In cases where multiple
            Products are returned, the expectation is that all Products returned
            are made available for the user to order.
            <br />
            <br />
            <Alert icon={false} severity="error">
              <b>Important Note</b>: To correctly invoke a lender’s business
              rules, it is important that all of the fields applicable to the
              lender’s valuation policy are included expandedSearch call.
              Section 6.5 identifies the fields that are required to be
              populated.
            </Alert>
          </Typography>

          <br />

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-pre-ordering-sequence-diagram.png"
              alt="Figure 3.1 - Pre-Ordering Sequence Diagram"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 3.1 - Pre-Ordering Sequence Diagram
            </Typography>
          </Stack>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Pre-Ordering Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={preOrderingMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Ordering
          </Typography>

          <br />

          <Typography variant="body2">
            A Customer can order a valuation using the orders endpoint. Although
            typically the same, there can be some variation on the input schema
            depending on the Product. Specific schema’s applicable to the
            Product are provided in the expandedSearch response or can
            alternatively be sourced by using the get categories service which
            will list of the Products available under each Category.
            <br />
            <br />
            <Alert icon={false} severity="error">
              <b>Important Note</b>: If an expandedSearch has been performed
              prior to ordering a job, it is important that the searchID
              returned by the call is populated in associatedSearchID field in
              order for business rule reporting to be able to associate the
              distinct calls. Failure to do so will limit business rule
              reporting effectiveness.
            </Alert>
            <br />
            <br />
            Note that when a job is ordered via the PSX, in the synchronous
            response returned, the job will initially be in the ‘received’
            status, with no ValEx ID available. This is because the job
            subsequently asynchronously created in ValEx. When the Customer
            receives an ‘instructed’ notification, the job will have been
            created in ValEx, and a ValEx ID will be available. The instructed
            notification includes the ValEx ID (supplierReference field). Figure
            3.2 provides an on overview of this process.
          </Typography>

          <br />

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-ordering-sequence-diagram.png"
              alt="Figure 3.2 - Ordering Sequence Diagram"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 3.2 - Ordering Sequence Diagram
            </Typography>
          </Stack>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Ordering Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={orderingMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Fees
          </Typography>

          <br />

          <Typography variant="body2">
            When ordering a valuation, a lender’s pre-set panel is applied to
            determine the fee for a job. In some cases, particularly for
            non-residential service types or residential valuations in remote
            areas, there will not be a set fee and the job will go through a
            quoting process.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Fixed Fee
          </Typography>

          <br />

          <Typography variant="body2">
            Where a lender has determined a pre-agreed fee for a given service
            type, estimated price and postcode, the fee will already be known at
            the time of order. An integrating platform can see the fee prior to
            ordering by optionally calling the{' '}
            <Link component={RouterLink} to="/apis/psx-apis/psx-panel-lookup">
              Panel Lookup
            </Link>{' '}
            service.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Quote
          </Typography>

          <br />

          <Typography variant="body2">
            Where a set fee is not available, a competitive quote process is
            employed to allow valuation firms on a lender’s panel to tender for
            the job. The quote process will send a quote request to each firm on
            the lender’s panel. After the shorter of a quote timeframe or all
            valuation firms responding, the list of quotes will be provided. An
            integrating platform can accept one of the quotes, and the job will
            be subsequently assigned to the nominated valuation firm. Note that
            quotes can be alternatively accepted by email.
          </Typography>

          <br />

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-quote-process.png"
              alt="Figure 3.2.2 - Quote Process"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 3.2.2 - Quote Process
            </Typography>
          </Stack>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Fee Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={feeMethods} />

          <br />
          <br />
          <br />

          <Typography
            variant="h4"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Notifications
          </Typography>

          <br />

          <Typography variant="body2">
            Notifications are used to notify changes in the status of a job
            through its workflow. The following sections outlines the
            methodologies that can be adopted, the different notifications that
            can be encountered, and how an Integrating Platform should respond
            to a given event.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Notification Methodologies
          </Typography>

          <br />

          <Typography variant="body2">
            After ordering a valuation, the Customer can receive updates
            regarding the progress of a valuation though notifications. PSX has
            both push and pull notification methodologies available for tracking
            a job transitioning through the PSX workflow.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Pull notifications
          </Typography>

          <br />

          <Typography variant="body2">
            Customer can poll for notifications on a regular basis using pull
            notifications. Each event created in the PSX has a sequential event
            ID. A Customer can pull all notifications since the last event
            retrieved by setting the last event ID in as a parameter in the{' '}
            <Link
              component={RouterLink}
              to="/apis/psx-apis/psx-pull-notifications"
            >
              Pull Notifications
            </Link>{' '}
            endpoint. Note that the event endpoint returns up to 100 events at a
            time.
            <br />
            <br />
            <Alert icon={false} severity="error">
              <b>Recommendation</b>: Where pull notifications are used, it is
              recommended events are polled for at least every 1 minute, but no
              more frequently than 10 seconds.
            </Alert>
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Push notifications
          </Typography>

          <br />

          <Typography variant="body2">
            Push notifications can be utilised to allow for real-time updates of
            jobs as events occur. Where a Customer includes a web hook URL in
            their order request, or where the URL has been configured against
            the Customer profile, the PSX will send notifications to that URL.
            PSX also allows oAuth2 authentication for push notifications.
            Customer can opt to use the standard authentication or oAuth
            integration.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Notification Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={notificationMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Notification Types
          </Typography>

          <br />

          <Typography variant="body2">
            Notifications can broadly be categorised into two types,{' '}
            <b>Action Updates</b> and <b>Delays</b>. An action update is a
            notification which is in most instances informational in nature,
            updating the orderer as to the progress of a job. Generally speaking
            no action is required from an orderer in the event of an action
            update. An example of an action update is an ‘Accepted’ workflow
            notification notifying that a valuation firm has accepted a job.
            Section 4.3 lists potential action updates that can occur.
            <br />
            <br />A specific type of action update is a delay. They key
            difference of a delay is that it signifies that the job can no
            longer progress unless the job order performs an action. An example
            of a delay might be a higher free request requiring the order to
            approve, or the provision of documents needed to perform the
            valuation. Section 4.4 lists delays and corresponding actions that
            can be performed when the delay is triggered.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Workflow Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={workflowMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Action Updates
          </Typography>

          <br />

          <ActionUpdatesTable />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Delays
          </Typography>

          <br />

          <DelaysTable />

          <br />
          <br />
          <br />

          <Typography
            variant="h4"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Post Completion Functionality
          </Typography>

          <br />

          <Typography variant="body2">
            Once a job reaches the completed state there are a number of
            interactions that can potentially be performed on a job by an
            Integrating Platform. This includes retrieving the completed job or
            optional interactions such as performing a post completion query or
            confirming a job as retrieved.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Valuation Retrieval
          </Typography>

          <br />

          <Typography variant="body2">
            On receipt of the completion notification, Integrating Platforms can
            retrieve the completed job by calling the get Order or get Item web
            service. The returned object will include the valuation report as a
            PDF document along with data included in the report.
            <br />
            <br />
            <Alert icon={false} severity="error">
              <b>Important Note</b>: Note that not all data that is in the PDF
              report is included in the response. This will vary depending on
              the service type and the supplier completing the report.
            </Alert>
            <br />
            <br />
            In the case of jobs ordered via different ordering channels, such as
            a PropertyHub Broker channel, there are also alternative methods
            that can be used to retrieve an existing job. These have been listed
            in section 5.1.1.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Retrieval Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={retrievalMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Post completion query
          </Typography>

          <br />

          <Typography variant="body2">
            Once a valuation is completed, if there are concerns over the
            provided assessment, the concerns can be raised with the valuer
            using the query function.
            <br />
            <br />
            Queries are broadly categorised into the following
          </Typography>

          <ul>
            <li>
              <b>Value</b> – Property market value is being disputed. This is
              only to be used where there is supporting evidence supplied to
              support the dispute.
            </li>
            <li>
              <b>Non-Value</b> – All other reasons other than market value
              requests, e.g., incorrect title reference, comments, comparable
              sales used within the report, photos of the property, building
              contract variations, or out-of-contract items.
            </li>
          </ul>

          <Typography variant="body2">
            <Alert icon={false} severity="error">
              <b>Important Note</b>: An Automated Valuation (AVM) cannot be
              disputed, and Desktops can only be disputed for non-value
              purposes.
            </Alert>
            <br />A valuer can provide one of the following resolutions for the
            query with a corresponding note:
          </Typography>

          <ul>
            <li>
              <b>Supported</b> – Valuer supports the query, agreeing to amend
              the valuation report.
            </li>
            <li>
              <b>Alternative</b> – Valuer provides an alternative position on
              the query.
            </li>
            <li>
              <b>Not Supported</b> – Valuer does not support the position held
              in the query, closing the query without amending the valuation
              report.
            </li>
          </ul>

          <Typography variant="body2">
            The resolution can be identified in the resolution field in the
            related notification.
            <br />
            <br />A valuer can also choose one of the following actions on the
            query:
          </Typography>

          <ul>
            <li>
              <b>Amend Report</b> – In the case of a Supported or Alternative
              resolution, the valuer can reopen the report for amendment. The
              job will transition back to the completed state once the valuer
              has completed updating the report. If the job is reopened for an
              amendment, the query closed notification will have a status of{' '}
              <code>IN_PROGRESS</code>.
            </li>
            <li>
              <b>Update Valuation</b> – In the case of Supported or Alternative
              resolution, the valuer may be required to revalue the property
              resulting from additional information or a change in scope, and
              thus request an additional fee. This will create a new separate
              job with a quoted fee that has to be accepted for the job to be
              completed.
            </li>
          </ul>

          <Stack alignItems='center' gap={1} my={6}>
            <Box
              component="img"
              maxWidth='100%'
              height='auto'
              src="/assets/images/psx-query-workflow-diagram.png"
              alt="Figure 5.2 - Query Workflow Diagram"
            />

            <Typography variant="body2" fontSize={13}>
              Figure 5.2 - Query Workflow Diagram
            </Typography>
          </Stack>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Query Related Methods
          </Typography>

          <br />

          <RelatedMethodsTable data={queryMethods} />

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Conversion Reporting
          </Typography>

          <br />

          <Typography variant="body2">
            In the case of broker ordered Jobs, where a lender would like to
            track conversion of valuations ordered against loan applications, an
            Customer can ‘stamp’ a job as converted by calling the{' '}
            <Link component={RouterLink} to="/apis/psx-apis/psx-set-retrieved">
              set retrieved
            </Link>{' '}
            method. This method will simply mark the job as converted for job
            conversion reporting.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Conversion reporting related methods
          </Typography>

          <br />

          <RelatedMethodsTable data={conversionReportingMethods} />

          <br />
          <br />
        </Box>
      </Box>
    </>
  );
};
