import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { TermsAndConditionsBody } from './TermsAndConditionsBody';
import { useRootStore } from '../../stores/rootStore';

export const TermsAndConditionsPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Terms and Conditions | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack p={4}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            color="primary.main"
            textAlign="center"
            fontWeight={600}
          >
            Terms and Conditions
          </Typography>

          <br />

          <TermsAndConditionsBody />
        </Container>
      </Stack>
    </>
  );
};
