import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link as RouterLink } from 'react-router-dom';
import { useRootStore } from '../../../stores/rootStore';

const CountryOfBirthMetrics = () => (
  <>
    <Typography
      id="country-of-birth-metrics"
      variant="h6"
      fontWeight={600}
      my={0.5}
    >
      Country of Birth
    </Typography>

    <Typography variant="body2">
      The Country of Birth Metrics relate to the countries of birth for the
      residents in a particular location.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={54}>102</TableCell>
            <TableCell>1046</TableCell>
            <TableCell>Country of Birth: Australia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Australia in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1047</TableCell>
            <TableCell>Country of Birth: Canada</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Canada in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1048</TableCell>
            <TableCell>Country of Birth: China</TableCell>
            <TableCell>
              The percentage breakdown of residents born in China in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1049</TableCell>
            <TableCell>Country of Birth: Croatia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Croatia in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1050</TableCell>
            <TableCell>Country of Birth: Egypt</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Egypt in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1051</TableCell>
            <TableCell>Country of Birth: Fiji</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Fiji in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1052</TableCell>
            <TableCell>Country of Birth: France</TableCell>
            <TableCell>
              The percentage breakdown of residents born in France in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1053</TableCell>
            <TableCell>Country of Birth: Germany</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Germany in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1054</TableCell>
            <TableCell>Country of Birth: Greece</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Greece in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1055</TableCell>
            <TableCell>Country of Birth: Hong Kong</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Hong Kong in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1056</TableCell>
            <TableCell>Country of Birth: India</TableCell>
            <TableCell>
              The percentage breakdown of residents born in India in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1057</TableCell>
            <TableCell>Country of Birth: Indonesia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Indonesia in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1058</TableCell>
            <TableCell>Country of Birth: Ireland</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Ireland in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1059</TableCell>
            <TableCell>Country of Birth: Italy</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Italy in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1060</TableCell>
            <TableCell>Country of Birth: Korea</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Korea in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1061</TableCell>
            <TableCell>Country of Birth: Lebanon</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Lebanon in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1062</TableCell>
            <TableCell>Country of Birth: Macedonia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Macedonia in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1063</TableCell>
            <TableCell>Country of Birth: Malaysia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Malaysia in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1064</TableCell>
            <TableCell>Country of Birth: Malta</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Malta in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1065</TableCell>
            <TableCell>Country of Birth: Netherlands</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Netherlands in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1066</TableCell>
            <TableCell>Country of Birth: New Zealand</TableCell>
            <TableCell>
              The percentage breakdown of residents born in New Zealand in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1067</TableCell>
            <TableCell>Country of Birth: Philippines</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Philippines in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1068</TableCell>
            <TableCell>Country of Birth: Poland</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Poland in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1069</TableCell>
            <TableCell>Country of Birth: Singapore</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Singapore in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1070</TableCell>
            <TableCell>Country of Birth: South Africa</TableCell>
            <TableCell>
              The percentage breakdown of residents born in South Africa in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1071</TableCell>
            <TableCell>Country of Birth: Sri Lanka</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Sri Lanka in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1072</TableCell>
            <TableCell>Country of Birth: Turkey</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Turkey in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1073</TableCell>
            <TableCell>Country of Birth: UK</TableCell>
            <TableCell>
              The percentage breakdown of residents born in UK in the
              geographical area. This metric is not available in 2016.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1074</TableCell>
            <TableCell>Country of Birth: USA</TableCell>
            <TableCell>
              The percentage breakdown of residents born in USA in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1075</TableCell>
            <TableCell>Country of Birth: Vietnam</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Vietnam in the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1076</TableCell>
            <TableCell>Country of Birth: South Eastern Europe</TableCell>
            <TableCell>
              The percentage breakdown of residents born in South Eastern Europe
              in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1077</TableCell>
            <TableCell>Country of Birth: Elsewhere</TableCell>
            <TableCell>
              The percentage breakdown of residents born in an unlisted country
              in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1279</TableCell>
            <TableCell>Country of Birth: Afghanistan</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Afghanistan in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1280</TableCell>
            <TableCell>Country of Birth: Bangladesh</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Bangladesh in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1281</TableCell>
            <TableCell>Country of Birth: Bosnia and Herzegovina</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Bosnia and
              Herzegovina in the geographical area. This metric will only
              display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1282</TableCell>
            <TableCell>Country of Birth: Cambodia</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Cambodia in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1283</TableCell>
            <TableCell>Country of Birth: Chile</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Chile in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1284</TableCell>
            <TableCell>Country of Birth: England</TableCell>
            <TableCell>
              The percentage breakdown of residents born in England in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1285</TableCell>
            <TableCell>Country of Birth: Mauritius</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Mauritius in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1286</TableCell>
            <TableCell>Country of Birth: Iran</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Iran in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1287</TableCell>
            <TableCell>Country of Birth: Iraq</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Iraq in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1288</TableCell>
            <TableCell>Country of Birth: Japan</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Japan in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1289</TableCell>
            <TableCell>Country of Birth: Myanmar</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Myanmar in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1290</TableCell>
            <TableCell>Country of Birth: Nepal</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Nepal in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1291</TableCell>
            <TableCell>Country of Birth: Northern Ireland</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Northern Ireland in
              the geographical area. This metric will only display the 2016
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1292</TableCell>
            <TableCell>Country of Birth: Not Stated</TableCell>
            <TableCell>
              The percentage breakdown of residents who did not state where they
              were born. This metric will only display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1293</TableCell>
            <TableCell>Country of Birth: Pakistan</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Pakistan in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1294</TableCell>
            <TableCell>Country of Birth: Papua New Guinea</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Papua New Guinea in
              the geographical area. This metric will only display the 2016
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1295</TableCell>
            <TableCell>Country of Birth: Scotland</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Scotland in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1296</TableCell>
            <TableCell>Country of Birth: Taiwan</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Taiwan in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1297</TableCell>
            <TableCell>Country of Birth: Thailand</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Thailand in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1298</TableCell>
            <TableCell>Country of Birth: Wales</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Wales in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1299</TableCell>
            <TableCell>Country of Birth: Zimbabwe</TableCell>
            <TableCell>
              The percentage breakdown of residents born in Zimbabwe in the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const EducationMetrics = () => (
  <>
    <Typography id="education-metrics" variant="h6" fontWeight={600} my={0.5}>
      Education Metrics
    </Typography>

    <Typography variant="body2">
      The Education Metrics relate to the levels of Education achieved for a
      particular location.
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Education
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={7}>104</TableCell>
            <TableCell>1087</TableCell>
            <TableCell>Education: Pre School</TableCell>
            <TableCell>
              The percentage of Pre School level achieved for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1088</TableCell>
            <TableCell>Education: Primary School</TableCell>
            <TableCell>
              The percentage of Primary School level achieved for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1089</TableCell>
            <TableCell>Education: Secondary School</TableCell>
            <TableCell>
              The percentage of Secondary School level achieved for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1090</TableCell>
            <TableCell>Education: TAFE</TableCell>
            <TableCell>
              The percentage of TAFE level achieved for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1091</TableCell>
            <TableCell>Education: University</TableCell>
            <TableCell>
              The percentage of University level achieved for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1092</TableCell>
            <TableCell>Education: Other</TableCell>
            <TableCell>
              The percentage of Other level achieved for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1093</TableCell>
            <TableCell>Education: Not Attending</TableCell>
            <TableCell>
              The percentage of No Attendance achieved for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Qualification
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={7}>103</TableCell>
            <TableCell>1079</TableCell>
            <TableCell>Qualification: Postgrad</TableCell>
            <TableCell>
              The percentage of Postgrads achieved for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1080</TableCell>
            <TableCell>
              Qualification: Graduate Diploma or Certificate
            </TableCell>
            <TableCell>
              The percentage of Graduate Diplomas or Certificates achieved for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1081</TableCell>
            <TableCell>Qualification: Bachelor</TableCell>
            <TableCell>
              The percentage of Bachelors achieved for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1082</TableCell>
            <TableCell>Qualification: Advanced Diploma or Diploma</TableCell>
            <TableCell>
              The percentage of Advanced Diplomas or Diplomas achieved for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1083</TableCell>
            <TableCell>Qualification: Certificate</TableCell>
            <TableCell>
              The percentage of Certificates achieved for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1084</TableCell>
            <TableCell>Qualification: Not Started</TableCell>
            <TableCell>
              The percentage of qualifications Not Started for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1085</TableCell>
            <TableCell>Qualification: Not Applicable</TableCell>
            <TableCell>
              The percentage of no qualifications achieved for the geographical
              area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography fontWeight={600}>New Zealand Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Education
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={6}>114</TableCell>
            <TableCell>1211</TableCell>
            <TableCell>Education: Higher Tertiary</TableCell>
            <TableCell>
              The percentage of Higher Tertiary level achieved for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1212</TableCell>
            <TableCell>Education: Tertiary</TableCell>
            <TableCell>
              The percentage of Tertiary level achieved for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1213</TableCell>
            <TableCell>Education: Vocational</TableCell>
            <TableCell>
              The percentage of Vocational level achieved for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1214</TableCell>
            <TableCell>Education: Secondary</TableCell>
            <TableCell>
              The percentage of Secondary level achieved for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1215</TableCell>
            <TableCell>Education: No Qualification</TableCell>
            <TableCell>
              The percentage of No Qualification level achieved for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1216</TableCell>
            <TableCell>Education: Other</TableCell>
            <TableCell>
              The percentage of Other level achieved for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const EmploymentAndOccupationMetrics = () => (
  <>
    <Typography
      id="employment-and-occupation-metrics"
      variant="h6"
      fontWeight={600}
      my={0.5}
    >
      Education Metrics
    </Typography>

    <Typography variant="body2">
      The Employment & Occupation Metrics relates to the employment status,
      occupation type and method of travel to work of residents for a particular
      location.
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Occupation
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={9}>119</TableCell>
            <TableCell>1264</TableCell>
            <TableCell>Occupation: Professional</TableCell>
            <TableCell>
              The percentage of residents working in a Professional occupation
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1265</TableCell>
            <TableCell>Occupation: Clerical</TableCell>
            <TableCell>
              The percentage of residents working in a Clerical occupation for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1266</TableCell>
            <TableCell>Occupation: Trades</TableCell>
            <TableCell>
              The percentage of residents working in a Trades occupation for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1267</TableCell>
            <TableCell>Occupation: Labourer</TableCell>
            <TableCell>
              The percentage of residents working in a Labourer occupation for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1268</TableCell>
            <TableCell>Occupation: Other</TableCell>
            <TableCell>
              The percentage of residents working in an Other occupation for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1315</TableCell>
            <TableCell>Occupation: Machinery Operators and Drivers</TableCell>
            <TableCell>
              The percentage of residents working in a Machinery Operators and
              Driver occupation for the geographical area. This metric will only
              display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1316</TableCell>
            <TableCell>Occupation: Managers</TableCell>
            <TableCell>
              The percentage of residents working in a Manager occupation for
              the geographical area. This metric will only display the 2016
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1317</TableCell>
            <TableCell>
              Occupation: Community and Personal Service Workers
            </TableCell>
            <TableCell>
              The percentage of residents working in a Community and Personal
              Service occupation for the geographical area. This metric will
              only display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1318</TableCell>
            <TableCell>Occupation: Sales Workers</TableCell>
            <TableCell>
              The percentage of residents working in a Sales occupation for the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Transport to Work
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={17}>111</TableCell>
            <TableCell>1181</TableCell>
            <TableCell>Transport to Work: Bicycle</TableCell>
            <TableCell>
              The percentage of residents that use a Bicycle to get to work for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1182</TableCell>
            <TableCell>Transport to Work: Bus</TableCell>
            <TableCell>
              The percentage of residents that use a Bus to get to work for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1183</TableCell>
            <TableCell>Transport to Work: Car (Drive)</TableCell>
            <TableCell>
              The percentage of residents that use a Car (Drive) to get to work
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1184</TableCell>
            <TableCell>Transport to Work: Car (Passenger)</TableCell>
            <TableCell>
              The percentage of residents that use a Car (Passenger) to get to
              work for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1185</TableCell>
            <TableCell>Transport to Work: Ferry</TableCell>
            <TableCell>
              The percentage of residents that use a Ferry to get to work for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1186</TableCell>
            <TableCell>Transport to Work: Motorbike</TableCell>
            <TableCell>
              The percentage of residents that use a Motorbike to get to work
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1187</TableCell>
            <TableCell>Transport to Work: Other</TableCell>
            <TableCell>
              The percentage of residents that use Other methods to get to work
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1188</TableCell>
            <TableCell>Transport to Work: Taxi</TableCell>
            <TableCell>
              The percentage of residents that use a Taxi to get to work for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1189</TableCell>
            <TableCell>Transport to Work: Train</TableCell>
            <TableCell>
              The percentage of residents that use a Train to get to work for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1190</TableCell>
            <TableCell>Transport to Work: Tram</TableCell>
            <TableCell>
              The percentage of residents that use a Tram to get to work for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1191</TableCell>
            <TableCell>Transport to Work: Truck</TableCell>
            <TableCell>
              The percentage of residents that use a Truck to get to work for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1192</TableCell>
            <TableCell>Transport to Work: Walk</TableCell>
            <TableCell>
              The percentage of residents that Walk to get to work for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1310</TableCell>
            <TableCell>Transport to Work: Did not Travel</TableCell>
            <TableCell>
              The percentage of residents that Did not Travel to get to work for
              the geographical area. This metric will only display the 2016
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1311</TableCell>
            <TableCell>Transport to Work: Not Stated</TableCell>
            <TableCell>
              The percentage of residents that Did not state how they get to
              work for the geographical area. This metric will only display the
              2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1312</TableCell>
            <TableCell>Transport to Work: Three Methods of Transport</TableCell>
            <TableCell>
              The percentage of residents that used three methods of transport
              to get to work for the geographical area. This metric will only
              display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1313</TableCell>
            <TableCell>Transport to Work: Two Methods of Transport</TableCell>
            <TableCell>
              The percentage of residents that used two methods of transport to
              get to work for the geographical area. This metric will only
              display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1314</TableCell>
            <TableCell>Transport to Work: Work from Home</TableCell>
            <TableCell>
              The percentage of residents that worked from home for the
              geographical area. This metric will only display the 2016 Census
              data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography fontWeight={600}>New Zealand Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Employment
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={2}>116</TableCell>
            <TableCell>1228</TableCell>
            <TableCell>Employment: Employed</TableCell>
            <TableCell>
              The percentage of residents that are Employed for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1229</TableCell>
            <TableCell>Employment: Unemployed</TableCell>
            <TableCell>
              The percentage of residents that are Unemployed for the
              geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Occupation
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={9}>115</TableCell>
            <TableCell>1218</TableCell>
            <TableCell>Occupation: Professional</TableCell>
            <TableCell>
              The percentage of residents working in a Professional occupation
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1219</TableCell>
            <TableCell>Occupation: Clerical</TableCell>
            <TableCell>
              The percentage of residents working in a Clerical occupation for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1220</TableCell>
            <TableCell>Occupation: Trades</TableCell>
            <TableCell>
              The percentage of residents working in a Trades occupation for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1221</TableCell>
            <TableCell>Occupation: Legislators, Admin and Managers</TableCell>
            <TableCell>
              The percentage of residents working in a Legislators, Admin and
              Managers occupation for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1222</TableCell>
            <TableCell>Occupation: Technicians</TableCell>
            <TableCell>
              The percentage of residents working in a Technicians occupation
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1223</TableCell>
            <TableCell>Occupation: Service and Sales</TableCell>
            <TableCell>
              The percentage of residents working in a Service and Sales
              occupation for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1224</TableCell>
            <TableCell>Occupation: Agricultural and Fishery</TableCell>
            <TableCell>
              The percentage of residents working in an Agricultural and Fishery
              occupation for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1225</TableCell>
            <TableCell>Occupation: Plant and Machinery Operator</TableCell>
            <TableCell>
              The percentage of residents working in a Plant and Machinery
              Operator occupation for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1226</TableCell>
            <TableCell>Occupation: Elementary Occupations</TableCell>
            <TableCell>
              The percentage of residents working in an Elementary occupation
              for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const HouseholdMetrics = () => (
  <>
    <Typography id="household-metrics" variant="h6" fontWeight={600} my={0.5}>
      Household Metrics
    </Typography>

    <Typography variant="body2">
      The Household Metrics relate to Household Composition, Income, Mortgage
      Repayments, Number of Vehicles, Occupancy, and Property Types for a
      particular location.
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Household Composition
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={6}>107</TableCell>
            <TableCell>1115</TableCell>
            <TableCell>Household Composition: Childless Couples</TableCell>
            <TableCell>
              The percentage of Childless Couples households for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1116</TableCell>
            <TableCell>Household Composition: Couples with Children</TableCell>
            <TableCell>
              The percentage of Couples with Children households for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1117</TableCell>
            <TableCell>Household Composition: Single Parents</TableCell>
            <TableCell>
              The percentage of Single Parent households for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1118</TableCell>
            <TableCell>Household Composition: Lone Households</TableCell>
            <TableCell>
              The percentage of Lone households for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1119</TableCell>
            <TableCell>Household Composition: Group Households</TableCell>
            <TableCell>
              The percentage of Group households for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1120</TableCell>
            <TableCell>Household Composition: Other</TableCell>
            <TableCell>
              The percentage of Other households for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Annual Household Income
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={16}>105</TableCell>
            <TableCell>1319</TableCell>
            <TableCell>Household Income: 0-13K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              0-13K range for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1096</TableCell>
            <TableCell>Household Income: 13-26K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              13-26K range for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1097</TableCell>
            <TableCell>Household Income: 26-33.8K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              26-33.8K range for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1098</TableCell>
            <TableCell>Household Income: 33.8-52K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              33.8-52K range for the geographical area. This metric will display
              the 2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1099</TableCell>
            <TableCell>Household Income: 52-72.8K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              52-72.8K range for the geographical area. This metric will only
              display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1100</TableCell>
            <TableCell>Household Income: 72.8-104K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              72.8-104K range for the geographical area. This metric will only
              display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1101</TableCell>
            <TableCell>Household Income: 104K+</TableCell>
            <TableCell>
              The percentage of households with an income that is 104K and over
              for the geographical area. This metric will only display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1102</TableCell>
            <TableCell>Household Income: 0-15.6K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              0-15.6K range for the geographical area. This metric will display
              both the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1320</TableCell>
            <TableCell>Household Income: 15.6-33.8K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              15.6-33.8K range for the geographical area. This metric will
              display the 2011 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1103</TableCell>
            <TableCell>Household Income: 15.6-31.2K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              15.6-31.2K range for the geographical area. This metric will only
              display the 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1104</TableCell>
            <TableCell>Household Income: 31.2-52K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              31.2-52K range for the geographical area. This metric will only
              display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1105</TableCell>
            <TableCell>Household Income: 52-78K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              52-78K range for the geographical area. This metric will only
              display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1106</TableCell>
            <TableCell>Household Income: 78-130K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              78-130K range for the geographical area. This metric will only
              display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1107</TableCell>
            <TableCell>Household Income: 130-182K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              130-182K range for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1108</TableCell>
            <TableCell>Household Income: 182K+</TableCell>
            <TableCell>
              The percentage of households with an income that is 182K and over
              for the geographical area. This metric will only display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Household Occupancy (Tenure)
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={5}>106</TableCell>
            <TableCell>1110</TableCell>
            <TableCell>Tenure: Renting</TableCell>
            <TableCell>
              The percentage of households where the occupant is renting for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1111</TableCell>
            <TableCell>Tenure: Owns Outright</TableCell>
            <TableCell>
              The percentage of households where the occupant owns the property
              outright for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1112</TableCell>
            <TableCell>Tenure: Purchaser</TableCell>
            <TableCell>
              The percentage of households where the occupant is the purchaser
              for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1113</TableCell>
            <TableCell>Tenure: Other</TableCell>
            <TableCell>
              The percentage of households where the occupant is other for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1300</TableCell>
            <TableCell>Tenure: Not Stated</TableCell>
            <TableCell>
              The percentage of households where the occupant is not stated for
              the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Mortgage Repayment
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={23}>108</TableCell>
            <TableCell>1122</TableCell>
            <TableCell>Mortgage Repayment: Under $250</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayment
              that is under $200 for the geographical area. This metric will
              display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1123</TableCell>
            <TableCell>Mortgage Repayment: $250 - $399</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $250 - $399 range for the geographical area.
              This metric will display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1124</TableCell>
            <TableCell>Mortgage Repayment: $400 - $549</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $400 - $549 range for the geographical area.
              This metric will display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1125</TableCell>
            <TableCell>Mortgage Repayment: $550 - $749</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $550 - $749 range for the geographical area.
              This metric will display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1126</TableCell>
            <TableCell>Mortgage Repayment: $750 - $949</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $750 - $949 range for the geographical area.
              This metric will display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1127</TableCell>
            <TableCell>Mortgage Repayment: $950 - $1199</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $950 - $1199 range for the geographical
              area. This metric will only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1128</TableCell>
            <TableCell>Mortgage Repayment: $1200 - $1400</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1200 - $1400 range for the geographical
              area. This metric will only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1129</TableCell>
            <TableCell>Mortgage Repayment: $1400 - $1600</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1400 - $1600 range for the geographical
              area. This metric will only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1130</TableCell>
            <TableCell>Mortgage Repayment: $1600 - $1800</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1600 - $1800 range for the geographical
              area. This metric will only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1131</TableCell>
            <TableCell>Mortgage Repayment: $1800 - $2000</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1800 - $2000 range for the geographical
              area. This metric will only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1132</TableCell>
            <TableCell>Mortgage Repayment: Over $2000</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that is Over $2000 for the geographical area. This metric will
              only display the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1134</TableCell>
            <TableCell>Mortgage Repayment: Under $300</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that is under $300 for the geographical area. This metric will
              only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1135</TableCell>
            <TableCell>Mortgage Repayment: $300 - $449</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $300 - $449 range for the geographical area.
              This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1136</TableCell>
            <TableCell>Mortgage Repayment: $450 - $599</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $450 - $599 range for the geographical area.
              This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1137</TableCell>
            <TableCell>Mortgage Repayment: $600 - $799</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $600 - $799 range for the geographical area.
              This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1138</TableCell>
            <TableCell>Mortgage Repayment: $800 - $999</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $800 - $999 range for the geographical area.
              This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1139</TableCell>
            <TableCell>Mortgage Repayment: $1000 - $1399</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1000 - $1399 range for the geographical
              area. This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1140</TableCell>
            <TableCell>Mortgage Repayment: $1400 - $1799</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1400 - $1799 range for the geographical
              area. This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1141</TableCell>
            <TableCell>Mortgage Repayment: $1800 - $2399</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $1800 - $2399 range for the geographical
              area. This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1142</TableCell>
            <TableCell>Mortgage Repayment: $2400 - $2999</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $2400 - $2999 range for the geographical
              area. This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1143</TableCell>
            <TableCell>Mortgage Repayment: $3000 - $3999</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that falls within the $3000 - $3999 range for the geographical
              area. This metric will only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1144</TableCell>
            <TableCell>Mortgage Repayment: Over $4000</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that is Over $4000 for the geographical area. This metric will
              only display the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1145</TableCell>
            <TableCell>Mortgage Repayment: Not Stated</TableCell>
            <TableCell>
              The percentage of households with a monthly mortgage repayments
              that is Not Stated for the geographical area. This metric will
              only display the 2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Property Type
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={5}>109</TableCell>
            <TableCell>1147</TableCell>
            <TableCell>Property Type: House</TableCell>
            <TableCell>
              The percentage of Houses in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1148</TableCell>
            <TableCell>Property Type: Semi Detached</TableCell>
            <TableCell>
              The percentage of Semi Detached properties in the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1149</TableCell>
            <TableCell>Property Type: Flat Unit or Apartment</TableCell>
            <TableCell>
              The percentage of Flats, Units or Apartments in the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1150</TableCell>
            <TableCell>Property Type: Unoccupied</TableCell>
            <TableCell>
              The percentage of Unoccupied properties in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1151</TableCell>
            <TableCell>Property Type: Other</TableCell>
            <TableCell>
              The percentage of Other property types in the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Vehicles per Household
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={4}>101</TableCell>
            <TableCell>1041</TableCell>
            <TableCell>Vehicles per Household: none</TableCell>
            <TableCell>
              The percentage of 0 vehicles per household for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1042</TableCell>
            <TableCell>Vehicles per Household: One Vehicle</TableCell>
            <TableCell>
              The percentage of 1 vehicle per household for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1043</TableCell>
            <TableCell>Vehicles per Household: 2</TableCell>
            <TableCell>
              The percentage of 2 vehicles per household for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1044</TableCell>
            <TableCell>Vehicles per Household: 3+</TableCell>
            <TableCell>
              The percentage of 3 or more vehicles per household for the
              geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography fontWeight={600}>New Zealand Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Household Income
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={6}>117</TableCell>
            <TableCell>1231</TableCell>
            <TableCell>Household Income: -20K</TableCell>
            <TableCell>
              The percentage of households with an income that is below 20k for
              the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1232</TableCell>
            <TableCell>Household Income: 20-30K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              20-30k range for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1233</TableCell>
            <TableCell>Household Income: 30-50K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              30-50k range for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1234</TableCell>
            <TableCell>Household Income: 50-70K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              50-70k range for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1235</TableCell>
            <TableCell>Household Income: 70-100K</TableCell>
            <TableCell>
              The percentage of households with an income that falls within the
              70-100k range for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1236</TableCell>
            <TableCell>Household Income: 100K-</TableCell>
            <TableCell>
              The percentage of households with an income that is 100K and over
              for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Household Structure
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={6}>118</TableCell>
            <TableCell>1238</TableCell>
            <TableCell>Household Structure: 1 Family</TableCell>
            <TableCell>
              The percentage of single family households for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1239</TableCell>
            <TableCell>Household Structure: 2 Families</TableCell>
            <TableCell>
              The percentage of 2 family households for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1240</TableCell>
            <TableCell>Household Structure: 3+ Families</TableCell>
            <TableCell>
              The percentage of 3 or more family households for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1241</TableCell>
            <TableCell>Household Structure: Lone Occupant</TableCell>
            <TableCell>
              The percentage of Lone Occupant households for the geographical
              area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1242</TableCell>
            <TableCell>Household Structure: Share Accommodation</TableCell>
            <TableCell>
              The percentage of Share Accommodation households for the
              geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1243</TableCell>
            <TableCell>Household Structure: Other</TableCell>
            <TableCell>
              The percentage of Other households for the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const PopulationMetrics = () => (
  <>
    <Typography id="population-metrics" variant="h6" fontWeight={600} my={0.5}>
      Population Metrics
    </Typography>

    <Typography variant="body2">
      The Population Metrics relate to the population count and gender ratio for
      particular location.
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian Metrics</Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>1002</TableCell>
            <TableCell>Male Population</TableCell>
            <TableCell>
              The percentage of the Male population for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={20}>120</TableCell>
            <TableCell>1003</TableCell>
            <TableCell>Male Population: 0-9</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 0-9 years
              of age for the geographical area. This metric will display the
              2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1012</TableCell>
            <TableCell>Male Population: 0-9</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 0-9 years
              of age for the geographical area. This metric will display the
              2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1004</TableCell>
            <TableCell>Male Population: 10-19</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 10-19
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1013</TableCell>
            <TableCell>Male Population: 10-19</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 10-19
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1005</TableCell>
            <TableCell>Male Population: 20-29</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 20-29
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1014</TableCell>
            <TableCell>Male Population: 20-29</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 20-29
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1006</TableCell>
            <TableCell>Male Population: 30-39</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 30-39
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1015</TableCell>
            <TableCell>Male Population: 30-39</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 30-39
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1007</TableCell>
            <TableCell>Male Population: 40-49</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 40-49
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1016</TableCell>
            <TableCell>Male Population: 40-49</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 40-49
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1008</TableCell>
            <TableCell>Male Population: 50-59</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 50-59
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1017</TableCell>
            <TableCell>Male Population: 50-59</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 50-59
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1009</TableCell>
            <TableCell>Male Population: 60-69</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 60-69
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1018</TableCell>
            <TableCell>Male Population: 60-69</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 60-69
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1010</TableCell>
            <TableCell>Male Population: 70-79</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 70-79
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1019</TableCell>
            <TableCell>Male Population: 70-79</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 70-79
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1011</TableCell>
            <TableCell>Male Population: 80-89</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 80-89
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1020</TableCell>
            <TableCell>Male Population: 80-89</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 80-89
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1273</TableCell>
            <TableCell>Male Population: 90-99</TableCell>
            <TableCell>
              The percentage of the Male population that falls within 90-99
              years of age for the geographical area. This metric will display
              the 2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1274</TableCell>
            <TableCell>Male Population: 100+</TableCell>
            <TableCell>
              The percentage of the Male population that is 100+ years of age
              and over for the geographical area. This metric will display the
              2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>1021</TableCell>
            <TableCell>Female Population</TableCell>
            <TableCell>
              The percentage of the Female population for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={20}>121</TableCell>
            <TableCell>1022</TableCell>
            <TableCell>Female Population: 0-9</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 0-9
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1031</TableCell>
            <TableCell>Female Population: 0-9</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 0-9
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1023</TableCell>
            <TableCell>Female Population: 10-19</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 10-19
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1032</TableCell>
            <TableCell>Female Population: 10-19</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 10-19
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1024</TableCell>
            <TableCell>Female Population: 20-29</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 20-29
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1033</TableCell>
            <TableCell>Female Population: 20-29</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 20-29
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1025</TableCell>
            <TableCell>Female Population: 30-39</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 30-39
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1034</TableCell>
            <TableCell>Female Population: 30-39</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 30-39
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1026</TableCell>
            <TableCell>Female Population: 40-49</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 40-49
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1035</TableCell>
            <TableCell>Female Population: 40-49</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 40-49
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1027</TableCell>
            <TableCell>Female Population: 50-59</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 50-59
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1036</TableCell>
            <TableCell>Female Population: 50-59</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 50-59
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1028</TableCell>
            <TableCell>Female Population: 60-69</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 60-69
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1037</TableCell>
            <TableCell>Female Population: 60-69</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 60-69
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1029</TableCell>
            <TableCell>Female Population: 70-79</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 70-79
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1038</TableCell>
            <TableCell>Female Population: 70-79</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 70-79
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1030</TableCell>
            <TableCell>Female Population: 80-89</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 80-89
              years of age for the geographical area. This metric will display
              the 2006 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1039</TableCell>
            <TableCell>Female Population: 80-89</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 80-89
              years of age for the geographical area. This metric will display
              the 2011 and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1276</TableCell>
            <TableCell>Female Population: 90-99</TableCell>
            <TableCell>
              The percentage of the Female population that falls within 90-99
              years of age for the geographical area. This metric will display
              the 2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1277</TableCell>
            <TableCell>Female Population: 100+</TableCell>
            <TableCell>
              The percentage of the Female population that is 100+ years of age
              and over for the geographical area. This metric will display the
              2006, 2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />

            <TableCell>1244</TableCell>
            <TableCell>Population</TableCell>
            <TableCell>
              The population amount for the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={20}>120</TableCell>
            <TableCell>1245</TableCell>
            <TableCell>Population: 0-9</TableCell>
            <TableCell>
              The percentage of the population that falls within 0-9 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1254</TableCell>
            <TableCell>Population: 0-9</TableCell>
            <TableCell>
              The percentage of the population that falls within 0-9 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1246</TableCell>
            <TableCell>Population: 10-19</TableCell>
            <TableCell>
              The percentage of the population that falls within 10-19 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1255</TableCell>
            <TableCell>Population: 10-19</TableCell>
            <TableCell>
              The percentage of the population that falls within 10-19 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1247</TableCell>
            <TableCell>Population: 20-29</TableCell>
            <TableCell>
              The percentage of the population that falls within 20-29 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1256</TableCell>
            <TableCell>Population: 20-29</TableCell>
            <TableCell>
              The percentage of the population that falls within 20-29 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1248</TableCell>
            <TableCell>Population: 30-39</TableCell>
            <TableCell>
              The percentage of the population that falls within 30-39 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1257</TableCell>
            <TableCell>Population: 30-39</TableCell>
            <TableCell>
              The percentage of the population that falls within 30-39 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1249</TableCell>
            <TableCell>Population: 40-49</TableCell>
            <TableCell>
              The percentage of the population that falls within 40-49 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1258</TableCell>
            <TableCell>Population: 40-49</TableCell>
            <TableCell>
              The percentage of the population that falls within 40-49 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1250</TableCell>
            <TableCell>Population: 50-59</TableCell>
            <TableCell>
              The percentage of the population that falls within 50-59 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1259</TableCell>
            <TableCell>Population: 50-59</TableCell>
            <TableCell>
              The percentage of the population that falls within 50-59 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1251</TableCell>
            <TableCell>Population: 60-69</TableCell>
            <TableCell>
              The percentage of the population that falls within 60-69 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1260</TableCell>
            <TableCell>Population: 60-69</TableCell>
            <TableCell>
              The percentage of the population that falls within 60-69 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1252</TableCell>
            <TableCell>Population: 70-79</TableCell>
            <TableCell>
              The percentage of the population that falls within 70-79 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1261</TableCell>
            <TableCell>Population: 70-79</TableCell>
            <TableCell>
              The percentage of the population that falls within 70-79 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1253</TableCell>
            <TableCell>Population: 80-89</TableCell>
            <TableCell>
              The percentage of the population that falls within 80-89 years of
              age for the geographical area. This metric will display the 2006
              Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1262</TableCell>
            <TableCell>Population: 80-89</TableCell>
            <TableCell>
              The percentage of the population that falls within 80-89 years of
              age for the geographical area. This metric will display the 2011
              and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1270</TableCell>
            <TableCell>Population: 90-99</TableCell>
            <TableCell>
              The percentage of the population that falls within 90-99 years of
              age for the geographical area. This metric will display the 2006,
              2011, and 2016 Census data.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1271</TableCell>
            <TableCell>Population: 100+</TableCell>
            <TableCell>
              The percentage of the population that is 100+ years of age and
              over for the geographical area. This metric will display the 2006,
              2011, and 2016 Census data.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <Typography fontWeight={600}>New Zealand Metrics</Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>1201</TableCell>
            <TableCell>Population</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell rowSpan={8}>113</TableCell>
            <TableCell>1202</TableCell>
            <TableCell>Population: 0-9</TableCell>
            <TableCell>
              The percentage of the population that falls within 0-9 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1203</TableCell>
            <TableCell>Population: 10-19</TableCell>
            <TableCell>
              The percentage of the population that falls within 10-19 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1204</TableCell>
            <TableCell>Population: 20-29</TableCell>
            <TableCell>
              The percentage of the population that falls within 20-29 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1205</TableCell>
            <TableCell>Population: 30-39</TableCell>
            <TableCell>
              The percentage of the population that falls within 30-39 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1206</TableCell>
            <TableCell>Population: 40-49</TableCell>
            <TableCell>
              The percentage of the population that falls within 40-49 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1207</TableCell>
            <TableCell>Population: 50-59</TableCell>
            <TableCell>
              The percentage of the population that falls within 50-59 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1208</TableCell>
            <TableCell>Population: 60-64</TableCell>
            <TableCell>
              The percentage of the population that falls within 60-64 years of
              age for the geographical area.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1209</TableCell>
            <TableCell>Population: 65+</TableCell>
            <TableCell>
              The percentage of the population that is 65 years of age and over
              for the geographical area.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const ReligionMetrics = () => (
  <>
    <Typography id="religion-metrics" variant="h6" fontWeight={600} my={0.5}>
      Religion Metrics
    </Typography>

    <Typography variant="body2">
      The Religion Metrics relate to the breakdown of religions of the residents
      in a particular location.
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian Metrics</Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={27}>110</TableCell>
            <TableCell>1153</TableCell>
            <TableCell>Religion: Buddhism</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Buddhism in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1154</TableCell>
            <TableCell>Religion: Hinduism</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Hinduism in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1155</TableCell>
            <TableCell>Religion: Inadequate Description</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Inadequate Description in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1156</TableCell>
            <TableCell>Religion: Islam</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Islam in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1157</TableCell>
            <TableCell>Religion: Judaism</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Judaism in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1158</TableCell>
            <TableCell>Religion: No Religion</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination No Religion in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1159</TableCell>
            <TableCell>Religion: Not Stated</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Not Stated in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1160</TableCell>
            <TableCell>Religion: Ab Traditional Religion</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Ab Traditional Religion in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1161</TableCell>
            <TableCell>Religion: Other</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Other in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1162</TableCell>
            <TableCell>Religion: Overseas Visitor</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Overseas Visitor in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1163</TableCell>
            <TableCell>Religion: Anglican</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Anglican in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1164</TableCell>
            <TableCell>Religion: Baptist</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Baptist in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1165</TableCell>
            <TableCell>Religion: Brethren</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Brethren in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1166</TableCell>
            <TableCell>Religion: Catholic</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Catholic in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1167</TableCell>
            <TableCell>Religion: Church of Christ</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Church of Christ in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1168</TableCell>
            <TableCell>Religion: Jehovahs Witness</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Jehovahs Witness in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1169</TableCell>
            <TableCell>Religion: Latter Day Saints</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Latter Day Saints in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1170</TableCell>
            <TableCell>Religion: Lutheran</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Lutheran in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1171</TableCell>
            <TableCell>Religion: Oriental Christ</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Oriental Christ in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1172</TableCell>
            <TableCell>Religion: Orthodox</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Orthodox in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1173</TableCell>
            <TableCell>Religion: Other Christian</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Other Christian in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1174</TableCell>
            <TableCell>Religion: Other Protestant</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Other Protestant in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1175</TableCell>
            <TableCell>Religion: Pentecostal</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Pentecostal in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1176</TableCell>
            <TableCell>Religion: Presbyterian</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Presbyterian in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1177</TableCell>
            <TableCell>Religion: Salvation Army</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Salvation Army in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1178</TableCell>
            <TableCell>Religion: Seventh Day Adventist</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Seventh Day Adventist in the geographical area
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1179</TableCell>
            <TableCell>Religion: Uniting Church</TableCell>
            <TableCell>
              The percentage breakdown of residents with the religious
              denomination Uniting Church in the geographical area
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const SuburbInformationMetrics = () => (
  <>
    <Typography
      id="suburb-information-metrics"
      variant="h6"
      fontWeight={600}
      my={0.5}
    >
      Suburb Information Metrics
    </Typography>

    <Typography variant="body2">
      The Suburb Information metrics relate to details of a suburb that include
      the area, number of schools/childcare centres, and the information on the
      number of parks that exist in the suburb.
      <br />
      <b>This data only exists for suburbs (locationTypeId = 8)</b>
    </Typography>

    <br />

    <Typography fontWeight={600}>Australian & New Zealand Metrics</Typography>

    <br />

    <Typography variant="body2" fontWeight={600}>
      Suburb Information
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Group Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Metric ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={5}>112</TableCell>
            <TableCell>1194</TableCell>
            <TableCell>Suburb Information: Area</TableCell>
            <TableCell>
              The approximate land area of the suburb in square metres.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1195</TableCell>
            <TableCell>Suburb Information: Schools Number</TableCell>
            <TableCell>
              The number of schools that exist within the suburb.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1196</TableCell>
            <TableCell>Suburb Information: Childcare Number</TableCell>
            <TableCell>
              The number of childcare centres that exist within the suburb.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1197</TableCell>
            <TableCell>Suburb Information: Parks Number</TableCell>
            <TableCell>
              The number of parks that exist within the suburb.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1198</TableCell>
            <TableCell>Suburb Information: Park Percentage</TableCell>
            <TableCell>The ratio of parks to the total suburb area.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export const MetricTypesCensus = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Typography
        id="metric-types-census"
        variant="h5"
        fontWeight={600}
        my={0.5}
      >
        Census
      </Typography>

      <Typography variant="body2">
        Our census data is available via our{' '}
        <Link
          component={RouterLink}
          to={`/apis/corelogic-apis/market-insights-${country}#/census`}
        >
          Census Statistics
        </Link>{' '}
        and{' '}
        <Link
          component={RouterLink}
          to={`/apis/corelogic-apis/market-insights-${country}#/charts`}
        >
          Charts
        </Link>{' '}
        services. The Census metrics available contain census data as gathered
        by the Australian Bureau of Statistics (ABS) and Statistics New Zealand.
      </Typography>

      <ul>
        <li>
          <Link href="#country-of-birth-metrics">Country of Birth</Link>
        </li>
        <li>
          <Link href="#education-metrics">Education</Link>
        </li>
        <li>
          <Link href="#employment-and-occupation-metrics">
            Employment & Occupation
          </Link>
        </li>
        <li>
          <Link href="#household-metrics">Household</Link>
        </li>
        <li>
          <Link href="#population-metrics">Population</Link>
        </li>
        <li>
          <Link href="#religion-metrics">Religion</Link>
        </li>
        <li>
          <Link href="#suburb-information-metrics">Suburb Information</Link>
        </li>
      </ul>

      <br />

      <CountryOfBirthMetrics />

      <br />
      <br />

      <EducationMetrics />

      <br />
      <br />

      <EmploymentAndOccupationMetrics />

      <br />
      <br />

      <HouseholdMetrics />

      <br />
      <br />

      <PopulationMetrics />

      <br />
      <br />

      <ReligionMetrics />

      <br />
      <br />

      <SuburbInformationMetrics />
    </>
  );
};
