import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Helmet } from 'react-helmet';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { styles } from './StandardsAndConventionsPage.styles';
import { useRootStore } from '../../../stores/rootStore';

export const StandardsAndConventionsPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | Standards And Conventions | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Standards and Conventions
          </Typography>

          <Typography fontWeight={500}>
            Learn the basics before you dive in.
          </Typography>

          <br />
          <br />

          <Typography variant="body2">
            With our APIs you get real-time access to the best property data,
            insights and analytics straight from the largest provider of
            property information in Australia and New Zealand. Get detailed
            property information including recent sales, current sales and
            rental listings, photos, valuations, comparable sales or rentals,
            and a timeline of property events backed up by more than 4 billion
            property decision points.
            <br />
            <br />
            All of our APIs are based on REST principles, using only standard
            verbs and status codes with JSON-encoded responses. Transport is TLS
            encryped, and requests require an OAuth2 Authorization Bearer token.
            <br />
            <br />
            Sign up to create an API Application with access to our Sandbox
            environment, with a limited dataset and quota.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            HTTP response status codes
          </Typography>

          <br />

          <Typography variant="body2">
            Our APIs use the conventional HTTP response status codes to indicate
            the success or failure of an API request . In general, codes in the
            2xx range indicate success, the 4xx range indicate an API request
            error, and, 5xx is a network or service error.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={500}>
            HTTP response status codes summary
          </Typography>

          <br />

          <TableContainer component={Paper} sx={{ maxWidth: 1200 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: '#1fa25b', fontWeight: 500 }}
                  >
                    200 OK
                  </TableCell>
                  <TableCell>The request has succeeded.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: 'primary.main', fontWeight: 500 }}
                  >
                    401 Unauthorized
                  </TableCell>
                  <TableCell>The request is unauthenticated.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: 'primary.main', fontWeight: 500 }}
                  >
                    403 Forbidden
                  </TableCell>
                  <TableCell>
                    The client does not have access rights to the requested
                    content.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: 'primary.main', fontWeight: 500 }}
                  >
                    404 Not Found
                  </TableCell>
                  <TableCell>
                    Requested resource or API path does not exist
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: 'primary.main', fontWeight: 500 }}
                  >
                    429 Too Many Requests
                  </TableCell>
                  <TableCell>
                    Sandbox quota or rate limit has been exceeded, retry with
                    backoff.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{ color: 'primary.main', fontWeight: 500 }}
                  >
                    5xx Server or Network Error
                  </TableCell>
                  <TableCell>Server or network unavailable.</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            HTTP methods
          </Typography>

          <br />

          <Typography variant="body2">
            Our APIs support the following HTTP methods for interacting with
            resources:
          </Typography>

          <br />

          <Typography variant="body2" bgcolor="#61affe" sx={styles.httpMethod}>
            GET
          </Typography>

          <br />

          <Typography variant="body2">
            Make a GET request to retrieve data.
          </Typography>

          <br />

          <Typography variant="body2" bgcolor="#49cc90" sx={styles.httpMethod}>
            POST
          </Typography>

          <br />

          <Typography variant="body2">
            Use a POST request to create new resources.
          </Typography>

          <br />

          <Typography variant="body2" bgcolor="#fca130" sx={styles.httpMethod}>
            PUT
          </Typography>

          <br />

          <Typography variant="body2">
            Make a PUT request to update a resource.
          </Typography>

          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Parameters
          </Typography>

          <br />

          <Typography variant="body2">
            Our APIs use three main categories of parameters for each endpoint:
            path, query, request body. Our API documentation includes a list of
            available parameters for each request.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Path parameters
          </Typography>

          <br />

          <Typography variant="body2">
            Some of our API URLs include resource names and unique identifiers
            to help you structure your requests. You must replace them with real
            values when making a request. For example:
          </Typography>

          <br />

          <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
            {`curl -X GET --header "Authorization: Bearer ***" "https://api-sbox.corelogic.asia/property-details/au/properties/{propertyId}/attributes/additional"`}
          </SyntaxHighlighter>

          <br />

          <Typography variant="body2">
            In this request, the URL includes 1 main resource, propertyId. You
            must replace it with a real value when you make a request. After you
            replace this value with actual data, your final request should look
            something like this:
          </Typography>

          <br />

          <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
            {`curl -X GET --header "Authorization: Bearer ***" "https://api-sbox.corelogic.asia/property-details/au/properties/353657253/attributes/additional"`}
          </SyntaxHighlighter>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Query string parameters
          </Typography>

          <br />

          <Typography variant="body2">
            Some of our APIs use query string parameters for sorting, filtering
            and pagination. The format for query string parameters is the full
            resource URL followed by a question mark, and the optional
            parameters:
          </Typography>

          <br />

          <SyntaxHighlighter language="http" style={a11yDark} wrapLongLines>
            {`https://api-sbox.corelogic.asia/search/au/property/postcode/43434342?parameter1=foo&amp;parameter2=bar`}
          </SyntaxHighlighter>

          <br />

          <Typography variant="body2">For example:</Typography>

          <br />

          <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
            {`curl -X GET --header "Authorization: Bearer ***" "https://api-sbox.corelogic.asia/search/au/property/postcode/43434342?baths=2&amp;pTypes=UNIT"`}
          </SyntaxHighlighter>

          <br />

          <Typography variant="body2">
            In this request, the URL includes 2 query string parameters, baths
            and pType.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Request body parameters
          </Typography>

          <br />

          <Typography variant="body2">
            For some of our APIs you must include a request body in JSON format.
            The following example shows you how to get an automated valuation
            estimate with an HTTP POST request.
          </Typography>

          <br />

          <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
            {`curl -X POST  --header "Content-Type: application/json" --header "Authorization: Bearer ***" -d '{
  "bathrooms": 2,
  "bedrooms": 2,
  "carSpaces": 1,
  "propertyType": "UNIT"
}' "https://api-sbox.corelogic.asia/avm/au/properties/2424232/liveavm/intellival/consumer"`}
          </SyntaxHighlighter>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Response body
          </Typography>

          <br />

          <Typography variant="body2">
            Every API call response includes headers and an optional
            JSON-formatted body. The documentation includes all response body
            parameters. The following example shows the type of JSON-formatted
            response to expect from a POST request to Suggestion service:
          </Typography>

          <br />

          <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
            {`{
  "suggestions": [
    {
      "isBodyCorporate": false,
      "isUnit": false,
      "localityId": 2795,
      "locationType": "address",
      "postcodeId": 401249,
      "propertyId": 16,
      "streetId": 16,
      "suggestion": "1 Aardvark Street Adelaide SA 5000"
    },
    {
      "isBodyCorporate": false,
      "isUnit": false,
      "localityId": 3723,
      "locationType": "address",
      "postcodeId": 300303,
      "propertyId": 13,
      "streetId": 13,
      "suggestion": "1 Aardvark Street Broadbeach QLD 4218"
    }
  ],
  "systemInfo": {
    "instanceName": "59:8080",
    "requestDate": "2024-02-12T14:55:14.199+10:00"
  }
}`}
          </SyntaxHighlighter>
        </Box>
      </Box>
    </>
  );
};
