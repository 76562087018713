import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const GettingStartedHeader = () => (
  <Stack minHeight={240} bgcolor="#1f323e" color="white" textAlign="center" p={8}>
    <Stack>
      <Typography variant="h5" fontSize={40} fontWeight={500}>
        Get Started
      </Typography>

      <Typography>
        A snapshot of the journey you take when you start developing with
        CoreLogic APIs.
      </Typography>
    </Stack>
  </Stack>
);
