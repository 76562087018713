import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios, { AxiosRequestConfig } from 'axios';

export const getEmailIsVerified = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const response = await axios.get(`${url}/email/is-verified`, config);

  return response.data as CLAUDUserInfo;
};

export const requestEmailVerificationLink = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const response = await axios.get(`${url}/email/verification-link`, config);

  return response.data as EmailVerificationRequestResponse;
};

export const sendEmailCompanyInvite = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  body: CompanyInviteRequest,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const { email, companyName } = body;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      email,
      companyName,
    },
  };

  const response = await axios.post(`${url}/email/company/invite`, null, config);
  
  return response.data;
};

export const acceptCompanyInvite = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  companyName: string,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      companyName,
    },
  };

  const response = await axios.post(
    `${url}/email/company/invite/accept`,
    null,
    config,
  );

  return response.data as MessageResponse;
};
