import React, { useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {
  BackstageIdentityResponse,
  SignInPageProps,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Progress,
  SignInProviderConfig,
  UserIdentity,
} from '@backstage/core-components';
import { useIdentityStore } from '../../stores/identityStore';
import { ProxiedSignInIdentity } from '../getting-started/ProxiedSignInIdentity';
import CoreLogicRed from '../../assets/images/corelogic-red.png';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { TermsAndConditionsModal } from '../terms-and-conditions/TermsAndConditionsModal';
import { styles } from './SignInPage.styles';
import Divider from '@mui/material/Divider';

type Props = SignInPageProps & {
  provider: SignInProviderConfig;
};

type LoginParams = { checkExisting?: boolean; showPopup?: boolean };

export const SignInPage = ({ onSignInSuccess, provider }: Props) => {
  const PROVIDER_STORAGE_KEY = '@backstage/core:SignInPage:provider';

  const location = useLocation();
  const navigate = useNavigate();
  const setProfile = useIdentityStore(state => state.setProfile);
  const setIsGuest = useIdentityStore(state => state.setIsGuest);
  const authApi = useApi(provider.apiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);

  const loginAsGuest = useCallback(() => {
    const guestIdentity = new ProxiedSignInIdentity({
      provider: 'guest',
      discoveryApi,
    });

    if (!localStorage.getItem(PROVIDER_STORAGE_KEY)) {
      localStorage.setItem(PROVIDER_STORAGE_KEY, 'guest');
    }

    setIsGuest(true);
    onSignInSuccess(guestIdentity);
  }, [discoveryApi, onSignInSuccess, setIsGuest]);

  const login = useCallback(
    async ({ checkExisting, showPopup }: LoginParams) => {
      try {
        let identityResponse: BackstageIdentityResponse | undefined;

        if (checkExisting) {
          // for redirecting back to 'company invite link' after logging in
          if (location.pathname.startsWith('/user/register')) {
            sessionStorage.setItem(
              'login-redirect-path',
              location.pathname + location.search,
            );

            if (location.search.includes('isUnregistered=true')) {
              loginAsGuest();
              navigate('/signup');
              return;
            }
          }

          if (localStorage.getItem(PROVIDER_STORAGE_KEY) === 'guest') {
            loginAsGuest();
            return;
          }

          identityResponse = await authApi.getBackstageIdentity({
            optional: true,
          });
        }

        if (!identityResponse && showPopup) {
          setShowLoginPage(true);
          identityResponse = await authApi.getBackstageIdentity({
            instantPopup: true,
          });
        }

        if (!identityResponse) {
          // when redirecting to 'signup page' it should automatically login as guest
          if (location.pathname.startsWith('/signup')) {
            loginAsGuest();
            return;
          }

          setShowLoginPage(true);
          return;
        }

        const profile = await authApi.getProfile();

        setIsGuest(false);
        setProfile(profile);
        onSignInSuccess(
          UserIdentity.create({
            identity: identityResponse.identity,
            authApi,
            profile,
          }),
        );

        // redirect to 'company invite link' when logged in as a user
        if (sessionStorage.getItem('login-redirect-path')) {
          navigate(sessionStorage.getItem('login-redirect-path')!);
          sessionStorage.removeItem('login-redirect-path');
        }
      } catch (err: any) {
        setShowLoginPage(true);
      }
    },
    [
      authApi,
      location.pathname,
      location.search,
      loginAsGuest,
      navigate,
      onSignInSuccess,
      setIsGuest,
      setProfile,
    ],
  );

  useEffect(() => {
    login({ checkExisting: true });
  }, [login]);

  if (!showLoginPage) {
    return <Progress />;
  }

  return (
    <Stack flex={1} direction="row">
      <Stack justifyContent="center" boxShadow={2} sx={styles.loginContainer}>
        <Box
          component="img"
          src={CoreLogicRed}
          alt="CoreLogic logo"
          height="auto"
          width={256}
          mb={2}
        />

        <Typography fontSize={22} fontWeight={500} mb={6}>
          Sign in to CoreLogic's Developer Portal
        </Typography>

        <Stack gap={2}>
          <Button variant="outlined" size="large" onClick={loginAsGuest}>
            Continue As Guest
          </Button>

          <Divider flexItem>
            <Typography fontSize="small" px={1}>
              OR
            </Typography>
          </Divider>

          <Button
            data-testid="login-button"
            variant="contained"
            size="large"
            onClick={() => login({ showPopup: true })}
          >
            Log In
          </Button>
        </Stack>

        <Typography
          color="rgba(31, 50, 62, 0.7)"
          fontSize={14}
          textAlign="center"
          mt={2}
        >
          Don't have an account?{' '}
          <Link component={RouterLink} to="/signup" onClick={loginAsGuest}>
            Sign up
          </Link>
        </Typography>

        <Typography color="rgba(31, 50, 62, 0.7)" fontSize={14} mt={6}>
          By using CoreLogic's Developer Portal, you agree to our{' '}
          <Link
            component="button"
            data-testid="terms-and-conditions-link"
            sx={{ verticalAlign: 'baseline' }}
            onClick={() => setShowTermsAndConditions(!showTermsAndConditions)}
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            data-testid="privacy-policy-link"
            href="https://www.corelogic.com.au/legals/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Stack>

      <Box flex={1} sx={styles.heroImage} />

      <TermsAndConditionsModal
        open={showTermsAndConditions}
        onClose={() => setShowTermsAndConditions(false)}
      />
    </Stack>
  );
};
