import { AnyApiFactory } from '@backstage/core-plugin-api';
import { ScmAuth } from '@backstage/integration-react';
import { scmIntegrationsApiFactory } from './scm-integrations';
import { oidcAuthApiFactory } from './oidc-auth-provider';
import { apiDocsApiFactory } from './api-docs';
import { analyticsApiFactory } from './analytics';

export const apis: AnyApiFactory[] = [
  scmIntegrationsApiFactory,
  oidcAuthApiFactory,
  apiDocsApiFactory,
  analyticsApiFactory,
  ScmAuth.createDefaultApiFactory(),
];
