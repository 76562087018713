import { SxProps } from '@mui/material/styles';

export const styles = {
  input: {
    fontSize: 14,
  },
  passwordStrengthContainer: {
    height: 17,
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    position: 'relative',
  },
  passwordStrength: {
    height: 16,
    borderRadius: '2px',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'width 750ms',
  },
} satisfies Record<string, SxProps>;
