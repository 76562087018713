import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CoreLogicRed from '../../assets/images/corelogic-red.png';

export const HeaderLogo = () => (
  <Link to="/" style={{ textDecoration: 'none' }}>
    <Stack direction="row" gap={2}>
      <Box
        component="img"
        src={CoreLogicRed}
        alt="CoreLogic Red"
        sx={{ height: 24, mt: 0.5 }}
      />

      <Divider orientation="vertical" flexItem sx={{ background: '#1f323e' }} />

      <Typography variant="subtitle1" color="#1f323e">
        Developer Portal
      </Typography>
    </Stack>
  </Link>
);
