import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { getReasonPhrase } from 'http-status-codes';
import { Link as RouterLink } from 'react-router-dom';
import SmileySad from '../../assets/svg/smiley-sad.svg';

type Props = {
  status?: number;
  message?: string;
};

export const ErrorPage = ({
  status = 404,
  message = 'Sorry, the page you were looking for could not be found.',
}: Props) => {
  return (
    <Stack flex={1} gap={1} pb={8} justifyContent="center" alignItems="center">
      <Box mb={2}>
        <img src={SmileySad} alt="Sad face" />
      </Box>
      <Typography variant="h3" fontWeight={500}>
        Error {status} - {getReasonPhrase(status)}
      </Typography>
      <Typography variant="body2">{message}</Typography>
      <Typography variant="body2" mt={2}>
        <Link component={RouterLink} to="/">
          Navigate back to the homepage
        </Link>
      </Typography>
    </Stack>
  );
};
