import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Table, TableColumn } from '@backstage/core-components';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { useStyles } from './Applications.styles';
import { TableSecretData } from '../table/TableSecretData';
import { getApigeeApps } from '../../api/apigee';

type Data = {
  name: string;
  displayName?: string;
  clientKey: string;
  clientSecret: string;
  appStatus: string;
};

const columns: TableColumn[] = [
  {
    title: 'Client Name',
    field: 'name',
    highlight: true,
    render: data => (
      <Stack>
        <Typography variant="body2" fontWeight={700}>
          {(data as Data).displayName}
        </Typography>

        {(data as Data).displayName !== (data as Data).name && (
          <Typography variant="body2" fontSize={11}>
            {(data as Data).name}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    title: 'Client Key',
    field: 'clientKey',
    render: data => <TableSecretData secret={(data as Data).clientKey} />,
  },

  {
    title: 'Client Secret',
    field: 'clientSecret',
    render: data => <TableSecretData secret={(data as Data).clientSecret} />,
  },
];

export const ApplicationsTable = () => {
  const classes = useStyles();
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apigeeApps = useQuery({
    queryKey: ['apigeeApps'],
    queryFn: () => getApigeeApps(discovery, identity),
  });

  return (
    <div className={classes.tableContainer}>
      {apigeeApps.error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          Something went wrong with getting the sandbox clients.
        </Alert>
      )}

      <Table
        options={{ paging: false }}
        data={(apigeeApps.data ?? [])
          .map(a => ({
            name: a.name,
            displayName: a.displayName,
            clientKey: a.consumerKey,
            clientSecret: a.consumerSecret,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))}
        isLoading={apigeeApps.isLoading}
        columns={columns}
        title="My Sandbox Clients"
        // @ts-ignore - subtitle can be an element but backstage expects string - https://github.com/backstage/backstage/blob/master/packages/core-components/src/components/Table/Table.tsx#L450
        subtitle={
          <Typography color="textSecondary" variant="body2">
            Sandbox clients are limited to the sandbox environment with a
            restricted dataset and API request quota. Use these clients to
            develop your ideas and explore our APIs. This allows you to get a
            feel for our API experience before you progress to a test
            environment.
          </Typography>
        }
      />
    </div>
  );
};
