import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { LinkButton } from '@backstage/core-components';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ProjectsTable } from './ProjectsTable';
import { styles } from './Projects.styles';
import { useIdentityStore } from '../../stores/identityStore';
import { getApigeeCompanyApps } from '../../api/apigee';
import { useRootStore } from '../../stores/rootStore';

export const ProjectsPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);
  const navigate = useNavigate();
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apigeeCompanyApps = useQuery({
    queryKey: ['apigeeCompanyApps'],
    queryFn: () => getApigeeCompanyApps(discovery, identity),
  });

  useEffect(() => {
    if (isGuest) navigate('/');
  }, [isGuest, navigate]);

  return (
    <>
      <Helmet>
        <title>
          API Projects | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <Stack flex={1} p={4} gap={2}>
          <LinkButton
            style={styles.createButton}
            to="/projects/email"
            color="primary"
            variant="contained"
            disabled={Object.keys(apigeeCompanyApps.data ?? {}).length === 0}
          >
            Invite Users
          </LinkButton>

          <ProjectsTable />
        </Stack>
      </Stack>
    </>
  );
};
