import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextClamp from 'react-string-clamp';
import { Entity } from '@backstage/catalog-model';
import { Link } from 'react-router-dom';

type ProductCardProps = {
  entity: Entity;
  apiAsProduct?: boolean;
};

export const ProductCard = ({ entity, apiAsProduct }: ProductCardProps) => (
  <Link
    to={
      apiAsProduct
        ? `/apis/${entity.metadata.name}`
        : `/products/${entity.metadata.name}`
    }
  >
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, ':hover': { cursor: 'pointer', boxShadow: 4 } }}
    >
      <CardContent>
        <Stack gap={2} height={180} p={1}>
          <Typography
            variant="h6"
            fontWeight={500}
            lineHeight={1.2}
            color="primary.main"
          >
            {entity.metadata.title ?? entity.metadata.name}
          </Typography>

          <Typography variant="body2" fontSize={12}>
            <TextClamp text={entity.metadata.description} lines={6} />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  </Link>
);
