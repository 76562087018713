import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useLogout } from '../../../hooks/useLogout';
import { useRootStore } from '../../../stores/rootStore';
import { useIdentityStore } from '../../../stores/identityStore';

const GuestLink = ({
  text,
  to,
  onClick,
}: {
  text: string;
  to?: string;
  onClick?: () => void;
}) => {
  const isGuest = useIdentityStore(state => state.isGuest);

  if (!isGuest) return text;
  if (!to && onClick) {
    return (
      <Link
        component="button"
        sx={{ verticalAlign: 'baseline' }}
        onClick={onClick}
      >
        {text}
      </Link>
    );
  }
  if (to) {
    return (
      <Link component={RouterLink} to={to}>
        {text}
      </Link>
    );
  }

  return text;
};

export const QuickStartPage = () => {
  const country = useRootStore(state => state.country);
  const logout = useLogout();

  return (
    <>
      <Helmet>
        <title>
          Guides | Quick Start | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Quick Start
          </Typography>

          <Typography fontWeight={500}>
            Learn how to send requests to our APIs from inside the
            documentation.
          </Typography>

          <br />
          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Up and go in 5 minutes
          </Typography>

          <br />

          <Typography variant="body2">
            This section explains how to send requests to CoreLogic APIs from
            the documentation.
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            1. <GuestLink text="Sign up" to="/signup" /> and{' '}
            <GuestLink text="Log in" onClick={logout} /> to create a Sandbox
            Client.
          </Typography>

          <br />

          <Typography variant="body2">
            Go to{' '}
            <Link component={RouterLink} to="/apps">
              Sandbox Clients
            </Link>{' '}
            and create a Sandbox application, if you haven't already done so.
          </Typography>

          <br />

          <Typography variant="body2" fontWeight={600}>
            Please note. This provides access to our Sandbox environment - a
            limited dataset and an API request quota. When the sandbox quota is
            exhausted the response will be "429 Too Many Requests".
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            2. Find a property from our{' '}
            <Link component={RouterLink} to="/guides/sandbox-test-data">
              Sandbox Test Data
            </Link>{' '}
            page.
          </Typography>

          <br />

          <Typography variant="body2">
            Here, you'll find example Sandbox properties, states, suburbs, and
            postcodes that you can use to test our APIs.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
