import { createApiFactory, configApiRef } from '@backstage/core-plugin-api';
import {
  scmIntegrationsApiRef,
  ScmIntegrationsApi,
} from '@backstage/integration-react';

export const scmIntegrationsApiFactory = createApiFactory({
  api: scmIntegrationsApiRef,
  deps: { configApi: configApiRef },
  factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
});
