import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Table, TableColumn } from '@backstage/core-components';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { styles } from './Projects.styles';
import { TableSecretData } from '../table/TableSecretData';
import { getApigeeCompanyApps } from '../../api/apigee';
import { useIdentityStore } from '../../stores/identityStore';

type Data = {
  company: string;
  companyDisplayName?: string;
  name: string;
  displayName?: string;
  clientKey: string;
  clientSecret: string;
  appStatus: string;
};

const columns: TableColumn[] = [
  {
    title: 'Project / Developer',
    field: 'company',
    highlight: true,
    render: data => (
      <Stack>
        <Typography variant="body2" fontWeight={700}>
          {(data as Data).companyDisplayName ?? (data as Data).company}
        </Typography>

        {(data as Data).companyDisplayName !== (data as Data).company && (
          <Typography variant="body2" fontSize={11}>
            {(data as Data).company}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    title: 'Client Name',
    field: 'name',
    render: data => (
      <Stack>
        <Typography variant="body2" fontWeight={700}>
          {(data as Data).displayName}
        </Typography>

        {(data as Data).displayName !== (data as Data).name && (
          <Typography variant="body2" fontSize={11}>
            {(data as Data).name}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    title: 'Client Key',
    field: 'clientKey',
    render: data => <TableSecretData secret={(data as Data).clientKey} />,
  },

  {
    title: 'Client Secret',
    field: 'clientSecret',
    render: data => <TableSecretData secret={(data as Data).clientSecret} />,
  },
];

export const ProjectsTable = () => {
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const profile = useIdentityStore(state => state.profile);

  const apigeeCompanyApps = useQuery({
    queryKey: ['apigeeCompanyApps'],
    queryFn: () => getApigeeCompanyApps(discovery, identity),
  });

  return (
    <div style={styles.tableContainer}>
      <Table
        options={{ paging: false }}
        data={Object.values(apigeeCompanyApps.data ?? [])
          .flat()
          .map(a => ({
            company: a.company ?? profile?.email ?? '',
            companyDisplayName: a.companyDisplayName,
            name: a.name,
            displayName: a.displayName,
            clientKey: a.consumerKey,
            clientSecret: a.consumerSecret,
          }))}
        isLoading={apigeeCompanyApps.isLoading}
        columns={columns}
        title="My API Projects"
        // @ts-ignore - subtitle can be an element but backstage expects string - https://github.com/backstage/backstage/blob/master/packages/core-components/src/components/Table/Table.tsx#L450
        subtitle={
          <Typography color="textSecondary" variant="body2">
            These are the clients that are associated with your project. These
            clients have been granted access to our UAT and production
            environments, allowing you to integrate them into your applications.
          </Typography>
        }
      />
    </div>
  );
};
