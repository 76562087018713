import React from 'react';
import Stack from '@mui/material/Stack';
import { Helmet } from 'react-helmet';
import { LinkButton } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';
import { useIdentityStore } from '../../stores/identityStore';
import { ApplicationsTable } from './ApplicationsTable';
import { useStyles } from './Applications.styles';

export const ApplicationsPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isGuest) navigate('/');
  }, [isGuest, navigate]);

  return (
    <>
      <Helmet>
        <title>
          Sandbox Clients | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <Stack flex={1} p={4} gap={2}>
          <LinkButton
            className={classes.createButton}
            to="/apps/create"
            color="primary"
            variant="contained"
          >
            Create Sandbox Client
          </LinkButton>

          <ApplicationsTable />
        </Stack>
      </Stack>
    </>
  );
};
