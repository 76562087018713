import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/material/styles';
import { Menu } from '@mui/icons-material';
import { HeaderLogo } from './HeaderLogo';
import { CountryPicker } from './CountryPicker';
import { useRootStore } from '../../stores/rootStore';
import { HeaderAvatar } from './HeaderAvatar';

export const Header = () => {
  const isSidebarExpanded = useRootStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useRootStore(
    state => state.setIsSidebarExpanded,
  );

  const sidebarButtonStyles: SxProps = {
    display: {
      xs: 'none',
      lg: 'block',
    },
    minWidth: 40,
    height: 40,
    border: 'none',
    borderRadius: '100%',
    color: '#1f323e',
  };

  return (
    <Box
      position="sticky"
      top={0}
      zIndex={999}
      bgcolor="white"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight={64}
      width="100%"
      px={2}
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      sx={{ scrollbarGutter: 'stable' }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Button
          data-testid="sidebar-menu-button"
          sx={sidebarButtonStyles}
          onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
        >
          <Menu />
        </Button>

        <Stack direction="row" alignItems="center" gap={1.75}>
          <HeaderLogo />

          <CountryPicker />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2} pr={1}>
        <HeaderAvatar />
      </Stack>
    </Box>
  );
};
