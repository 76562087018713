import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';
import { useIdentityStore } from '../../stores/identityStore';
import { SignupForm } from './SignupForm';

export const SignupPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isGuest) navigate('/');
  }, [isGuest, navigate]);

  return (
    <>
      <Helmet>
        <title>
          Sign up | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <Stack flex={1} p={4} gap={2}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Create an account
          </Typography>
          <SignupForm />
        </Stack>
      </Stack>
    </>
  );
};
