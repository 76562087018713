import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios, { AxiosRequestConfig } from 'axios';

export const getApigeeApps = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const response = await axios.get(`${url}/apigee/apps`, config);

  return response.data as ApigeeApp[];
};

export const createApigeeApp = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  body: ApigeeAppRequest,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  const response = await axios.post(`${url}/apigee/apps`, body, config);

  return response.data as ApigeeApp;
};

export const getApigeeCompanyApps = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { email } = await identityApi.getProfileInfo();
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      email,
    },
  };

  const response = await axios.get(`${url}/apigee/company/apps`, config);

  return response.data as ApigeeCompanyApps;
};

export const getApigeeCompanyDevelopers = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  companyName: string,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      companyName,
    },
  };

  const response = await axios.get(`${url}/apigee/company/developers`, config);

  return response.data as ApigeeDeveloper[];
};

export const getApigeeCompanyInvitees = async (
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi,
    companyName: string,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      companyName,
    },
  };

  const response = await axios.get(`${url}/apigee/company/invitees`, config);

  return response.data as string[];
};

export const removeApigeeCompanyDevelopers = async (
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi,
    body: CompanyInviteRequest,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const { email, companyName } = body;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
    params: {
      developerEmail: email,
      companyName,
    },
  };

  const response = await axios.delete(`${url}/apigee/company/developer`, config);
  return response.status;

};
