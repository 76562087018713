import React, { forwardRef, useCallback, memo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Score } from '@zxcvbn-ts/core';
import { FieldProps } from './Field';
import { PasswordField } from './PasswordField';
import { styles } from './Field.styles';
import { useEstimatePassword } from '../../hooks/useEstimatePassword';

type PasswordStrengthProps = {
  passwordStrength: Score | -1;
};

const PasswordStrength = memo(({ passwordStrength }: PasswordStrengthProps) => {
  const passwordStrengthLabels = [
    'Weak',
    'Fair',
    'Good',
    'Strong',
    'Very Strong',
  ];

  const passwordStrengthColors = [
    '#d9534f',
    '#f0ad4e',
    '#f0ad4e',
    '#33b737',
    '#33b737',
  ];

  const passwordStrengthWidths = ['20%', '40%', '60%', '80%', '100%'];

  return (
    <Stack sx={styles.passwordStrengthContainer}>
      <Stack
        alignItems="center"
        width={passwordStrengthWidths[passwordStrength] ?? 0}
        bgcolor={passwordStrengthColors[passwordStrength]}
        sx={styles.passwordStrength}
      >
        <Typography
          variant="body2"
          color="white"
          fontSize={12}
          fontWeight={400}
        >
          {passwordStrengthLabels[passwordStrength]}
        </Typography>
      </Stack>
    </Stack>
  );
});

export const PasswordWithStrengthField = forwardRef<
  HTMLInputElement,
  FieldProps
>((props, ref) => {
  const estimate = useEstimatePassword();

  const getPasswordStrength = useCallback(
    (password: string) => {
      const { score } = estimate(password);

      if (password.length === 0) {
        return -1;
      }

      if (password.length < 8) {
        return 0;
      }

      return score;
    },
    [estimate],
  );

  const passwordStrength = getPasswordStrength(props.value);

  return (
    <Stack gap={1}>
      <PasswordField ref={ref} {...props} />

      <PasswordStrength passwordStrength={passwordStrength} />
    </Stack>
  );
});
