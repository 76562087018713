import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios, { AxiosRequestConfig } from 'axios';

export const signup = async (
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
  body: CreateCLAUDUserRequest,
) => {
  const url = await discoveryApi.getBaseUrl('dev-portal');
  const { token } = await identityApi.getCredentials();

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${url}/signup`, body, config);

  return response.data as CLAUDUserInfo;
};
