import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNext from '@mui/icons-material/NavigateNext';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import * as yup from 'yup';
import {
  alertApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styles } from './Projects.styles';
import { ProjectsDevelopersTable } from './ProjectsDevelopersTable';
import { Field } from '../field/Field';
import { useIdentityStore } from '../../stores/identityStore';
import { sendEmailCompanyInvite } from '../../api/email';
import {
  getApigeeCompanyDevelopers,
  getApigeeCompanyInvitees,
} from '../../api/apigee';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../stores/rootStore';
import { ProjectsInviteeDevelopersTable } from './ProjectsInviteeDevelopersTable';

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required.')
      .email('Invalid email.')
      .trim()
      .lowercase(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const defaultValues: FormData = {
  email: '',
};

export const ProjectsEmailPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const alert = useApi(alertApiRef);
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apigeeCompanyApps = useQuery<ApigeeCompanyApps>({
    queryKey: ['apigeeCompanyApps'],
  });

  const firstCompany =
    Object.keys(apigeeCompanyApps.data ?? {}).find(company => !!company) ?? '';
  const firstCompanyApp = apigeeCompanyApps.data?.[firstCompany]?.[0];
  const companyName = firstCompanyApp?.company ?? '';

  const apigeeCompanyDevelopers = useQuery({
    queryKey: ['apigeeCompanyDevelopers'],
    queryFn: () => getApigeeCompanyDevelopers(discovery, identity, companyName),
    enabled: !!companyName,
  });

  const apigeeCompanyInvitees = useQuery({
    queryKey: ['apigeeCompanyInvitees'],
    queryFn: () => getApigeeCompanyInvitees(discovery, identity, companyName),
    enabled: !!companyName,
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const sendEmailCompanyInviteMutation = useMutation({
    mutationFn: (body: CompanyInviteRequest) =>
      sendEmailCompanyInvite(discovery, identity, body),
    onSuccess: async (_, { email }) => {
      queryClient.setQueryData(
        ['apigeeCompanyInvitees'],
        (cachedData: string[]) =>
          cachedData ? [...cachedData, email] : [email],
      );

      alert.post({
        message: `Invite message was sent to ${email}`,
        display: 'transient',
        severity: 'success',
      });

      reset(defaultValues);
    },
    onError: (_, { email }) =>
      alert.post({
        message: `Sending invite to ${email} failed`,
        display: 'transient',
        severity: 'error',
      }),
  });

  const onSubmit = async (data: FormData) => {
    const { email } = data;

    if (apigeeCompanyInvitees.data?.includes(email)) {
      setError('email', { message: 'User is already invited.' });
      return;
    }

    if (apigeeCompanyDevelopers.data?.map(d => d.email).includes(email)) {
      setError('email', {
        message: 'User is already part of this API project.',
      });
      return;
    }

    sendEmailCompanyInviteMutation.mutate({ email, companyName });
  };

  useEffect(() => {
    if (isGuest) {
      navigate('/');
      return;
    }

    if (!companyName) {
      navigate('/projects');
    }
  }, [isGuest, companyName, navigate]);

  return (
    <>
      <Helmet>
        <title>
          API Projects | Invite Users | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1} p={4} gap={2}>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
          <Link component={RouterLink} to="/projects">
            <Typography>My API Projects</Typography>
          </Link>
          <Typography color="text.primary">
            Invite users to a project
          </Typography>
        </Breadcrumbs>

        <Typography variant="h4" mb={2.5}>
          Invite Users to {firstCompanyApp?.companyDisplayName ?? companyName}
        </Typography>

        <form style={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Field
                {...field}
                id="company-email-invite-input"
                label="Email"
                error={errors.email?.message}
                touched={touchedFields.email || isSubmitted}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            sx={styles.button}
            disabled={!companyName}
            fullWidth
          >
            {sendEmailCompanyInviteMutation.isPending ? (
              <CircularProgress sx={{ color: 'white' }} size={24} />
            ) : (
              'Invite User'
            )}
          </Button>
        </form>

        <ProjectsDevelopersTable />
        <ProjectsInviteeDevelopersTable />
      </Stack>
    </>
  );
};
