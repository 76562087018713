import {
  createApiFactory,
  analyticsApiRef,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

export const analyticsApiFactory = createApiFactory({
  api: analyticsApiRef,
  deps: { configApi: configApiRef, identityApi: identityApiRef },
  factory: ({ configApi, identityApi }) =>
    GoogleAnalytics4.fromConfig(configApi, {
      identityApi,
      userIdTransform: async userEntityRef =>
        userEntityRef.split('/')?.[1] ?? 'guest',
    }),
});
