import React, { forwardRef, memo, useCallback, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Field, FieldProps } from './Field';

type ShowPasswordButtonProps = {
  showPassword: boolean;
  showPasswordFn: () => void;
};

const ShowPasswordButton = memo(
  ({ showPassword, showPasswordFn }: ShowPasswordButtonProps) => (
    <InputAdornment position="end">
      <IconButton onClick={showPasswordFn} tabIndex={-1}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  ),
);

export const PasswordField = forwardRef<HTMLInputElement, FieldProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const showPasswordFn = useCallback(
      () => setShowPassword(password => !password),
      [],
    );

    return (
      <Field
        ref={ref}
        {...props}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <ShowPasswordButton
            showPassword={showPassword}
            showPasswordFn={showPasswordFn}
          />
        }
      />
    );
  },
);
