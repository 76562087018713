import React from 'react';
import './ThreeDotsLoader.css';

type Props = {
  size?: number;
  color?: string;
};

export const ThreeDotsLoader = ({ size = 12, color = 'black' }: Props) => {
  return (
    <div className="bouncing-loader" style={{ marginBlock: size + 8 }}>
      <div
        style={{
          width: size,
          height: size,
          marginInline: size * 0.4,
          backgroundColor: color,
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          marginInline: size * 0.4,
          backgroundColor: color,
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          marginInline: size * 0.4,
          backgroundColor: color,
        }}
      />
    </div>
  );
};
