import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import { Header } from '../header/Header';
import { Sidebar } from '../sidebar/Sidebar';
import { Footer } from '../footer/Footer';

export const Root = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header />

      <Stack direction="row" flex={1}>
        <Sidebar />

        <Stack flex={1}>
          <Stack flex={1}>{children}</Stack>

          <Footer />
        </Stack>
      </Stack>
    </>
  );
};
