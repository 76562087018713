import axios from 'axios';

export const getAccessToken = async (credentials: CLAccessTokenRequest) => {
  const url =
    'https://api-sbox.corelogic.asia/access/as/token.oauth2?grant_type=client_credentials';
  const tokenResponse = await axios.post(url, credentials, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return tokenResponse.data as CLAccessToken;
};

export const getSuggestions = async (
  country: 'au' | 'nz',
  token: string,
  q: string,
) => {
  const url = `https://api-sbox.corelogic.asia/property/${country}/v2/suggest.json?q=${q}&suggestionTypes=address,street,locality,postcode,state`;
  const suggestResponse = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return suggestResponse.data as SuggestionResponse;
};

export const searchProperties = async (
  country: 'au' | 'nz',
  token: string,
  type: PropertySearchType,
  id: string,
) => {
  const searchResponse = await axios.get(
    `https://api-sbox.corelogic.asia/search/${country}/property/${type.toLowerCase()}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return searchResponse.data as PropertySearchResponse;
};
