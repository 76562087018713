import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ArrowDropDown, Check, Close } from '@mui/icons-material';
import { useRootStore } from '../../../stores/rootStore';

const SeriesRequest = () => (
  <>
    <Typography variant="body2">
      Please see the example request for Market Trends below:
    </Typography>

    <br />

    <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
      {JSON.stringify(
        {
          locationId: '390',
          locationTypeId: 3,
          propertyTypeId: 1,
          fromDate: '2012-02-29',
          toDate: '2013-03-31',
          metricTypeId: 21,
          interval: 1,
        },
        null,
        2,
      )}
    </SyntaxHighlighter>

    <br />

    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <Typography fontWeight={600}>Request Parameters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ whiteSpace: 'nowrap' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                  Field
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                  Required?
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                  Type
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                  Description
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                  Example
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>fromDate</TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Date</TableCell>
                <TableCell>
                  The start date for the data required in the series in format
                  YYYY-MM-DD.
                  <br />
                  This is mandatory if toDate is entered. Defaults to return
                  most recent available data.
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "fromDate": "2012-02-29"
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>toDate</TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Date</TableCell>
                <TableCell>
                  The end date for the data required in the series in format
                  YYYY-MM-DD.
                  <br />
                  <br />
                  Defaults to fromDate + 12 months (or today's date whichever is
                  lesser).
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "toDate": "2013-03-31"
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>interval</TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  Determines the intervals for the data returned and are
                  calculated from the recent date which has metric values in a
                  requested range. In case of metricTypeGroupId the data
                  returned and calculated from any metric type in group having
                  most recent date which has metric value in a requested date
                  range.
                  <br />
                  <br />
                  For example, 1 = monthly, 2 = every two months, 3 = quarterly,
                  6 = half yearly, 12 = yearly, 24= 2 years, etc.
                  <br />
                  <br />
                  Defaults to 1.
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "interval": 1
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>locationId</TableCell>
                <TableCell>Yes</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  A unique identifier for the location in a given location type.
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "locationId": 7926
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  locationTypeId
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  A unique identifier for the location or area type.
                  <ul style={{ lineHeight: 1.5 }}>
                    <li>2 = Country (AU/NZ)</li>
                    <li>3 = Council Area (AU only)</li>
                    <li>4 = Postcode (AU only)</li>
                    <li>7 = State (AU only)</li>
                    <li>8 = Locality (Suburb) (AU/NZ)</li>
                    <li>9 = Territorial Authority (NZ only)</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "locationTypeId": 8
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>chartType</TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  The chartType can override any chartType from sN.cTId and
                  default chartType from metric type group. For example, the
                  default chartType id for metricTypeGroup 1 is Column, setting
                  chartTypeId=5 will override chartType to Pie chart globally.
                  <ul style={{ lineHeight: 1.5 }}>
                    <li>1 = SPLINE</li>
                    <li>2 = LINE</li>
                    <li>3 = COLUMN</li>
                    <li>4 = AREA</li>
                    <li>5 = PIE</li>
                    <li>6 = BAR</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "chartType": 5
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  metricTypeGroupId
                </TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  Returns all metric types for a requested metric type group.
                  <br />
                  <br />
                  Only required if metricTypeId is not passed and cannot be
                  passed in conjunction with metricTypeId.
                  <br />
                  <br />A unique identifier for the metric group; accepted
                  values:
                  <ul style={{ lineHeight: 1.5 }}>
                    <li>1 = Number Sold by Price (12 months)</li>
                    <li>2 = Median Price by Price (12 months)</li>
                    <li>3 = Percentile Sale Price (12 months)</li>
                  </ul>
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "metricTypeGroupId": 1
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  metricTypeId
                </TableCell>
                <TableCell>No</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  A unique identifier for the metric.
                  <br />
                  <br />
                  Only required if metricGroupId is not passed and cannot be
                  passed in conjunction with metricGroupId.
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "metricTypeId": 21
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  propertyTypeId
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Integer</TableCell>
                <TableCell>
                  A unique identifier for the type of property.
                  <br />
                  <br />
                  Australia specific property types:
                  <ul style={{ lineHeight: 1.5 }}>
                    <li>1 = Houses</li>
                    <li>2 = Units</li>
                    <li>3 = Land</li>
                  </ul>
                  <br />
                  New Zealand specific property types:
                  <ul style={{ lineHeight: 1.5 }}>
                    <li>4 = Apartments</li>
                    <li>5 = Flats</li>
                    <li>
                      6 = Residential dwellings (Houses, Apartments, Flats)
                    </li>
                  </ul>
                </TableCell>
                <TableCell>
                  <SyntaxHighlighter language="json" style={a11yDark}>
                    "propertyTypeId": 1
                  </SyntaxHighlighter>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  </>
);

const SoldMetrics = () => (
  <>
    <Typography id="sold-metrics" variant="h6" fontWeight={600} my={0.5}>
      Sold Metrics
    </Typography>

    <Typography variant="body2">
      The Sold metrics provide statistics based off property sales.
      <br />
      <br />
      Sales-based metrics are lagged. Meaning they are current as of the latest
      reporting period minus 3 months.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              AU Data
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              NZ Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>3</TableCell>
            <TableCell>Average GPO Distance</TableCell>
            <TableCell>
              The average distance from the GPO of those properties that
              transacted over the last 12 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>4</TableCell>
            <TableCell> Average Hold Period (years)</TableCell>
            <TableCell>
              The average number of years a property has been held between
              sales. The calculation includes all properties sold over the last
              year and is simply the difference between the most recent date of
              sale and the previous date of sale.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>5</TableCell>
            <TableCell>Average Land Area</TableCell>
            <TableCell>
              The average size of land of those properties that transacted over
              the last 12 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>12</TableCell>
            <TableCell>Annual Change in Median Price (10 years)</TableCell>
            <TableCell>
              The compounding annual change in median sale price based on the
              current period compared with the same period 10 years ago. The
              calculation indicates the average annual growth rate and is
              expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>13</TableCell>
            <TableCell>Annual Change in Median Price (20 years)</TableCell>
            <TableCell>
              The compounding annual change in median sale price based on the
              current period compared with the same period 20 years ago. The
              calculation indicates the average annual growth rate and is
              expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>14</TableCell>
            <TableCell>Annual Change in Median Price (5 years)</TableCell>
            <TableCell>
              The compounding annual change in median sale price based on the
              current period compared with the same period 5 years ago. The
              calculation indicates the average annual growth rate and is
              expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>19</TableCell>
            <TableCell>Change in Median Price (5 years)</TableCell>
            <TableCell>
              The difference between the median sale prices in the current
              period (last 12 months) compared to the same period five years
              ago. The result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>20</TableCell>
            <TableCell>Median Vendor Discount (12 months) </TableCell>
            <TableCell>
              The median difference between the contract price on a property and
              the first advertised price. The figure is expressed as a
              percentage and is an average of all private treaty sales which
              sold for less than their initial asking price during the last 12
              months. Auction listings and listings without an advertised asking
              price are excluded from the calculation.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>21</TableCell>
            <TableCell>Median Sale Price (12 months)</TableCell>
            <TableCell>
              The middle sale price of all transactions recorded during the 12
              month period. Note that sale prices lower than $10,000 and higher
              than $80,000,000 are excluded from the analysis.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>22</TableCell>
            <TableCell>Median Sale Price (350K to 750K)</TableCell>
            <TableCell>
              The middle price of all transactions above $350,000 and under
              $750,000 recorded during the 12 month period.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>23</TableCell>
            <TableCell>Median Sale Price (6 months)</TableCell>
            <TableCell>
              The middle sale price of all transactions recorded during the 6
              month period. Note that sale prices lower than $10,000 and higher
              than $80,000,000 are excluded from the analysis.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>24</TableCell>
            <TableCell>Median Sale Price (over 750K)</TableCell>
            <TableCell>
              The middle price of all transactions above $750,000 recorded
              during the 12 month period.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>25</TableCell>
            <TableCell>Median Sale Price (past 3 months)</TableCell>
            <TableCell>
              The middle sale price of all transactions recorded during the 3
              month period. Note that sale prices lower than $10,000 and higher
              than $80,000,000 are excluded from the analysis.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>26</TableCell>
            <TableCell> Median Sale Price (under 350K)</TableCell>
            <TableCell>
              The middle price of all transactions under $350,000 recorded
              during the 12 month period.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>28</TableCell>
            <TableCell>Median Land Size (12 months)</TableCell>
            <TableCell>
              The median size in square meters of all blocks of vacant land that
              have sold over the past year.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>29</TableCell>
            <TableCell>Median Land Size (3 months)</TableCell>
            <TableCell>
              The median size in square meters of all blocks of vacant land that
              have sold over the past three months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>30</TableCell>
            <TableCell>Median Land Price per SQM (12 months)</TableCell>
            <TableCell>
              The median price per square meter for vacand land sold over the
              past 12 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>31</TableCell>
            <TableCell>Median Land Price per SQM (3 months)</TableCell>
            <TableCell>
              The median price per square meter for vacand land sold over the
              past 3 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>32</TableCell>
            <TableCell>Median Days on Market (12 months)</TableCell>
            <TableCell>
              The median number of days it has taken to sell those properties
              sold by private treaty sale during the last 12 months. The
              calculation excludes auction listings and listings where an asking
              price is not advertised. The days on market calculation uses the
              contract date on the property compared with the first advertised
              date.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>35</TableCell>
            <TableCell> Change in Sales Volumes (monthly)</TableCell>
            <TableCell>
              The percentage change in sales volumes within the specified region
              in the current period compared to the same period 1 month ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>37</TableCell>
            <TableCell>Number Sold (12 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>38</TableCell>
            <TableCell>Number Sold (6 Months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 6 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>39</TableCell>
            <TableCell>Number Sold (monthly)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last month.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>40</TableCell>
            <TableCell>Number Sold (past 3 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 3 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>42</TableCell>
            <TableCell>Lower Quartile (12 months)</TableCell>
            <TableCell>
              The 25th percentile sale price of sales over the past 12 months
              within the geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>43</TableCell>
            <TableCell>Upper Quartile (12 months)</TableCell>
            <TableCell>
              The 75th percentile sale price of sales over the past 12 months
              within the geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>45</TableCell>
            <TableCell>Change in Sales Volumes (3 months)</TableCell>
            <TableCell>
              The percentage change in sales volumes within the specified region
              in the current period compared to the same period 3 months ago.
              The result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>46</TableCell>
            <TableCell>Change in Median Price (quarter)</TableCell>
            <TableCell>
              The difference between the median sale price in the current period
              (last 12 months) compared to the same period 3 months ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>51</TableCell>
            <TableCell>Number Sold between $1m and $2m (12 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for between $1,000,000 and
              $1,999,999.99.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>52</TableCell>
            <TableCell>
              Number Sold between $200k and $400k (12 months)
            </TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for between $200,000 and
              $399,999.99.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>53</TableCell>
            <TableCell>Number Sold above $2m (12 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for more than $2,000,000.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>54</TableCell>
            <TableCell>Number Sold for less than $200k (12 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for less than $200,000.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>55</TableCell>
            <TableCell>
              Number Sold between $400k and $600k (12 months)
            </TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for between $400,000 and
              $599,999.99.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>56</TableCell>
            <TableCell>
              Number Sold between $600k and $800k (12 months)
            </TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for between $600,000 and
              $899,999.99.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>57</TableCell>
            <TableCell>Number Sold between $800k and $1m (12 months)</TableCell>
            <TableCell>
              A count of all transactions within the specified region over the
              last 12 months that have sold for between $800,000 and
              $999,999.99.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>61</TableCell>
            <TableCell>Change in Median Price (3 years)</TableCell>
            <TableCell>
              The difference between the median sale prices in the current
              period (last 12 months) compared to the same period three years
              ago. The result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>66</TableCell>
            <TableCell>Total Value of Sales (12 months)</TableCell>
            <TableCell>
              The total value of all property transactions recorded over the
              past year within the geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>67</TableCell>
            <TableCell>Total Value of Sales (monthly)</TableCell>
            <TableCell>
              The total value of all property transactions recorded over the
              past month within the geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>68</TableCell>
            <TableCell>Stock Turnover (12 months)</TableCell>
            <TableCell>
              The percentage of total dwellings within the specified region that
              have sold over the last 12 months.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>69</TableCell>
            <TableCell>Change in Median Price (12 months)</TableCell>
            <TableCell>
              The difference between the median sale prices in the current
              period (last 12 months) compared to the same period 12 months ago.
              The result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>71</TableCell>
            <TableCell>Change in Sales Volumes (12 months)</TableCell>
            <TableCell>
              The percentage change in sales volumes within the specified region
              in the current period compared to the same period 12 months ago.
              The result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>75</TableCell>
            <TableCell>Median Days on Market (12 months)</TableCell>
            <TableCell>
              The median number of days it has taken to sell all properties
              during the last 12 months. The days on market calculation uses the
              contract date on the property compared with the first advertised
              date.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>82</TableCell>
            <TableCell>Sale price / CV ratio (3 months)</TableCell>
            <TableCell>
              The percentage difference between sale price and CV over the past
              3 months
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>95</TableCell>
            <TableCell>CoreLogic RP Data Mortgage Index (RMI)</TableCell>
            <TableCell>
              CoreLogic systems monitor more than 100,000 mortgage activity
              events ever month across our four main finance industry platforms.
              Monitoring the activity events across this platform provides a
              unique and timely lead indicator to housing finance commitments.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>96</TableCell>
            <TableCell>
              CoreLogic RP Data Mortgage Index (RMI) Change (12 months)
            </TableCell>
            <TableCell>
              CoreLogic systems monitor more than 100,000 mortgage activity
              events ever month across our four main finance industry platforms.
              Monitoring the activity events across this platform provides a
              unique and timely lead indicator to housing finance commitments.
              The 12 month change compares the change in the index value over
              the last 12 months. The data provided is mapped to the suburbs
              corresponding sub-region. When an insufficient number of events
              have been recorded, no 12 month change will be calculated and the
              field will be recorded as 'n.a.'.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>97</TableCell>
            <TableCell>
              Total CoreLogic RP Data Mortgage Index (RMI) Events (per client)
            </TableCell>
            <TableCell>
              The total count over the past four weeks of valuations run across
              CoreLogic's four main finance industry platforms divided by total
              clients. When an insufficient number of events have been recorded,
              'n.a.' will be displayed.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: National, State, Suburb</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>98</TableCell>
            <TableCell>
              Total CoreLogic RP Data Mortgage Index (RMI) Events
            </TableCell>
            <TableCell>
              The total count over the past four weeks of valuations run across
              CoreLogic's four main finance industry platforms. When an
              insufficient number of events have been recorded, 'n.a.' will be
              displayed.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: National, State, Suburb</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const ForSaleMetrics = () => (
  <>
    <Typography id="for-sale-metrics" variant="h6" fontWeight={600} my={0.5}>
      For Sale Metrics
    </Typography>

    <Typography variant="body2">
      The For Sale metrics provide statistics based off property's for sale
      advertisement campaigns.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              AU Data
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              NZ Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>1</TableCell>
            <TableCell>Total Auction Listings (12 months)</TableCell>
            <TableCell>
              The total number of auction listings that have been observed over
              the past year within the geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>34</TableCell>
            <TableCell>Change in Listings (monthly)</TableCell>
            <TableCell>
              The percentage change in listings within the specified region in
              the current period compared to the same period 1 month ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>36</TableCell>
            <TableCell>Total New Listings (12 months)</TableCell>
            <TableCell>
              The total number of new listings recorded across the Geography
              over the past year.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>44</TableCell>
            <TableCell>Change in Listings (3 months)</TableCell>
            <TableCell>
              The percentage change in listings within the specified region in
              the current period compared to the same period 3 months ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>58</TableCell>
            <TableCell>% Stock on Market (12 months)</TableCell>
            <TableCell>
              The percentage of dwellings within the suburb that have been
              listed for sale over the past year.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>64</TableCell>
            <TableCell>Total Listings (12 months)</TableCell>
            <TableCell>
              The total unique number of properties that have been advertised
              for sale and captured by CoreLogic over the past year. To be
              included in the count, the listings have to be matched to an
              actual address.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>65</TableCell>
            <TableCell>Total Listings (monthly)</TableCell>
            <TableCell>
              The total unique number of properties that have been advertised
              for sale and captured by CoreLogic over the past month. To be
              included in the count, the listings have to be matched to an
              actual address.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>70</TableCell>
            <TableCell>Change in Listings (12 months)</TableCell>
            <TableCell>
              The percentage change in listings within the specified region in
              the current period compared to the same period 12 months ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const RentalMetrics = () => (
  <>
    <Typography id="rental-metrics" variant="h6" fontWeight={600} my={0.5}>
      Rental Metrics
    </Typography>

    <Typography variant="body2">
      The Rental metrics provide statistics based off property's rental
      advertisement campaigns.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              AU Data
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              NZ Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>10</TableCell>
            <TableCell>Value based Gross Rental Yield</TableCell>
            <TableCell>
              Is calculated based on the annualised rent (Median Asking Rent x
              52 (weeks)), divided by the median value of those properties
              advertised for rent.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Postcode</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>47</TableCell>
            <TableCell>Change in Rental Rate (12 months)</TableCell>
            <TableCell>
              The difference between the median weekly advertised rental rate in
              the current period compared to the same period 12 months ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>48</TableCell>
            <TableCell>Change in Rental Rate (5 years)</TableCell>
            <TableCell>
              The difference between the median weekly advertised rental rate in
              the current period compared to the same period 5 years ago. The
              result is expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>49</TableCell>
            <TableCell>Median Asking Rent (12 months)</TableCell>
            <TableCell>
              The middle value of advertised weekly rents captured by CoreLogic
              during the 12 month period.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>50</TableCell>
            <TableCell>Rental Rate Observations (12 months)</TableCell>
            <TableCell>
              The number of observations that have been used to calculate the
              'Median Asking Rent' figure.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>77</TableCell>
            <TableCell>Median Asking Rent (quarterly)</TableCell>
            <TableCell>
              The middle value of weekly rents captured by NZ Government during
              the last quarter.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>78</TableCell>
            <TableCell>Rental Rate Observations (quarterly)</TableCell>
            <TableCell>
              The number of observations that have been used to calculate the
              Median Asking Rent figure.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>79</TableCell>
            <TableCell>Change in Rental Rate (12 months)</TableCell>
            <TableCell>
              The difference between the median rental rate in the current
              period compared to the same period 12 months ago. The result is
              expressed as a percentage.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>80</TableCell>
            <TableCell>Value Based Gross Rental Yield</TableCell>
            <TableCell>
              Is calculated based on the annualised rent (Median Asking Rent x
              52 (weeks)), divided by the median value of properties within the
              geography.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const ValuationMetrics = () => (
  <>
    <Typography id="valuation-metrics" variant="h6" fontWeight={600} my={0.5}>
      Valuation Metrics
    </Typography>

    <Typography variant="body2">
      The Valuation metrics provide statistics based off property's valuation
      amounts.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              AU Data
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              NZ Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>8</TableCell>
            <TableCell>Automated Valuation Observations</TableCell>
            <TableCell>
              The total number of Automated Valuations that have been run within
              the geography.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>9</TableCell>
            <TableCell>AVM Value Change (3 months)</TableCell>
            <TableCell>
              The difference between the current median value and the median
              value three months prior.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council, Postcode</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>11</TableCell>
            <TableCell>Median Value (monthly)</TableCell>
            <TableCell>
              The middle value of all properties across the geography based on
              the Automated Valuation Model.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>17</TableCell>
            <TableCell>Median Value Accumulation (monthly)</TableCell>
            <TableCell>
              Growth between the last sale price and the current AVM value
              expressed in dollar terms.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>18</TableCell>
            <TableCell>Median Value Accumulation (%)</TableCell>
            <TableCell>
              Growth between the last sale price and the current AVM value
              expressed as a percentage.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>72</TableCell>
            <TableCell>AVM Value Change (5 years)</TableCell>
            <TableCell>
              The difference between the current median value and the median
              value five years prior.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>73</TableCell>
            <TableCell>Stratified Median Value (monthly)</TableCell>
            <TableCell>
              The stratified median value of all properties across the geography
              based on the Automated Valuation Model.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>83</TableCell>
            <TableCell>AVM Value Change (3 months)</TableCell>
            <TableCell>
              The difference between the current median value and the median
              value three months prior.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>84</TableCell>
            <TableCell>AVM Value Change (12 months)</TableCell>
            <TableCell>
              The difference between the current median value and the median
              value one year prior.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>85</TableCell>
            <TableCell>AVM Value Change (3 years)</TableCell>
            <TableCell>
              The difference between the current median value and the median
              value three years prior.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>86</TableCell>
            <TableCell>Average Value</TableCell>
            <TableCell>
              The average value of all developed residential properties in the
              area based on the latest monthly property value index.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>87</TableCell>
            <TableCell>Annual Property Value Change</TableCell>
            <TableCell>
              The difference between the current average value and the average
              value one year prior.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>88</TableCell>
            <TableCell>Change in Median Value (12 months)</TableCell>
            <TableCell>
              The difference between the median value in the current period
              compared to the same period 12 months ago. The result is expressed
              as a percentage.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council, Postcode</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>89</TableCell>
            <TableCell>Change in Median Value (3 years)</TableCell>
            <TableCell>
              The difference between the median value in the current period
              compared to the same period three years ago. The result is
              expressed as a percentage.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council, Postcode</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>90</TableCell>
            <TableCell>Change in Median Value (5 years)</TableCell>
            <TableCell>
              The difference between the median value in the current period
              compared to the same period five years ago. The result is
              expressed as a percentage.
              <br />
              <br />
              <br />
              <b>Unavailable geographies: Council, Postcode</b>
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

const PropertyMetrics = () => (
  <>
    <Typography id="property-metrics" variant="h6" fontWeight={600} my={0.5}>
      Property Metrics
    </Typography>

    <Typography variant="body2">
      The Property metrics provide statistics based off property's attributes.
    </Typography>

    <br />

    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Display Name
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Definition
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              AU Data
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              NZ Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>63</TableCell>
            <TableCell>Total Dwellings</TableCell>
            <TableCell>
              The number of dwelling located within the defined area.
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>81</TableCell>
            <TableCell>Average Land Area</TableCell>
            <TableCell>The average size of land of all properties.</TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Close color="primary" />
            </TableCell>
            <TableCell sx={{ alignItems: 'center' }}>
              <Check color="success" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export const MetricTypesMarketTrends = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Typography
        id="metric-types-market-trends"
        variant="h5"
        fontWeight={600}
        my={0.5}
      >
        Market Trends
      </Typography>

      <br />

      <Typography variant="body2">
        Market Trends is an essential tool for anyone that wants to truly
        understand the housing market at a micro level across Australia and New
        Zealand, including any business involved in the housing market.
        <br />
        <br />
        Our Market Trends data is available via our{' '}
        <Link
          component={RouterLink}
          to={`/apis/corelogic-apis/market-insights-${country}#/statistics`}
        >
          Statistics
        </Link>{' '}
        service and{' '}
        <Link
          component={RouterLink}
          to={`/apis/corelogic-apis/market-insights-${country}#/charts`}
        >
          Charts
        </Link>{' '}
        service. These services allow you to view time series (or trend) data
        for one or more metrics, locations and property types in order to
        understand the trend and historical performance of the metric.
      </Typography>

      <ul>
        <li>
          <Link href="#sold-metrics">Sold</Link>
        </li>
        <li>
          <Link href="#for-sale-metrics">For Sale</Link>
        </li>
        <li>
          <Link href="#rental-metrics">Rental</Link>
        </li>
        <li>
          <Link href="#valuation-metrics">Valuation</Link>
        </li>
        <li>
          <Link href="#property-metrics">Property</Link>
        </li>
      </ul>

      <br />

      <SeriesRequest />

      <br />
      <br />

      <SoldMetrics />

      <br />
      <br />

      <ForSaleMetrics />

      <br />
      <br />

      <RentalMetrics />

      <br />
      <br />

      <ValuationMetrics />

      <br />
      <br />

      <PropertyMetrics />
    </>
  );
};
