import _ from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Cottage,
  CottageOutlined,
  DescriptionOutlined,
  IntegrationInstructions,
  IntegrationInstructionsOutlined,
  Inventory2,
  Inventory2Outlined,
  Window,
  WindowOutlined,
  ListAltOutlined,
  ListAltTwoTone,
  Description,
  PhoneOutlined,
  Phone,
  HelpOutline,
  Help,
} from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useIdentityStore } from '../../stores/identityStore';
import { useRootStore } from '../../stores/rootStore';
import { getEntities } from '../../api/catalog';
import { SidebarLink } from './SidebarLink';
import { SidebarList } from './SidebarList';
import { ThreeDotsLoader } from '../loader/ThreeDotsLoader';
import { formatNamespace } from '../../utilities/formatNamespace';

export const Sidebar = () => {
  const country = useRootStore(state => state.country);
  const isSidebarExpanded = useRootStore(state => state.isSidebarExpanded);
  const isGuest = useIdentityStore(state => state.isGuest);
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const entities = useQuery({
    queryKey: ['apis'],
    queryFn: () => getEntities(discovery, identity, 'kind=api'),
  });

  const apisByNamespace = _.groupBy(
    entities.data?.filter(e => e.metadata.tags?.includes(country)),
    d => d.metadata.namespace,
  );

  const containerStyle: SxProps = {
    width: {
      xs: 72,
      lg: isSidebarExpanded ? 'auto' : 72,
    },
    minWidth: {
      xs: 72,
      lg: isSidebarExpanded ? 244 : 72,
    },
    maxWidth: 244,
  };

  const topPartStyle: SxProps = {
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarGutter: isSidebarExpanded ? 'stable' : 'auto',
    pb: 6,
  };

  return (
    <Stack
      position="sticky"
      top={64}
      zIndex={999}
      bgcolor="white"
      maxHeight="calc(100vh - 64px)"
      borderRight="1px solid rgba(0, 0, 0, 0.1)"
      sx={containerStyle}
    >
      <Stack flex={1} gap={0.5} py={1.5} sx={topPartStyle}>
        <SidebarLink
          to="/"
          label="Home"
          logo={<CottageOutlined />}
          activeLogo={<Cottage />}
        />
        <SidebarLink
          to="/products"
          label="Products"
          logo={<Inventory2Outlined />}
          activeLogo={<Inventory2 />}
        />

        <SidebarList
          to="/apis"
          label="APIs"
          logo={<IntegrationInstructionsOutlined />}
          activeLogo={<IntegrationInstructions />}
        >
          {entities.isLoading ? (
            <Box pl={3}>
              <ThreeDotsLoader size={6} color="#ef4400" />
            </Box>
          ) : (
            Object.entries(apisByNamespace)
              .filter(([namespace]) => namespace !== 'api-product')
              .sort((a, b) => {
                // bring corelogic-apis to top
                if (a[0] === 'corelogic-apis') return -1;
                if (b[0] === 'corelogic-apis') return 1;
                return a[0].localeCompare(b[0]);
              })
              .map(([namespace, apis]) => {
                const namespaceTitle = formatNamespace(namespace);

                if (namespace === 'default') {
                  return apis
                    .sort((a, b) =>
                      a.metadata.name.localeCompare(b.metadata.name),
                    )
                    .map(a => (
                      <SidebarLink
                        key={a.metadata.name}
                        label={a.metadata.title ?? a.metadata.name}
                        to={`/apis/${a.metadata.name}`}
                      />
                    ));
                }

                return (
                  <SidebarList
                    key={namespace}
                    label={namespaceTitle}
                    to={`/apis/${namespace}`}
                  >
                    {apis
                      .sort((a, b) =>
                        a.metadata.name.localeCompare(b.metadata.name),
                      )
                      .map(api => (
                        <SidebarLink
                          key={api.metadata.name}
                          label={api.metadata.title ?? api.metadata.name}
                          to={`/apis/${namespace}/${api.metadata.name}`}
                        />
                      ))}
                  </SidebarList>
                );
              })
          )}
        </SidebarList>

        <SidebarList
          to="/guides"
          label="Guides"
          logo={<DescriptionOutlined />}
          activeLogo={<Description />}
        >
          <SidebarLink to="/guides/quick-start" label="Quick Start" />
          <SidebarLink
            to="/guides/api-authentication"
            label="API Authentication"
          />
          <SidebarLink
            to="/guides/standards-and-conventions"
            label="Standards & Conventions"
          />
          <SidebarLink
            to="/guides/environment-details"
            label="Environment details"
          />
          <SidebarLink
            to="/guides/sandbox-test-data"
            label="Sandbox Test Data"
          />
          <SidebarLink
            to="/guides/application-silent-login"
            label="Application Silent Login"
          />

          <SidebarList to="/guides/metric-types" label="Statistics & Census">
            <SidebarLink to="/guides/metric-types" label="Metric Types" />
            <SidebarLink
              to="/guides/charts-customisation"
              label="Charts Customisation"
            />
          </SidebarList>

          <SidebarLink to="/guides/mapping-services" label="Mapping Services" />

          <SidebarLink
            to="/guides/psx-implementation"
            label="PSX Implementation"
          />
        </SidebarList>

        {!isGuest && (
          <SidebarLink
            to="/apps"
            label="Sandbox Clients"
            logo={<WindowOutlined />}
            activeLogo={<Window />}
          />
        )}

        {!isGuest && (
          <SidebarLink
            to="/projects"
            label="API Projects"
            logo={<ListAltOutlined />}
            activeLogo={<ListAltTwoTone />}
          />
        )}

        <SidebarLink
          to="/contact-us"
          label="Contact Us"
          logo={<PhoneOutlined />}
          activeLogo={<Phone />}
        />

        <SidebarLink
          to="/faq"
          label="FAQ"
          logo={<HelpOutline />}
          activeLogo={<Help />}
        />
      </Stack>
    </Stack>
  );
};
