import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';

export const FaqPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          FAQ (Frequently Asked Questions) | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            FAQ (Frequently Asked Questions)
          </Typography>

          <Typography fontWeight={500}>
            Find answers to some frequently asked questions.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How is the Developer Portal 2.0 version different from the existing
            portal?
          </Typography>

          <br />

          <Typography variant="body2">
            The 2.0 version is closely integrated with CoreLogic API services
            with new modernized UI interface, and it provides simple steps to
            sign up and access the services in a restricted API environment. The
            API products are documentation coupled with the API releases.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How do I find the list of products available in CoreLogic?
          </Typography>

          <br />

          <Typography variant="body2">
            To see the full list of products available in CoreLogic, go to the
            sidebar and select <b>Products</b>. This will take you to the
            Products page, which lists all available products based on the
            country selected in the Developer Portal.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            What do you mean by a Restricted Sandbox environment?
          </Typography>

          <br />

          <Typography variant="body2">
            This is one of the CoreLogic API environments where APIs provide a
            limited dataset, and is limited by request quota. When the sandbox
            quota is exhausted, the response will be "429 Too Many Requests".
            This is the environment where end users can try out any API or
            Product.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How do I get the unrestricted access to UAT or Production API
            environments?
          </Typography>

          <br />

          <Typography variant="body2">
            Once you're satisfied with the products, you can reach out to sales
            representatives with the details of your Sandbox Client to provide
            access to the specific product in the production environment. During
            the authorization process, the CoreLogic pre-sales team will collect
            additional details such as your company name, Sandbox Client ID,
            product or API of your interest, and your contact information.
            <br />
            <br />
            The authorization process will move the Sandbox Client into your API
            Projects. You will be able to view the API Project details from the
            sidebar under <b>API Projects</b>.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How can I regenerate my Client Secret?
          </Typography>

          <br />

          <Typography variant="body2">
            Currently, the Developer Portal 2.0 does not have the option to
            refresh or regenerate your own client secret, but we plan on making
            this feature available in the future. For now, you can request it by
            sending an email to{' '}
            <Link href="mailto:api.support@corelogic.com">
              api.support@corelogic.com
            </Link>
            , they will be able to generate the credentials on demand.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            I am having problems inviting users to join my portal account
          </Typography>

          <br />

          <Typography variant="body2">
            Please{' '}
            <Link href="mailto:api.support@corelogic.com">
              email our friendly support staff
            </Link>{' '}
            with details of your issue. Please include the invitation email if
            it has been sent.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            I've just signed up for my free evaluation access, what should I
            expect as next steps?
          </Typography>

          <br />

          <Typography variant="body2">
            An integration representative will contact you shortly to discuss
            pricing and provisioning a full-access, paid service plan.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How much does it cost for a full-access, paid service plan?
          </Typography>

          <br />

          <Typography variant="body2">
            Pricing for a paid service plan depends on your use case and the
            scale of your implementation, among other factors. Speak with your
            CoreLogic representative to get indicative pricing and validate that
            we can support your use case early in your journey.
            <br />
            <br />
            Our onboarding process involves a number of steps, and our Sandbox
            is designed to help you poke-around and have a play early on,
            however, we do not warrant that we will nor can support all use
            cases.
            <br />
            <br />
            Clients typically move from using our Sandbox environment to explore
            what possibilities might exist, through to a larger evaluation that
            includes technical feasibility and support.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How do I retrieve real property records from within the Sandbox
            environment?
          </Typography>

          <br />

          <Typography variant="body2">
            Please refer to the{' '}
            <Link component={RouterLink} to="/guides/sandbox-test-data">
              Sandbox Test Data
            </Link>{' '}
            page.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Is NZ data available within the Sandbox environment?
          </Typography>

          <br />

          <Typography variant="body2">
            It is! As per Australia, we also have a limited dataset sample for
            NZ properties in{' '}
            <Link component={RouterLink} to="/guides/sandbox-test-data">
              Sandbox
            </Link>
            . To test requests with NZ properties, change the Region to NZ in
            the dropdown available on top of the page.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            When should I display CoreLogic Disclaimers and Copyright notices?
          </Typography>

          <br />

          <Typography variant="body2">
            CoreLogic will make recommendations as to where the use of our
            disclaimers and other content is required. This will occur as part
            of the service we provide when you migrate to a paid plan before
            your implementation goes live.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Why am I not able to retrieve legal content?
          </Typography>

          <br />

          <Typography variant="body2">
            Legal content is not available in the Sandbox environment. Once
            promoted to UAT you will be able to trial it.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            How can I understand what the different match types and codes mean
            in the Address Matcher response?
          </Typography>

          <br />

          <Typography variant="body2">
            All of the details for the match types and match codes can be found
            on the{' '}
            <Link
              component={RouterLink}
              to={`/apis/corelogic-apis/address-match-${country}`}
            >
              Address Matcher API
            </Link>
            .
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Why do I get the response "402: No data found for your search." when
            I search for a specific property address via the Suggest service?
          </Typography>

          <br />

          <Typography variant="body2">
            The Sandbox environment data set is significantly reduced and does
            not reflect the full production environment data set. Please refer
            to the Sandbox page for details of the limited data set within this
            environment. Please ensure you are using the correct address of the
            property as the Suggest service utilises "starts with" logic to
            match addresses (or other location types) beginning with the exact
            input string (no fuzzy matching).
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Why am I getting a 401 Unauthorized error with the message?
          </Typography>

          <br />

          <Typography variant="body2">
            <ul>
              <li>
                'Restricted environment access denied.' - your free evaluation
                access is restricted to our Sandbox environment. You will be
                granted full access to all environments when a paid service plan
                is provisioned.
              </li>
              <br />
              <li>
                'Invalid token provided.' - check that you have generated the
                token using the same environment as the request you are making
                e.g. token generated in UAT can only be used to make subsequent
                calls in UAT.
              </li>
            </ul>
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Are the CoreLogic APIs backwards compatible?
          </Typography>

          <br />

          <Typography variant="body2">
            As we continue to develop and improve CoreLogic APIs, service method
            request and response objects will evolve over time. As such, we
            strive to ensure that new functionality is backwards compatible.
            However, if a change to existing input or output objects is required
            (i.e. breaking changes), we will introduce a new version of the
            service, allowing the integration of older versions to still be
            supported for a reasonable length of time before removal. We will
            attempt to notify you in advance of any updates to our CoreLogic
            APIs prior to release.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Why are my saved property IDs no longer valid or not returning data
            as they used to?
          </Typography>

          <br />

          <Typography variant="body2">
            We continually work to maintain and improve the quality of our data
            and ensure it is always current. Property IDs previously returned to
            customers and integrators may no longer be available, in the
            following circumstances:
            <ul>
              <li>Property has been deduplicated</li>
              <li>Property has been made obsolete</li>
              <li>Property was split or merged</li>
            </ul>
          </Typography>

          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Is NAPLAN data available via CoreLogic APIs?
          </Typography>

          <br />

          <Typography variant="body2">
            NAPLAN data is not available via CoreLogic APIs. We recommend
            accessing more information about the school via the detail returned
            including the school's website.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Where does CoreLogic's School and School Catchment data come from?
          </Typography>

          <br />

          <Typography variant="body2">
            Our data is an aggregation of multiple sources, combined to improve
            quality and coverage.
          </Typography>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            What other Places of Interest are available via the Places API?
          </Typography>

          <br />

          <Typography variant="body2">
            Currently, only Schools and School Catchments are available.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
