import '@backstage/cli/asset-types';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = document.getElementById('root')!;

root.style.display = 'flex';
root.style.flexDirection = 'column';
root.style.minHeight = '100vh';

ReactDOM.createRoot(root).render(<App />);
