import React, { useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from 'react-google-recaptcha';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  alertApiRef,
  configApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { isAxiosError } from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useStyles } from './Signup.styles';
import { Field } from '../field/Field';
import { PasswordField } from '../field/PasswordField';
import { PasswordWithStrengthField } from '../field/PasswordWithStrengthField';
import { signup } from '../../api/signup';
import { useEstimatePassword } from '../../hooks/useEstimatePassword';

export const SignupForm = () => {
  const [agreed, setAgreed] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const classes = useStyles();
  const config = useApi(configApiRef);
  const alert = useApi(alertApiRef);
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const estimate = useEstimatePassword();

  const signupMutation = useMutation({
    mutationFn: (body: CreateCLAUDUserRequest) =>
      signup(discovery, identity, body),
  });

  const schema = useMemo(
    () =>
      yup
        .object({
          firstName: yup
            .string()
            .required('First name is required.')
            .max(100, 'First name cannot be longer than 100 characters.')
            .trim(),
          lastName: yup
            .string()
            .required('Last name is required.')
            .max(100, 'Last name cannot be longer than 100 characters.')
            .trim(),
          emailAddress: yup
            .string()
            .required('Email is required.')
            .email('Invalid email.')
            .trim()
            .lowercase(),
          username: yup
            .string()
            .required('Username is required.')
            .min(3, 'Username must be atleast 3 characters.')
            .max(100, 'Username cannot be longer than 100 characters.')
            .matches(/^[-0-9a-zA-Z._]+$/, 'Invalid username.')
            .trim(),
          password: yup
            .string()
            .required('Password is required.')
            .min(8, 'Password must be atleast 8 characters.')
            .max(128, 'Password cannot be longer than 128 characters.')
            .matches(/([ -~]{8,128})/)
            .test({
              message: ({ value }) => {
                const warning = estimate(value).feedback.warning;
                const message = 'Password is too weak.';

                if (warning) {
                  return `${message} (${warning})`;
                }

                return message;
              },
              test: value => estimate(value).score > 1,
            })
            .trim(),
          confirmPassword: yup
            .string()
            .required('Confirm your password.')
            .oneOf([yup.ref('password')], 'Passwords must match.')
            .trim(),
          phoneNumber: yup
            .string()
            .required('Phone number is required.')
            .test({
              message: 'Invalid phone number.',
              test: value =>
                /^(\+?\d{10,12})$/g.test(value.replaceAll(/\D/g, '')),
            }),
        })
        .required(),
    [estimate],
  );

  type FormData = yup.InferType<typeof schema>;

  const defaultValues: FormData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    username: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
  };

  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    const body = {
      ...data,
      recaptchaToken,
    };

    signupMutation.mutate(body, {
      onSuccess: () => {
        alert.post({
          message: 'Successfully created user',
          severity: 'success',
          display: 'transient',
        });

        reset(defaultValues);
        setAgreed(false);

        identity.signOut();
      },
      onError: e => {
        if (!isAxiosError(e)) {
          alert.post({
            message: 'Something went wrong when signing up',
            severity: 'error',
            display: 'transient',
          });

          return;
        }

        const claudError = e.response?.data as CreateCLAUDUserError;
        const USERNAME_ALREADY_EXISTS = 'username.already.exists';
        const EMAIL_ALREADY_EXISTS = 'user.email.already.exists';

        switch (claudError.errors?.[0].key) {
          case EMAIL_ALREADY_EXISTS:
            setError('emailAddress', {
              message: 'Email already signed up. Please try another email.',
            });
            break;

          case USERNAME_ALREADY_EXISTS:
            setError('username', {
              message:
                'Username already signed up. Please try another username.',
            });
            break;

          default:
            alert.post({
              message: 'Something went wrong when signing up',
              severity: 'error',
              display: 'transient',
            });
        }
      },
      onSettled: () => {
        recaptchaRef.current?.reset();
        setRecaptchaToken(null);
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <Field
            {...field}
            label="First name"
            error={errors.firstName?.message}
            touched={touchedFields.firstName || isSubmitted}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <Field
            {...field}
            label="Last name"
            placeholder="Enter your last name"
            error={errors.lastName?.message}
            touched={touchedFields.lastName || isSubmitted}
          />
        )}
      />

      <Controller
        name="emailAddress"
        control={control}
        render={({ field }) => (
          <Field
            {...field}
            label="Email Address"
            placeholder="Enter your email address"
            error={errors.emailAddress?.message}
            touched={touchedFields.emailAddress || isSubmitted}
          />
        )}
      />

      <Controller
        name="username"
        control={control}
        render={({ field }) => (
          <Field
            {...field}
            label="Username"
            placeholder="Enter your username"
            error={errors.username?.message}
            touched={touchedFields.username || isSubmitted}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <PasswordWithStrengthField
            {...field}
            label="Password"
            placeholder="Enter your password"
            error={errors.password?.message}
            touched={touchedFields.password || isSubmitted}
          />
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <PasswordField
            {...field}
            label="Confirm Password"
            placeholder="Confirm your password"
            error={errors.confirmPassword?.message}
            touched={touchedFields.confirmPassword || isSubmitted}
          />
        )}
      />

      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <Field
            {...field}
            label="Phone Number"
            placeholder="Enter your phone number"
            error={errors.phoneNumber?.message}
            touched={touchedFields.phoneNumber || isSubmitted}
          />
        )}
      />

      {config.getOptionalBoolean('recaptcha.enabled') && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.getOptionalString('recaptcha.siteKey') ?? ''}
          onChange={value => setRecaptchaToken(value)}
        />
      )}

      <div className={classes.termsAndConditionsContainer}>
        <Checkbox checked={agreed} size='small' onChange={() => setAgreed(!agreed)} />

        <Typography variant="body2" fontSize={14}>
          I have read and agreed to the{' '}
          <Link href="/terms-and-conditions" target="_blank">
            terms and conditions*
          </Link>
        </Typography>
      </div>

      <Button
        type="submit"
        variant="contained"
        className={classes.button}
        fullWidth
        disabled={
          !agreed ||
          (config.getOptionalBoolean('recaptcha.enabled') && !recaptchaToken)
        }
      >
        Sign Up
        {signupMutation.isPending && (
          <CircularProgress sx={{ color: 'white', marginLeft: 2 }} size={16} />
        )}
      </Button>
    </form>
  );
};
