import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';

export const OpenApiGuestWarning = () => {
  const logout = useLogout();

  return (
    <Alert severity="warning">
      <AlertTitle>Warning!</AlertTitle>
      You need to{' '}
      <Link
        component="button"
        sx={{ verticalAlign: 'baseline' }}
        onClick={logout}
      >
        log in
      </Link>{' '}
      to try this API.
      <br />
      <br />
      Don't have an account?{' '}
      <Link component={RouterLink} to="/signup">
        Sign up here.
      </Link>
    </Alert>
  );
};
