import React from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

type EntityData = {
  entity: Entity;
  name: string;
  description?: string;
  namespace?: string;
};

type Props = {
  title: string;
  subtitle: string | React.ReactElement;
  data: EntityData[];
  isLoading: boolean;
};
export const ApiTable = ({ title, subtitle, data, isLoading }: Props) => {
  const columns: TableColumn[] = [
    {
      title: 'Name',
      field: 'name',
      highlight: true,
      render: (d: any) => (
        <Link
          component={RouterLink}
          to={`/apis/${d.entity.metadata.namespace}/${d.entity.metadata.name}`}
        >
          {d.name}
        </Link>
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Group',
      field: 'namespace',
    },
  ];

  return (
    <Table
      title={
        <Typography variant="h4" color="primary.main" fontWeight={600}>
          {title}
        </Typography>
      }
      // @ts-ignore
      // subtitle can be an element but backstage typed it as string - https://github.com/backstage/backstage/blob/master/packages/core-components/src/components/Table/Table.tsx#L450
      subtitle={
        <Typography color="textSecondary" variant="body2" maxWidth={1400}>
          {subtitle}
        </Typography>
      }
      options={{ paging: false }}
      data={data}
      isLoading={isLoading}
      columns={columns}
    />
  );
};
