import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  defaultTypography,
  genPageTheme,
  pageTheme,
} from '@backstage/theme';

export const colors = {
  primary: '#ef4400',
  black: '#1f323e',
} as const;

export const corelogicTheme = createUnifiedTheme({
  fontFamily: 'Poppins',
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& th': { border: '1px solid rgba(0, 0, 0, 0.07)' },
          '& td': { border: '1px solid rgba(0, 0, 0, 0.07)' },
        },
      },
    },
    BackstagePage: {
      styleOverrides: {
        root: () => ({
          height: '100%',
        }),
      },
    },
  },
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.primary,
      },
      text: {
        primary: colors.black,
      },
    },
    typography: {
      ...defaultTypography,
      htmlFontSize: 16,
      fontFamily: 'Poppins',
    },
  }),
  pageTheme: {
    // Overwrite all default backstage page themes' color
    ...Object.keys(pageTheme).reduce((acc, key) => {
      acc[key] = genPageTheme({
        colors: [colors.black],
        shape: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect width="200" height="100" fill="rgb(31, 50, 62)" /></svg>');`,
      });

      return acc;
    }, {} as typeof pageTheme),
  },
});
