import { OAuth2 } from '@backstage/core-app-api';
import {
  ApiRef,
  ProfileInfoApi,
  BackstageIdentityApi,
  SessionApi,
  createApiRef,
  createApiFactory,
  discoveryApiRef,
  oauthRequestApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';

export const oidcAuthApiRef: ApiRef<ProfileInfoApi & BackstageIdentityApi & SessionApi> = createApiRef({
  id: 'auth.oidc-auth-provider',
});

export const oidcAuthApiFactory = createApiFactory({
  api: oidcAuthApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      configApi,
      discoveryApi,
      oauthRequestApi,
      provider: {
        id: 'oidc-auth-provider',
        title: 'My custom oidc auth provider',
        icon: () => null,
      },
      environment: configApi.getOptionalString('auth.environment'),
      defaultScopes: ['openid', 'profile', 'email'],
      popupOptions: {
        size: {
          width: 1000,
          height: 1000,
        },
      },
    }),
});
