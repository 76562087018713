import React from 'react';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import {
  alertApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Progress } from '@backstage/core-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Field } from '../field/Field';
import { EmailVerificationRequest } from '../profile/EmailVerificationRequest';
import { getEmailIsVerified } from '../../api/email';
import { createApigeeApp } from '../../api/apigee';
import { AxiosError } from 'axios';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: 512,
    marginTop: 16,
  },
  button: {
    background: 'rgb(235,71,35)',
    color: 'white',
    padding: 12,
    borderRadius: 8,
  },
  formErrorText: {
    color: 'red',
    fontSize: 12,
    marginLeft: 16,
  },
});

export const CreateApplicationForm = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const identity = useApi(identityApiRef);
  const alert = useApi(alertApiRef);
  const discovery = useApi(discoveryApiRef);
  const navigate = useNavigate();

  const userEmailVerification = useQuery({
    queryKey: ['userEmailVerification'],
    queryFn: () => getEmailIsVerified(discovery, identity),
  });

  const createApigeeAppMutation = useMutation({
    mutationFn: (app: ApigeeAppRequest) =>
      createApigeeApp(discovery, identity, app),
  });

  const schema = yup
    .object({
      applicationName: yup
        .string()
        .required('Client name is required.')
        .min(12, 'Client name should be atleast 12 characters.')
        .max(255, 'Client name cannot be longer than 255 characters.')
        .test({
          message: 'Application name has invalid symbols.',
          test: value => /^[\w-](?!.* {2})[ \w-]+$/.test(value),
        })
        .test({
          message: "Client name should not have a 'test' keyword in it.",
          test: value => !value.toLowerCase().includes('test'),
        })
        .trim(),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const defaultValues: FormData = {
    applicationName: '',
  };

  const {
    control,
    reset,
    handleSubmit,
    setError,
    watch,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    const { applicationName } = data;
    const profile = await identity.getProfileInfo();

    const body = {
      applicationName,
      contactName: profile.displayName ?? '',
      contactEmail: profile.email ?? '',
    };

    createApigeeAppMutation.mutate(body, {
      onSuccess: result => {
        reset(defaultValues);

        alert.post({
          message: 'Successfully created the sandbox client',
          severity: 'success',
          display: 'transient',
        });

        queryClient.setQueryData(['apigeeApps'], (oldData: ApigeeApp[]) =>
          oldData ? [result, ...oldData] : [result],
        );

        navigate('/apps');
      },
      onError: error => {
        if (error instanceof AxiosError) {
          const message =
            error.response?.data.msg ?? error.response?.data.error;

          setError('applicationName', { message });

          alert.post({
            message,
            severity: 'error',
            display: 'transient',
          });

          return;
        }

        alert.post({
          message: error.message,
          severity: 'error',
          display: 'transient',
        });
      },
    });
  };

  if (userEmailVerification.isLoading) {
    return <Progress />;
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      {!userEmailVerification.data?.isEmailValidated && (
        <Alert severity="error">
          You need to verify your email first before you can create a sandbox
          client. <EmailVerificationRequest />
        </Alert>
      )}

      <Stack gap={1}>
        <Controller
          name="applicationName"
          control={control}
          render={({ field }) => (
            <Field
              {...field}
              id="client-name-input"
              label="Client Display Name"
              error={errors.applicationName?.message}
              touched={touchedFields.applicationName || isSubmitted}
            />
          )}
        />

        <Typography variant="body2" fontWeight={500} fontSize={12}>
          Name: {watch('applicationName').toLowerCase().trim().replaceAll(' ', '-')}
        </Typography>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        className={classes.button}
        disabled={
          createApigeeAppMutation.isPending ||
          userEmailVerification.isLoading ||
          !userEmailVerification.data?.isEmailValidated
        }
        fullWidth
      >
        Create Sandbox Client
        {createApigeeAppMutation.isPending && (
          <CircularProgress sx={{ color: 'white', marginLeft: 2 }} size={16} />
        )}
      </Button>
    </form>
  );
};
