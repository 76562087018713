import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { ProductOverview } from '../products/ProductOverview';
import { ApiOverview } from '../api/ApiOverview';
import { ErrorPage } from '../error/ErrorPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')}>
      <ProductOverview />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>
      <ApiOverview />
    </EntitySwitch.Case>
    {/* We don't support other entity kinds */}
    <EntitySwitch.Case>
      <ErrorPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
