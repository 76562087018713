import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    createButton: {
      alignSelf: 'flex-end',
    },
    tableContainer: {
      width: '100%',
    }
  });
  