import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import {
  useAsyncEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { apiDocsConfigRef } from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import {
  CodeSnippet,
  Progress,
  WarningPanel,
} from '@backstage/core-components';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../stores/rootStore';
import { useNavigate } from 'react-router-dom';

export const ProductOverview = () => {
  const navigate = useNavigate();

  const country = useRootStore(state => state.country);
  const countryOnRender = useRef(country);

  const apiDocs = useApi(apiDocsConfigRef);
  const { entity, loading } = useAsyncEntity();
  const {
    entities,
    loading: relatedEntitiesLoading,
    error,
  } = useRelatedEntities(entity!, {
    type: 'providesApi',
  });

  if (countryOnRender.current !== country) {
    countryOnRender.current = country;
    navigate('/products');
  }

  if (loading || relatedEntitiesLoading) {
    return <Progress />;
  }

  if (error || !entity || !entities || entities.length === 0) {
    return (
      <WarningPanel
        severity="error"
        title="Could not load APIs"
        message={<CodeSnippet text={`${error}`} language="text" />}
      />
    );
  }

  const api = entities.at(0) as ApiEntity;
  const apiDocsDefinitionWidget = apiDocs.getApiDefinitionWidget(api);

  if (!apiDocsDefinitionWidget) {
    return (
      <WarningPanel severity="error" title="Could not load API definition" />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Products | {entity.metadata.title} | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>
      <Stack p={4}>
        {apiDocsDefinitionWidget.component(api.spec.definition)}
      </Stack>
    </>
  );
};
