import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import { GettingStartedHeader } from './GettingStartedHeader';
import { Timeline } from '../timeline/Timeline';
import { useRootStore } from '../../stores/rootStore';
import { useIdentityStore } from '../../stores/identityStore';

export const GettingStartedPage = () => {
  const country = useRootStore(state => state.country);
  const isGuest = useIdentityStore(state => state.isGuest);

  return (
    <>
      <Helmet>
        <title>
          Get Started | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <GettingStartedHeader />

        <Container maxWidth="xl" sx={{ flex: 1, py: 8 }}>
          <Stack direction="row">
          <Timeline
              circleText="1"
              title="Sign up"
              subtitle="Create and activate your account."
              description="Start by creating an account. Pick a username and password, fill in the requested details and complete your account sign-up. Before you can sign in, you must activate your account with the code sent to your email address."
              link={isGuest ? '/signup' : undefined}
              linkText={isGuest ? 'Create an account' : undefined}
            />

            <Timeline
              circleText="2"
              title="Explore"
              subtitle="Discover the APIs to help design your solution."
              description="Create a API Client from the My API Clients page to get your API access credentials. Then use these credentials in a sandbox to explore our APIs and the information it returns. Get help from our interactive API documentation and get up to speed by using our pre-built sample API collections. Read some use cases to understand the possibilities of using our API. Be aware, sandbox credentials will expire after 6 months unused."
              link="/products"
              linkText="API Docs"
            />

            <Timeline
              circleText="3"
              title="Evaluate"
              subtitle="Get in touch with us."
              description="Let us know what you want to achieve. Our experts will help you refine your requirements, firm up commercial terms, and prepare an evaluation agreement. After you sign the evaluation agreement, you will have full access to our test environment with real data."
              link="/contact-us"
              linkText="Contact us"
            />
            <Timeline
              circleText="4"
              title="Build"
              subtitle="Use real data to build your solution."
              description="Access our test environment and build your application with real data. This means you can build and test your integrations and know that they will behave the same on the production servers as they do in our test environment. We work with you along the way to get a solution design in place. The solution design documents the entire solution, the data you use and how you use it."
            />
            <Timeline
              circleText="5"
              title="Go Live"
              subtitle="Take your solution to the market."
              description="When you are ready to go live, we help you verify that your solution is built as agreed in the solution design and prepare a production contract. After you sign the contract, you get access to our live production environment with the best property-related data and analytics. Once satisfied, go live with your robust, well-tested solution."
            />
          </Stack>
        </Container>
      </Stack>
    </>
  );
};
