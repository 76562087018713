import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { ArrowDropDown } from '@mui/icons-material';
import { useRootStore } from '../../stores/rootStore';

export const CountryPicker = () => {
  const country = useRootStore(state => state.country);
  const setCountry = useRootStore(state => state.setCountry);
  const [menu, setMenu] = useState<HTMLElement>();

  const alert = useApi(alertApiRef);

  const menuOnClick = (value: 'au' | 'nz') => {
    setCountry(value);
    setMenu(undefined);

    alert.post({
      message: `Your preferred region has been changed to ${
        value === 'au' ? 'Australia' : 'New Zealand'
      }.`,
      display: 'transient',
      severity: 'success',
    });
  };

  return (
    <>
      <Stack
        data-testid="country-picker-menu-button"
        direction="row"
        pl={1.25}
        pr={0.25}
        py={0.25}
        border="1px solid"
        borderColor="rgba(239, 68, 0, 0.5)"
        borderRadius={1}
        onClick={e => setMenu(e.currentTarget)}
        sx={{
          cursor: 'pointer',
          transition: 'all 150ms',
          ':hover': {
            bgcolor: 'rgba(239, 68, 0, 0.025)',
            borderColor: 'rgba(239, 68, 0, 1)',
          },
        }}
      >
        <Typography fontWeight={500} color="primary.main">
          {country.toUpperCase()}
        </Typography>

        <ArrowDropDown sx={{ color: 'primary.main' }} />
      </Stack>

      <Menu
        open={!!menu}
        anchorEl={menu}
        onClose={() => setMenu(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => menuOnClick('au')}>Australia</MenuItem>
        <MenuItem onClick={() => menuOnClick('nz')}>New Zealand</MenuItem>
      </Menu>
    </>
  );
};
