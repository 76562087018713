import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
    width: 512,
    marginTop: 16,
  },
  button: {
    background: 'rgb(235,71,35)',
    color: 'white',
    padding: 12,
    borderRadius: 4,
  },
  termsAndConditionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  radioContainer: { gap: 8, marginBottom: 8 },
  radioGroup: { gap: 16, marginLeft: 12 },
  radio: { gap: 8 },
});
