import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { uniqueId } from 'lodash';
import { getEntities } from '../../api/catalog';
import { useRootStore } from '../../stores/rootStore';
import { ProductCard } from './ProductCard';

export const ProductsContent = () => {
  const country = useRootStore(state => state.country);
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const products = useQuery({
    queryKey: ['products'],
    queryFn: () => getEntities(discovery, identity, 'kind=component'),
  });

  const apis = useQuery({
    queryKey: ['apis'],
    queryFn: () => getEntities(discovery, identity, 'kind=api'),
  });

  const apisAsProducts = [
    'commercial-apis',
    'construction-apis',
    'customer-engagement-solutions',
    'insurance-apis',
    'property-bureau-apis',
  ];

  const productOrder = [
    'whats-your-property-worth',
    'equity-calculation',
    'renovation-calculator',
    'rp-inside',
    'form-pre-population',
    'property-services-exchange',
  ];

  if (products.isLoading || apis.isLoading) {
    return (
      <Box
        display="grid"
        sx={{
          gap: 4,
          gridTemplateColumns: {
            xs: 'repeat(2, minmax(0, 1fr))',
            lg: 'repeat(3, minmax(0, 1fr))',
            xl: 'repeat(4, minmax(0, 1fr))',
          },
        }}
      >
        {Array(8)
          .fill(1)
          .map(() => (
            <Skeleton
              key={uniqueId()}
              variant="rounded"
              animation="wave"
              height={240}
            />
          ))}
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      sx={{
        gap: 4,
        gridTemplateColumns: {
          xs: 'repeat(2, minmax(0, 1fr))',
          lg: 'repeat(3, minmax(0, 1fr))',
          xl: 'repeat(4, minmax(0, 1fr))',
        },
      }}
    >
      {products.data
        ?.filter(e => e.metadata.tags?.includes(country))
        .sort((a, b) => {
          const indexA = productOrder.findIndex(p =>
            a.metadata.name.includes(p),
          );
          const indexB = productOrder.findIndex(p =>
            b.metadata.name.includes(p),
          );

          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          }

          if (indexA !== -1) {
            return -1;
          }

          if (indexB !== -1) {
            return 1;
          }

          return a.metadata.name.localeCompare(b.metadata.name);
        })
        .map(e => (
          <ProductCard key={JSON.stringify(e)} entity={e} />
        ))}

      {apis.data
        ?.filter(
          e =>
            e.metadata.tags?.includes(country) &&
            apisAsProducts.some(api => e.metadata.name.includes(api)),
        )
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name))
        .map(e => (
          <ProductCard key={JSON.stringify(e)} entity={e} apiAsProduct />
        ))}
    </Box>
  );
};
