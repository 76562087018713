import React from 'react';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { createApiFactory } from '@backstage/core-plugin-api';
import { OpenApiWidget } from '../components/open-api/OpenApiWidget';

export const apiDocsApiFactory = createApiFactory({
  api: apiDocsConfigRef,
  deps: {},
  factory: () => {
    const definitionWidgets = defaultDefinitionWidgets().map(w => {
      if (w.type === 'openapi') {
        w.component = definition =>
          React.createElement(OpenApiWidget, { definition });
      }

      return w;
    });

    return {
      getApiDefinitionWidget: apiEntity =>
        definitionWidgets.find(d => d.type === apiEntity.spec.type),
    };
  },
});
