import { SxProps } from '@mui/material/styles';
import HeroImage from '../../assets/images/hero-image.png';

export const styles = {
  loginContainer: {
    width: { xs: '50%', lg: '40%', xl: '33.33%' },
    px: { xs: 6, xl: 10 },
    pb: 16,
  },
  heroImage: {
    backgroundImage: `url(${HeroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
} satisfies Record<string, SxProps>;
