import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getApigeeApps } from '../../api/apigee';

type OpenApiApplicationsSelectProps = {
  setCredentials: React.Dispatch<React.SetStateAction<CLAccessTokenRequest>>;
};

export const OpenApiApplicationsSelect = ({
  setCredentials,
}: OpenApiApplicationsSelectProps) => {
  const [selectedApigeeApp, setSelectedApigeeApp] = useState<string>('');

  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apigeeApps = useQuery({
    queryKey: ['apigeeApps'],
    queryFn: () => getApigeeApps(discovery, identity),
  });

  return (
    <Stack gap={4}>
      {apigeeApps.data?.length === 0 && (
        <Alert severity="warning">
          <AlertTitle>Warning:</AlertTitle>
          You have no sandbox clients yet. Create one{' '}
          <Link component={RouterLink} to="/apps/create">
            here
          </Link>
          .
        </Alert>
      )}

      <Stack gap={2} px={6}>
        <Typography fontWeight={600}>
          Select a sandbox client to get credentials:
        </Typography>

        <FormControl
          sx={{ minWidth: 250, alignSelf: 'flex-start' }}
          size="small"
        >
          <InputLabel id="api-client-label">
            {apigeeApps.isLoading ? 'Loading...' : 'Sandbox Clients'}
          </InputLabel>

          <Select
            data-testid="api-clients-select"
            labelId="api-client-label"
            label="Sandbox Clients"
            value={selectedApigeeApp}
            disabled={apigeeApps.isLoading || apigeeApps.data?.length === 0}
            onChange={e => {
              // remove persisted swagger authorization
              localStorage.removeItem('authorized');

              setSelectedApigeeApp(e.target.value);

              const selectedApp = apigeeApps.data?.find(
                a => a.name === e.target.value,
              )!;

              setCredentials({
                client_id: selectedApp.consumerKey,
                client_secret: selectedApp.consumerSecret,
              });
            }}
          >
            {apigeeApps.data?.map(app => (
              <MenuItem key={JSON.stringify(app)} value={app.name}>
                {app.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};
