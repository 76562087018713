import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../../stores/rootStore';

export const APIAuthenticationPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | API Authentication | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            API Authentication
          </Typography>

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Securely authenticate and authorise your API Clients
          </Typography>
          <br />

          <Typography variant="body2">
            Our APIs use the OAuth 2.0 protocol to enable applications to
            request our client credential token using Client ID and Client
            Secret.
          </Typography>
          <br />

          <Typography variant="body2">
            The Access Token response is a JSON Web Token (JWT) and may be
            decoded to obtain expiry time (a Unix epoch timestamp) so that you
            know when to refresh the access token. Each grant_type token expiry
            is subject to change without notice. You must verify the token
            expiry and refresh tokens based on the identified value.
          </Typography>
          <br />

          <Typography variant="body2">
            Your application must provide this access token in every request to
            the APIs.
          </Typography>

          <ul>
            <li>
              <Typography variant="body2">
                All API requests must be made over HTTPS, with TLSv1.2 minimum.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                API requests without a valid Authorization header will fail.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                OAuth2 supported grant types: client_credentials,
                authorization_code, refresh_token.
              </Typography>
            </li>
          </ul>

          <br />

          <img
            src="/assets/images/CoreLogic API Authentication.png"
            alt="CoreLogic API Authentication Flow"
          />

          <br />
          <br />
          
          <Typography variant="body2">
            Follow these 4 steps to begin integration with our APIs:
          </Typography>

          <ul>
            <li>
              <Typography variant="body2">
                Retrieve the credentials (client ID and client secret) for your
                application.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Use these credentials in a request to the OAuth Token Service
                and receive an access token.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Present the access token with each request to our APIs (e.g. in
                the <b>Authorization</b> header provide "Bearer eyjsdf...").
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Refresh your access token prior to expiry for uninterrupted
                service.
              </Typography>
            </li>
          </ul>

          <br />

          <Typography variant="body2">
            For best performance, we recommend securely caching your token, and
            retrieving a new token when the current token expires.
          </Typography>
          <br />

          <Typography variant="h5" fontWeight={600} my={0.5}>
            Example API Use
          </Typography>

          <br />
          <Typography variant="h6" fontWeight={500} my={0.5}>
            Obtain an API client token
          </Typography>

          <br />

          <SyntaxHighlighter
            language="shellSession"
            style={a11yDark}
            wrapLongLines
          >
            {`curl --location --request POST 'https://api.corelogic.asia/access/as/token.oauth2?grant_type=client_credentials' \\
 --header 'Content-Length: 0' \\
 --header 'Authorization: Basic <Base64 Encoded client_id:client_secret>'
`}
          </SyntaxHighlighter>

          <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
            {`{
  "access_token": "eyJhbGxxxxxxxxxxxxxxxxxxxxxxxWQ",
  "token_type": "Bearer",
  "expires_in": 3299
}`}
          </SyntaxHighlighter>
          <br />

          <Typography variant="body2">
            <b>Note:</b> Some services, such as PSX APIs, still utilize legacy
            access tokens for API requests. To obtain one, simply change the
            endpoint from <b>/as/token.oauth2</b> to <b>/oauth/token</b>.
          </Typography>
          <br />

          <SyntaxHighlighter
            language="shellSession"
            style={a11yDark}
            wrapLongLines
          >
            {`curl --location --request POST 'https://api.corelogic.asia/access/oauth/token?grant_type=client_credentials' \\
 --header 'Content-Length: 0' \\
 --header 'Authorization: Basic <Base64 Encoded client_id:client_secret>'
`}
          </SyntaxHighlighter>
          <br />

          <Typography variant="h6" fontWeight={500} my={0.5}>
            Calling our APIs
          </Typography>
          <br />

          <SyntaxHighlighter
            language="shellSession"
            style={a11yDark}
            wrapLongLines
          >
            {`curl -X 'GET' \\
  'https://api-sbox.corelogic.asia/property/au/v2/suggest.json?q=2%20Albert%20Avenue%20Broadbeach%20QLD%204218' \\
  -H 'accept: application/json' \\
  -H 'Authorization: Bearer eyJhbGxxxxxxxxxxxxxxxxxxxxxxxWQ'
`}
          </SyntaxHighlighter>

          <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
            {`{
  "suggestions": [
    {
      "councilAreaId": 284,
      "countryId": 113,
      "isActiveProperty": true,
      "isBodyCorporate": false,
      "isUnit": false,
      "localityId": 3723,
      "postcodeId": 300303,
      "propertyId": 47872329,
      "stateId": 3,
      "streetId": 38135,
      "suggestion": "2 Albert Avenue Broadbeach QLD 4218",
      "suggestionType": "address"
    }
  ],
  "systemInfo": {
    "instanceName": "139:8080",
    "requestDate": "2024-06-26T01:50:17.949Z"
  }
}`}
          </SyntaxHighlighter>
          <br />
        </Box>
      </Box>
    </>
  );
};
