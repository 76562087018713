import { SxProps } from '@mui/material/styles';

export const styles = {
  httpMethod: {
    width: 80,
    py: 0.75,
    color: 'white',
    fontWeight: 700,
    textAlign: 'center',
    borderRadius: 1,
  },
  codeSnippet: {
    maxWidth: 768,
    padding: 24,
  },
} satisfies Record<string, SxProps>;
