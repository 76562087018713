import { ProfileInfo } from '@backstage/core-plugin-api';
import { create } from 'zustand';

interface IdentityState {
  profile?: ProfileInfo;
  setProfile: (by?: ProfileInfo) => void;
  isGuest: boolean;
  setIsGuest: (by: boolean) => void;
}

export const useIdentityStore = create<IdentityState>()(set => ({
  profile: undefined,
  setProfile: by => set(() => ({ profile: by })),
  isGuest: true,
  setIsGuest: by => set(() => ({ isGuest: by })),
}));
