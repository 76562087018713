import { create } from 'zustand';

interface RootState {
  country: 'au' | 'nz';
  isSidebarExpanded: boolean;
  setCountry: (by: 'au' | 'nz') => void;
  setIsSidebarExpanded: (by: boolean) => void;
}

const country =
  (['au', 'nz'] as const).find(c => c === localStorage.getItem('country')) ??
  'au';

const isSidebarExpanded =
  [true, false].find(v => v.toString() === localStorage.getItem('sidebarPinState')) ??
   true;

export const useRootStore = create<RootState>()(set => ({
  country,
  isSidebarExpanded,
  setCountry: by => {
    set(() => ({ country: by }));
    localStorage.setItem('country', by);
  },
  setIsSidebarExpanded: by => {
    set(() => ({ isSidebarExpanded: by }));
    localStorage.setItem('sidebarPinState', by.toString());
  },
}));
