import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { styles } from './Table.styles';

export const TableSecretData = ({ secret }: { secret: string }) => {
  const [showSecret, setShowSecret] = useState(false);

  return (
    <div style={styles.tableDataContainer}>
      <IconButton size="small" onClick={() => setShowSecret(!showSecret)}>
        {showSecret ? <VisibilityOff /> : <Visibility />}
      </IconButton>
      <span>{showSecret ? secret : '••••••••••'}</span>
    </div>
  );
};
