import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

type Props = {
  circleText: string;
  title: string;
  subtitle: string;
  description: string;
  link?: string;
  linkText?: string;
};

export const Timeline = (props: Props) => {
  return (
    <Stack flex={1} alignItems="center" gap={2}>
      <Stack direction="row" alignItems="center" width="100%">
        <Stack
          width={40}
          height={40}
          justifyContent="center"
          alignItems="center"
          p={1}
          color="#ef4400"
          border="2px solid #ef4400"
          borderRadius="100%"
        >
          <Typography fontWeight="bold">{props.circleText}</Typography>
        </Stack>

        <Box flex={1} height={2} bgcolor="#ef4400" />
      </Stack>

      <Stack pr={3.5} gap={2}>
        <Stack>
          <Typography variant="h6">{props.title}</Typography>
          <Typography fontSize={14} fontWeight="600" lineHeight={1.25}>
            {props.subtitle}
          </Typography>
        </Stack>

        {props.link && props.linkText && (
          <Link to={props.link}>
            <Button variant="contained" sx={{ fontSize: 12 }}>
              {props.linkText}
            </Button>
          </Link>
        )}

        <Typography
          color="rgba(31, 50, 62, 0.8)"
          sx={{ fontSize: { xs: 12, lg: 14 } }}
        >
          {props.description}
        </Typography>
      </Stack>
    </Stack>
  );
};
