import React, { useState } from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getApigeeApps } from '../../../api/apigee';
import { useRootStore } from '../../../stores/rootStore';
import { useIdentityStore } from '../../../stores/identityStore';
import { Field } from '../../field/Field';
import {
  getAccessToken,
  getSuggestions,
  searchProperties,
} from '../../../api/clapi';
import { useLogout } from '../../../hooks/useLogout';

const GuestAlert = () => {
  const isGuest = useIdentityStore(state => state.isGuest);
  const logout = useLogout();

  if (!isGuest) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ mb: 3 }}>
      <AlertTitle>Warning!</AlertTitle>
      You need to{' '}
      <Link
        component="button"
        sx={{ verticalAlign: 'baseline' }}
        onClick={logout}
      >
        log in
      </Link>{' '}
      to be able to find properties.
    </Alert>
  );
};

type SelectSandboxStepProps = {
  selectedAppName?: string;
  setSelectedApp: React.Dispatch<React.SetStateAction<ApigeeApp | undefined>>;
};

const SelectSandboxStep = ({
  selectedAppName = '',
  setSelectedApp,
}: SelectSandboxStepProps) => {
  const isGuest = useIdentityStore(state => state.isGuest);
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);

  const apps = useQuery({
    queryKey: ['apigeeApps'],
    queryFn: () => getApigeeApps(discovery, identity),
    enabled: !isGuest,
  });

  return (
    <>
      <GuestAlert />

      <Typography variant="h6" fontWeight={600} my={0.5}>
        1. Select one of your Sandbox client to use.
      </Typography>

      <br />

      <FormControl size="small" sx={{ width: 400 }}>
        <InputLabel id="api-client-label">
          {apps.isLoading ? 'Loading...' : 'Sandbox Clients'}
        </InputLabel>

        <Select
          data-testid="api-clients-select"
          labelId="api-client-label"
          label="Sandbox Clients"
          value={selectedAppName}
          disabled={isGuest || apps.isLoading || apps.data?.length === 0}
          onChange={e =>
            setSelectedApp(apps.data?.find(a => a.name === e.target.value))
          }
        >
          {apps.data?.map(app => (
            <MenuItem key={JSON.stringify(app)} value={app.name}>
              {app.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

type GetSuggestionsStepProps = {
  app?: ApigeeApp;
};

const GetSuggestionsStep = ({ app }: GetSuggestionsStepProps) => {
  const country = useRootStore(state => state.country);
  const alert = useApi(alertApiRef);

  const schema = yup
    .object({
      q: yup
        .string()
        .required('Search query is required.')
        .min(2, 'Search query should be atleast 2 characters.'),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const defaultValues: FormData = {
    q: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const suggestMutation = useMutation({
    mutationFn: async (q: string) => {
      const { access_token } = await getAccessToken({
        client_id: app?.consumerKey ?? '',
        client_secret: app?.consumerSecret ?? '',
      });

      const { suggestions } = await getSuggestions(country, access_token, q);

      return suggestions;
    },
    onSuccess: data => {
      if (!data) {
        alert.post({
          message: 'Failed to search address suggestions.',
          severity: 'error',
          display: 'transient',
        });
      }
    },
    onError: () =>
      alert.post({
        message: 'Failed to search address suggestions.',
        severity: 'error',
        display: 'transient',
      }),
  });

  const onSubmit = async ({ q }: FormData) => suggestMutation.mutate(q);

  return (
    <>
      <Typography variant="h6" fontWeight={600} my={0.5}>
        2. Use the Suggest API to retrieve valid IDs for{' '}
        <Typography
          component="span"
          variant="h6"
          fontWeight={600}
          color="primary.main"
        >
          properties
        </Typography>
        ,{' '}
        <Typography
          component="span"
          variant="h6"
          fontWeight={600}
          color="primary.main"
        >
          streets
        </Typography>
        ,{' '}
        <Typography
          component="span"
          variant="h6"
          fontWeight={600}
          color="primary.main"
        >
          localities
        </Typography>
        ,{' '}
        <Typography
          component="span"
          variant="h6"
          fontWeight={600}
          color="primary.main"
        >
          postcodes
        </Typography>
        , and{' '}
        <Typography
          component="span"
          variant="h6"
          fontWeight={600}
          color="primary.main"
        >
          states
        </Typography>
        .
      </Typography>

      <Stack direction="row" alignItems="center" gap={1} mt={1}>
        <Typography
          component="span"
          variant="body2"
          bgcolor="#61affe"
          color="white"
          fontWeight={600}
          py={0.75}
          px={1.75}
          borderRadius={1}
        >
          GET
        </Typography>

        <Typography variant="body2" fontWeight={500}>
          https://api-sbox.corelogic.asia/property/{country}/v2/suggest.json?q=
          {'{query}'}
        </Typography>
      </Stack>

      <br />

      <Stack
        data-testid="get-suggestions-form"
        component="form"
        direction="row"
        alignItems="flex-start"
        gap={1}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Box sx={{ width: 400 }}>
          <Controller
            name="q"
            control={control}
            render={({ field }) => (
              <Field
                {...field}
                id="search-query-input"
                size="medium"
                label="Search Query"
                error={errors.q?.message}
                touched={touchedFields.q || isSubmitted}
                disabled={!app}
              />
            )}
          />
        </Box>

        <Button
          data-testid="get-suggestions-button"
          type="submit"
          variant="contained"
          sx={{ width: 120, height: 52 }}
          disabled={!app || !watch('q') || suggestMutation.isPending}
        >
          {suggestMutation.isPending ? 'Loading...' : 'Search'}
        </Button>
      </Stack>

      {suggestMutation.data && (
        <Box maxHeight={600} overflow="auto" mt={1}>
          <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
            {suggestMutation.data.length > 0
              ? JSON.stringify(suggestMutation.data, null, 2)
              : 'No results found for your query.'}
          </SyntaxHighlighter>
        </Box>
      )}
    </>
  );
};

const SearchPropertiesStep = ({ app }: GetSuggestionsStepProps) => {
  const [selectedSearchType, setSelectedSearchType] =
    useState<PropertySearchType>('Locality');
  const country = useRootStore(state => state.country);

  const alert = useApi(alertApiRef);

  const schema = yup
    .object({
      id: yup
        .string()
        .required('ID is required.')
        .test({
          message: 'ID should be a number.',
          test: value => /^\d*$/.test(value),
        }),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const defaultValues: FormData = {
    id: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const searchMutation = useMutation({
    mutationFn: async (id: string) => {
      const { access_token } = await getAccessToken({
        client_id: app?.consumerKey ?? '',
        client_secret: app?.consumerSecret ?? '',
      });

      const {
        _embedded: { propertySummaryList },
      } = await searchProperties(country, access_token, selectedSearchType, id);

      return propertySummaryList;
    },
    onError: () =>
      alert.post({
        message: `Failed to search properties in the provided ${selectedSearchType.toLowerCase()}.`,
        severity: 'error',
        display: 'transient',
      }),
  });

  const onSubmit = async ({ id }: FormData) => searchMutation.mutate(id);

  return (
    <>
      <Stack direction="row" gap={0.66} alignItems="center">
        <Typography variant="h6" fontWeight={600} my={0.5}>
          3. Find properties in a
        </Typography>

        <FormControl size="small">
          <Select
            data-testid="search-type-select"
            variant="standard"
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: 'primary.main',
              boxShadow: 'none',
              '& .v5-MuiSelect-select': { py: 0, background: 'none' },
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            value={selectedSearchType}
            onChange={e =>
              setSelectedSearchType(e.target.value as PropertySearchType)
            }
            displayEmpty
            disableUnderline
          >
            <MenuItem value="Locality">Locality</MenuItem>
            <MenuItem value="Postcode">Postcode</MenuItem>
            <MenuItem value="Street">Street</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1} mt={1}>
        <Typography
          component="span"
          variant="body2"
          bgcolor="#61affe"
          color="white"
          fontWeight={600}
          py={0.75}
          px={1.75}
          borderRadius={1}
        >
          GET
        </Typography>

        <Typography variant="body2" fontWeight={500}>
          https://api-sbox.corelogic.asia/search/{country}/property/
          {selectedSearchType.toLowerCase()}/{'{id}'}
        </Typography>
      </Stack>

      <br />

      <Stack
        data-testid="search-properties-form"
        component="form"
        direction="row"
        alignItems="flex-start"
        gap={1}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Box sx={{ width: 400 }}>
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <Field
                {...field}
                id="search-id-input"
                size="medium"
                label={`${selectedSearchType} ID`}
                error={errors.id?.message}
                touched={touchedFields.id || isSubmitted}
                disabled={!app}
              />
            )}
          />
        </Box>

        <Button
          data-testid="search-properties-button"
          type="submit"
          variant="contained"
          sx={{ width: 120, height: 52 }}
          disabled={!app || !watch('id') || searchMutation.isPending}
        >
          {searchMutation.isPending ? 'Loading...' : 'Search'}
        </Button>
      </Stack>

      {searchMutation.data && (
        <Box maxHeight={600} overflow="auto" mt={1}>
          <SyntaxHighlighter language="json" style={a11yDark} wrapLongLines>
            {JSON.stringify(searchMutation.data, null, 2)}
          </SyntaxHighlighter>
        </Box>
      )}
    </>
  );
};

export const SandboxTestDataPage = () => {
  const [selectedApp, setSelectedApp] = useState<ApigeeApp>();
  const country = useRootStore(state => state.country);
  const isAU = country === 'au';

  return (
    <>
      <Helmet>
        <title>
          Guides | Sandbox Test Data | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Sandbox Test Data
          </Typography>

          <Typography fontWeight={500}>
            Learn how to find properties to start trying out APIs.
          </Typography>

          <br />
          <br />

          <SelectSandboxStep
            selectedAppName={selectedApp?.name}
            setSelectedApp={setSelectedApp}
          />

          <br />
          <br />
          <br />

          <GetSuggestionsStep app={selectedApp} />

          <br />
          <br />

          <SearchPropertiesStep app={selectedApp} />

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            4. Use the IDs from the property list in our{' '}
            <Link component={RouterLink} to="/apis">
              APIs
            </Link>
            .
          </Typography>

          <br />
          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            Example Sandbox Properties
          </Typography>

          <ul>
            {isAU ? (
              <>
                <li>
                  2 Albert Avenue Broadbeach QLD 4218 - Property ID: 47872329
                </li>
                <li>
                  2/10 Albert Avenue Broadbeach QLD 4218 - Property ID: 41418296
                </li>
                <li>
                  112A Bacchus Circuit Kambah ACT 2902 - Property ID: 17717000
                </li>
              </>
            ) : (
              <>
                <li>
                  407/8 Airedale Street Auckland Central Auckland - City -
                  Property ID: 17660220
                </li>
                <li>
                  18 Avon Street Parnell Auckland - City - Property ID: 15864070
                </li>
                <li>
                  4 Albert Street Island Bay Wellington City - Property ID:
                  9129284
                </li>
              </>
            )}
          </ul>

          <br />
          <br />

          <Typography variant="h6" fontWeight={600} my={0.5}>
            List of available states, suburbs, and postcodes
          </Typography>

          <br />
          
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="sandbox-property-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Country
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    State
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
                    Suburb
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isAU ? (
                  <>
                    <TableRow>
                      <TableCell rowSpan={24}>Australia</TableCell>
                      <TableCell rowSpan={3}>ACT</TableCell>
                      <TableCell>Kambah, 2902</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Monash, 2904</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Oxley, 2903</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>NSW</TableCell>
                      <TableCell>Blacktown, 2148</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Quakers Hill, 2763</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Seven Hills, 2147</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>NT</TableCell>
                      <TableCell>Durack, 0830</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Humpty Doo, 0836</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Zuccoli, 0832</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>QLD</TableCell>
                      <TableCell>Broadbeach, 4218</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Southport, 4215</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Surfers Paradise, 4217</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>SA</TableCell>
                      <TableCell>Adelaide, 5000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Glenside, 5065</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>North Adelaide, 5006</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>TAS</TableCell>
                      <TableCell>Devonport, 7310</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>La Trobe, 7307</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ulverstone, 7315</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>VIC</TableCell>
                      <TableCell>Beaconsfield, 3807</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Officer, 3809</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pakenham, 3810</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={3}>WA</TableCell>
                      <TableCell>Baldivis, 6171</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Cooloongup, 6168</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Rockingham, 6168</TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell rowSpan={5}>New Zealand</TableCell>
                      <TableCell rowSpan={5} />
                      <TableCell>Auckland Central, 1010</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parnell, 1052</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ponsonby, 1011</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Island Bay, 6023</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Saint Kilda, 9012</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
