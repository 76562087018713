import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { AsyncEntityProvider } from '@backstage/plugin-catalog-react';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { getEntities } from '../../api/catalog';

export const ProductEntityPage = () => {
  const { name } = useParams();
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const errorApi = useApi(errorApiRef);

  const {
    data: products,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['products', name],
    queryFn: () =>
      getEntities(discovery, identity, `kind=component,metadata.name=${name}`),
  });

  if (!name) {
    errorApi.post(new Error('No name provided!'));
    return <Navigate to="/" />;
  }

  return (
    <AsyncEntityProvider
      entity={products?.at(0)}
      error={error ?? undefined}
      loading={isLoading}
      refresh={refetch}
    >
      <Outlet />
    </AsyncEntityProvider>
  );
};
