import React, { PropsWithChildren } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { AsyncEntityProvider } from '@backstage/plugin-catalog-react';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { getEntities } from '../../api/catalog';

export const ApiEntityPage = ({ children }: PropsWithChildren) => {
  const { name, namespace } = useParams();
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const errorApi = useApi(errorApiRef);

  const finalName = name ?? namespace;

  const {
    data: apis,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['apis', finalName],
    queryFn: () =>
      getEntities(discovery, identity, `kind=API,metadata.name=${finalName}`),
  });

  if (!finalName) {
    errorApi.post(new Error('No name provided!'));
    return <Navigate to="/" />;
  }

  return (
    <AsyncEntityProvider
      entity={apis?.at(0)}
      error={error ?? undefined}
      loading={isLoading}
      refresh={refetch}
    >
      {children ?? <Outlet />}
    </AsyncEntityProvider>
  );
};
