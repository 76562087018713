import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { AttachMoney, HeadsetMic, SettingsOutlined } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';

type ContactProps = {
  to: string;
  label: string;
  Logo: OverridableComponent<SvgIconTypeMap>;
};

const Contact = ({ to, label, Logo }: ContactProps) => (
  <Link to={to} target="_blank">
    <Stack
      alignItems="center"
      gap={2}
      sx={{
        transition: '150ms transform',
        '&:hover': { transform: 'scale(1.1)' },
      }}
    >
      <Logo
        sx={{
          width: 160,
          height: 160,
          p: 4,
          boxShadow: 2,
          borderRadius: '100%',
          color: '#1f323e',
        }}
      />

      <Typography fontSize={28}>{label}</Typography>
    </Stack>
  </Link>
);

export const ContactUsPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Contact Us | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1}>
        <Typography
          variant="h2"
          color="primary.main"
          textAlign="center"
          fontWeight={600}
          p={4}
        >
          Contact Us
        </Typography>

        <Box flex={1}>
          <Stack
            direction="row"
            justifyContent="center"
            gap={12}
            py={8}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <Contact
              to="https://www.corelogic.com.au/software-solutions/corelogic-apis#contact-form"
              label="Sales"
              Logo={AttachMoney}
            />
            <Contact
              to="https://www.corelogic.com.au/software-solutions/corelogic-apis#contact-form"
              label="General"
              Logo={HeadsetMic}
            />
            <Contact
              to="https://dataproducts.zendesk.com/hc/en-au/requests/new?ticket_form_id=5431530664591"
              label="Technical"
              Logo={SettingsOutlined}
            />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
