import React from 'react';
import Link from '@mui/material/Link';
import {
  alertApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { requestEmailVerificationLink } from '../../api/email';

export const EmailVerificationRequest = () => {
  const discovery = useApi(discoveryApiRef);
  const identity = useApi(identityApiRef);
  const alert = useApi(alertApiRef);

  const requestEmailVerificationLinkMutation = useMutation({
    mutationFn: () => requestEmailVerificationLink(discovery, identity),
  });

  return (
    <Link
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        requestEmailVerificationLinkMutation.mutate(undefined, {
          onSuccess: () =>
            alert.post({
              message: 'Email verification sent!',
              display: 'transient',
            }),
          onError: e =>
            alert.post({
              message: isAxiosError(e)
                ? e.response?.data?.error
                : 'Something went wrong when requesting an email verification link',
              severity: 'error',
              display: 'transient',
            }),
        })
      }
    >
      Verify your email.
    </Link>
  );
};
