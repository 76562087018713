import React, { forwardRef } from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { styles } from './Field.styles';

export type FieldProps = {
  id?: string;
  name: string;
  label: string;
  value: string;
  type?: string;
  error?: string;
  touched?: boolean;
  endAdornment?: React.JSX.Element;
  onChange: (e: React.ChangeEvent) => void;
  onBlur: (e: React.FocusEvent) => void;
} & Omit<BaseTextFieldProps, 'error' | 'ref'>;

// Use forwardRef to get rid of error - 'Function components cannot be given refs.'
export const Field = forwardRef<HTMLInputElement, FieldProps>(
  (
    { id, name, error, touched, endAdornment, label, placeholder, ...props },
    ref,
  ) => (
    <TextField
      id={id}
      ref={ref}
      {...props}
      variant="outlined"
      label={label}
      placeholder={placeholder ?? label}
      error={touched && !!error}
      helperText={touched && error}
      InputProps={{ style: styles.input, endAdornment }}
      InputLabelProps={{ style: styles.input, shrink: true }}
      fullWidth
      required
    />
  ),
);
