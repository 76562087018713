import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { useRootStore } from '../../stores/rootStore';

export type Props = {
  to: string;
  label: string;
  logo?: React.ReactNode;
  activeLogo?: React.ReactNode;
  endLogo?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const SidebarLink = ({
  to,
  label,
  logo,
  activeLogo,
  endLogo,
  onClick,
}: Props) => {
  const isSidebarExpanded = useRootStore(state => state.isSidebarExpanded);

  const containerStyle: SxProps = {
    justifyContent: {
      xs: 'center',
      lg: isSidebarExpanded ? 'flex-start' : 'center',
    },
    gap: {
      xs: 0.5,
      lg: isSidebarExpanded ? 3 : 0.5,
    },
    marginLeft: {
      lg: isSidebarExpanded ? 1.5 : 0,
    },
    cursor: 'pointer',
  };

  const labelContainerStyle: SxProps = {
    alignItems: {
      xs: 'flex-start',
      lg: isSidebarExpanded ? 'center' : 'flex-start',
    },
  };

  const labelStyle: SxProps = {
    textAlign: {
      xs: 'center',
      lg: isSidebarExpanded ? 'left' : 'center',
    },
    fontSize: {
      xs: 10,
      lg: isSidebarExpanded ? 15 : 10,
    },
    whiteSpace: 'normal',
  };

  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          py="10px"
          px="12px"
          borderRadius="8px"
          sx={{
            ...containerStyle,
            backgroundColor: isActive ? 'rgba(0, 0, 0, 0.06)' : '',
            '&:hover': {
              backgroundColor: isActive
                ? 'rgba(0, 0, 0, 0.09)'
                : 'rgba(0, 0, 0, 0.03)',
            },
          }}
          onClick={onClick}
        >
          {isActive ? activeLogo : logo}

          <Stack flex={1} direction="row" sx={labelContainerStyle} className={endLogo ? 'sidebar-list': ''}>
            <Typography
              flex={1}
              fontWeight={500}
              whiteSpace="nowrap"
              sx={labelStyle}
            >
              {label}
            </Typography>

            {endLogo}
          </Stack>
        </Stack>
      )}
    </NavLink>
  );
};
