import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useRootStore } from '../../../stores/rootStore';
import _ from 'lodash';

const ChartsCustomisationTable = () => {
  const data = [
    {
      category: '',
      rows: [
        {
          parameter: 'backgroundColor',
          required: 'No',
          description: `The color of the chart's background can be customised using this parameter. The color that is passed will be the hexadecimal value, without the "#". For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color is light grey (f1f3f1).`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'backgroundColor=669933',
        },
        {
          parameter: 'borderColor',
          required: 'No',
          description: `The color of the chart's border can be customised using this parameter. The color that is passed will be the hexadecimal value, without the "#". For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color is blue (006699).\n\nNote: this will only be visible if there is a border present (see border width).`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'borderColor=669933',
        },
        {
          parameter: 'borderWidth',
          required: 'No',
          description: `The width of the chart's border can be customised to any width using this parameter.  If no border is required, then the width can be set to 0.\n\nDefault is 0, no border displayed.`,
          type: 'Integer',
          values: '0 to X',
          example: 'borderWidth=1',
        },
      ],
    },
    {
      category: 'Chart title',
      rows: [
        {
          parameter: 'titleEnabled',
          required: 'No',
          description: `The display of the chart's title can be configured to be on/off using this parameter.\n\nDefault is true.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'titleEnabled=0',
        },
        {
          parameter: 'titleAlign',
          required: 'No',
          description: `The location of the chart's title can be customised using this parameter.\n\nDefault is Left`,
          type: 'String',
          values: 'Left\n\nRight\n\nCenter',
          example: 'titleAlign=Left',
        },
        {
          parameter: 'titleBoxColor',
          required: 'No',
          description: `The background color of the title box can be customised using this parameter.  The color that is passed will be the hexadecimal value, without the "#".  For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color is black (464b4b)`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'titleBoxColor=669933',
        },
        {
          parameter: 'titleFontColor',
          required: 'No',
          description: `The font color of the chart's title can be customised using this parameter.  The color that is passed will be the hexadecimal value, without the "#".  For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color is white (ffffff)`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'titleFontColor=669933',
        },
        {
          parameter: 'titleValue',
          required: 'No',
          description:
            "The chart's title can be customised to any string using this parameter.  If this parameter is not passed, then the metric's default title will display.",
          type: 'String',
          values: '<string>',
          example: 'titleValue=Alpha House Chart',
        },
      ],
    },
    {
      category: 'Credits (aka: explanatory text)',
      rows: [
        {
          parameter: 'creditsEnabled',
          required: 'No',
          description: `The display of the chart's explanatory text can be configured to be on/off using this parameter.\n\nDefault is 1, explanatory text is displayed.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'creditsEnabled=1',
        },
        {
          parameter: 'creditsValue',
          required: 'No',
          description: `The value of the chart's explanatory text can be customised using this parameter.\n\nDefault is unique to the metric type requested.`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'creditsValue=test text here',
        },
        {
          parameter: 'creditsAlign',
          required: 'No',
          description: `The horizontal position of the chart's explanatory text can be customised using this parameter.\n\nDefault is right.`,
          type: 'String',
          values: 'center\n\nleft\n\nright',
          example: 'creditsAlign=left',
        },
        {
          parameter: 'creditsVAlign',
          required: 'No',
          description: `The vertical position of the chart's explanatory text can be customised using this parameter.\n\nDefault is bottom.`,
          type: 'String',
          values: 'top\n\nmiddle\n\nbottom',
          example: 'creditsVAlign=top',
        },
      ],
    },
    {
      category: 'Grid lines',
      rows: [
        {
          parameter: 'gridLineColor',
          required: 'No',
          description: `	The color of the chart's major grid lines can be customised using this parameter.  The color that is passed will be the hexadecimal value, without the "#".  For example, if you wanted black (#00000), you would just pass "000000"\n\nDefault color is grey (#c0c0c0).\n\nNote: this will only be visible if the grid line is enabled (see gridLineEnabled parameter).`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'gridLineColor=0000',
        },
        {
          parameter: 'gridLineDashStyle',
          required: 'No',
          description: `The style of the chart's major grid lines can be customised using this parameter.\n\nDefault is solid.`,
          type: 'String',
          values: `solid\n\nshortdash\n\nshortdot\n\nshortDashDot\n\nshortDashDotDot\n\ndot\n\ndash\n\nlongdash\n\ndashDot\n\nlongDashDot\n\nlongDashDotDot`,
          example: 'gridLineDashStyle=solid',
        },
        {
          parameter: 'gridLineEnabled',
          required: 'No',
          description: `The display of the chart's major grid lines can be configured to be on/off using this parameter.\n\nDefault is true, so grid lines are displayed.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'gridLineEnabled=0',
        },
        {
          parameter: 'minorGridLineColor',
          required: 'No',
          description: `The color of the chart's minor (secondary) grid lines can be customised using this parameter.  The color that is passed will be the hexadecimal value, without the "#".  For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color is grey (#c5c7c6).\n\nNote: this will only be visible if the minor grid line is enabled (see minorGridLineEnabled parameter).`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'minorGridLineColor=ff0000',
        },
        {
          parameter: 'minorGridLineDashStyle',
          required: 'No',
          description: `The style of the chart's minor (secondary) grid lines can be customised using this parameter.\n\nDefault is shortdash.`,
          type: 'String',
          values: `solid\n\nshortdash\n\nshortdot\n\nshortDashDot\n\nshortDashDotDot\n\ndot\n\ndash\n\nlongdash\n\ndashDot\n\nlongDashDot\n\nlongDashDotDot`,
          example: 'minorGridLineDashStyle=shortdash',
        },
        {
          parameter: 'minorGridLineEnabled',
          required: 'No',
          description: `	The display of the chart's minor (secondary) grid lines can be configured to be on/off using this parameter.\n\nDefault is false, so minor grid lines are hidden.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'minorGridLineEnabled=0',
        },
      ],
    },
    {
      category: 'Legend',
      rows: [
        {
          parameter: 'legendAlign',
          required: 'No',
          description: `The horizontal alignment of the legend can be configured using this parameter.\n\nDefault is center.`,
          type: 'String',
          values: 'center\n\nleft\n\nright',
          example: 'legendAlign=center',
        },
        {
          parameter: 'legendVAlign',
          required: 'No',
          description: `The vertical alignment of the legend can be configured using this parameter.\n\nDefault is bottom.`,
          type: 'String',
          values: 'top\n\nmiddle\n\nbottom',
          example: 'legendVAlign=top',
        },
        {
          parameter: 'legendLayout',
          required: 'No',
          description:
            'The orientation of the legend layout. By default this is set to vertical.',
          type: 'String',
          values: 'vertical\n\nhorizontal',
          example: 'legendLayout=vertical',
        },
        {
          parameter: 'legendEnabled',
          required: 'No',
          description: `The display of the legend can be configured to be on/off using this parameter.\n\nDefault is true.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'legendEnabled=0',
        },
      ],
    },
    {
      category: "Series' effects",
      rows: [
        {
          parameter: 'chartColors',
          required: 'No',
          description: `The color of the chart's individual series can be customised using this parameter.  The values passed will overwrite the defaults, but if there are not sufficient values passed (ie: < 10), then the defaults will used for the remaining.  For example, if only 2 chartColors are passed, then series 3-10 will still use the defaults.\n\nThe color that is passed will be the hexadecimal value, without the "#".  For example, if you wanted black (#00000), you would just pass "000000".\n\nDefault color scheme is #e23d28, #958377, #685745, #62b5dc, #006699, #003E51, #996699, #990066, #a3aa83, #669933, #ff9900, #ff6633`,
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'chartColors=000000,ff9900',
        },
        {
          parameter: 'chartTypeId',
          required: 'No',
          description: `	The type of the chart for ALL series requested can be specified using this parameter.\nChart options include a line, column, area, spline (smoothed out line), pie and a bar chart.\nThis value overrides the defaults of all metric type charts and it will override any custom series chart types (sN.cTId)specified.\n\nNote: metricTypeGroupId defaults will not be affected by this.`,
          type: 'Integer',
          values: '1=spline\n\n2=line\n\n3=column\n\n4=area\n\n5=pie\n\n6=bar',
          example: 'chartTypeId=1',
        },
        {
          parameter: 'columnWidth',
          required: 'No',
          description: `The width of the series' columns can be customised using this parameter. Default is a dynamic width that is applied automatically based off the number of columns appearing in the chart. The accepted columnWidth values range from 20-50. Anything outside of that range will result in the default width.\n\nNote: the columnWidth ONLY applies to column charts.`,
          type: 'Integer',
          values: '20-50',
          example: 'columnWidth=30',
        },
        {
          parameter: 'lineWidth',
          required: 'No',
          description: `The width of the series' line can be customised using this parameter. This will also automatically update the marker radius, unless markerRadius parameter value is specified.\n\nDefault is 1 and maximum is 5. Any value outside of that range will result in the default width.\n\nNote: the lineWidth ONLY applies to line, spline and area charts.`,
          type: 'Integer',
          values: '1-5',
          example: 'lineWidth=4',
        },
        {
          parameter: 'sN.cTId',
          required: 'No',
          description: `The type of the chart for each individual series can be specified using this parameter.\nChart options include a line, column, area, spline (smoothed out line), pie and bar chart.\nThis value overrides the default for the requested metric type for that individual series.\n\nDefaults will vary based on the metric type or metricTypeGroupId that is requested.\n\nNote: metricTypeGroupId defaults will not be affected by this.`,
          type: 'Integer',
          values: '1=spline\n\n2=line\n\n3=column\n\n4=area\n\n5=pie\n\n6=bar',
          example: 's1.cTId=1',
        },
        {
          parameter: 'gradientEffect',
          required: 'No',
          description: `A gradient efffect can be applied to a column chart using this parameter.\n\nDefault is false, meaning the gradient is not applied.\n\nNote: the gradient effect ONLY applies to column charts.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'gradientEffect=1',
        },
        {
          parameter: 'shadowEffect',
          required: 'No',
          description: `A shadow efffect can be applied to a column chart using this parameter.\n\nDefault is false, meaning shadow is not applied.\n\nNote: the shadow effect ONLY applies to column charts.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'shadowEffect=1',
        },
        {
          parameter: 'markerRadius',
          required: 'No',
          description: `The radius of the marker on the line, spline and area charts can be customised using this parameter.\n\nDefault is 2, however when the lineWidth parameter is passed, the markerRadius will adjust dynamically.\n\nNote: the markerRadius ONLY applies to spline, line, and area charts.`,
          type: 'Integer',
          values: '2',
          example: 'markerRadius=3',
        },
      ],
    },
    {
      category: 'X & Y Axis',
      rows: [
        {
          parameter: 'xAxisHorizontalLabel',
          required: 'No',
          description: `Display of the x axis label is by default vertical in orientation, sending in a value of 1 or True will change that orientation to horizontal display. Sending in a parameter of 0 or False or not sending in any value will keep the orientation in the default position.`,
          type: 'Boolean',
          values: 'False, True\n\n0, 1',
          example: 'xAxisHorizontalLabel=1',
        },
        {
          parameter: 'xAxisLabelEnabled',
          required: 'No',
          description: `The display of the labels on the x-axis can be configured to be on/off using this parameter.\n\nDefault is true.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'xAxisLabelEnabled=0',
        },
        {
          parameter: 'xAxisTitleEnabled',
          required: 'No',
          description: `The display of the title on the x-axis can be configured to be on/off using this parameter.\n\nDefault is true.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'xAxisTitleEnabled=0',
        },
        {
          parameter: 'xAxisTitleValue',
          required: 'No',
          description:
            "The x-axis' title can be customised to any string using this parameter.  If this parameter is not passed, then the metric's default title will display on the x-axis. The title appears horizontally below the x-axis' values.",
          type: 'String',
          values: '<string>',
          example: 'xAxisTitleValue=Count of Houses',
        },
        {
          parameter: 'yAxisLabelEnabled',
          required: 'No',
          description: `The display of the labels on the y-axis can be configured to be on/off using this parameter.\n\nDefault is true.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'yAxisLabelEnabled=0',
        },
        {
          parameter: 'yAxisTitleEnabled',
          required: 'No',
          description: `The display of the title on the y-axis can be configured to be on/off using this parameter.\n\nDefault is true, but the following logic is applied to determine if a title is displayed: if there are multiple y-axis, then display the titles on both.`,
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'yAxisTitleEnabled=0',
        },
        {
          parameter: 'yAxisTitleValue',
          required: 'No',
          description:
            "The y-axis' title can be customised to any string using this parameter.  If this parameter is not passed, then the metric's default title will display on the y-axis. The title appears vertically beside the y-axis' values.",
          type: 'String',
          values: '<string>',
          example: 'yAxisTitleValue=Report Month',
        },
        {
          parameter: 'secondaryYAxisTitleValue',
          required: 'No',
          description:
            "The secondary y-axis' title can be customised to any string using this parameter. If this parameter is not passed, then the metric's default title will display on the secondary y-axis.",
          type: 'String',
          values: '<string>',
          example: 'secondaryYAxisTitleValue=Median Sale Price',
        },
        {
          parameter: 'yAxisLineWidth',
          required: 'No',
          description:
            'The y axis line width. If yAxisLineWidth=0 the axis will be hidden.',
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'yAxisLineWidth=0',
        },
        {
          parameter: 'yAxisMinorTickWidth',
          required: 'No',
          description:
            'The y axis minor tick width. If yAxisMinorTickWidth=0 the ticks will be hidden.',
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'yAxisMinorTickWidth=0',
        },
        {
          parameter: 'xAxisLabelFontSize',
          required: 'No',
          description:
            'X axis label font size. This parameter is used to increase / decrease the font size. Default font size is 11. Min value is 10, max value is 20, any other value will default to 11.',
          type: 'Integer',
          values: '10-20',
          example: 'xAxisLabelFontSize=12',
        },
        {
          parameter: 'yAxisLabelFontSize',
          required: 'No',
          description:
            'y axis label font size. This parameter is used to increase / decrease the font size. Default font size is 11. Min value is 10, max value is 20, any other value will default to 11.',
          type: 'Integer',
          values: '10-20',
          example: 'yAxisLabelFontSize=12',
        },
      ],
    },
    {
      category: 'Label',
      rows: [
        {
          parameter: 'dataLabelFormat',
          required: 'No',
          description:
            "The label describing each point in the pie chart. Default value is: {y} which corresponds to the numerical value. To enforce the display of the '%' symbol, the percent-encoded value ‘%25’ should be passed (e.g. {y}%25). Applies to Pie Charts only.",
          type: 'String',
          values: [
            'dataLabelFormat = {y} → Display only data value',
            'dataLabelFormat = {point.name} → Display only label',
            'dataLabelFormat = {point.name}: {y} → Display label: data value',
            "dataLabelFormat = test → Will display the label as 'test'",
          ],
          example: 'dataLabelFormat={y}',
        },
        {
          parameter: 'dataLabelColor',
          required: 'No',
          description:
            'The colour applied to data labels in a pie chart. Applies to Pie Charts only.',
          type: 'String',
          values: 'e.g.\n\n000000\n\nff0000',
          example: 'dataLabelColor=F1C40F',
        },
        {
          parameter: 'dataLabelsEnabled',
          required: 'No',
          description:
            'The display of the pie chart labels. By default data labels are enabled (0). If dataLabelsEnabled=1 the labels are disabled',
          type: 'Boolean',
          values: 'True, False\n\n0, 1',
          example: 'dataLabelsEnabled=1',
        },
        {
          parameter: 'legendLabelFormat',
          required: 'No',
          description:
            'The display format of the pie chart. If not specified it will default to %7Bname%7D.',
          type: 'String',
          values: [
            "%7Bname%7D → Label's name",
            "%7Bname%7D - %7By%7D properties → Label's name - metric count properties",
          ],
          example: 'legendLabelFormat=%7Bname%7D',
        },
      ],
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Parameter
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Required
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Type
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Accepted
              <br />
              Values
            </TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'primary.main' }}>
              Example
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(section => (
            <React.Fragment key={section.category}>
              {section.category && (
                <TableRow id={_.kebabCase(section.category)}>
                  <TableCell
                    colSpan={6}
                    sx={{ fontWeight: 600, color: 'primary.main' }}
                  >
                    {section.category}
                  </TableCell>
                </TableRow>
              )}

              {section.rows.map(row => (
                <TableRow key={row.parameter}>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {row.parameter}
                  </TableCell>
                  <TableCell>{row.required}</TableCell>

                  <TableCell>
                    {row.description.split('\n').map(line => (
                      <React.Fragment key={line}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {row.type}
                  </TableCell>
                  <TableCell>
                    {Array.isArray(row.values) ? (
                      <ul style={{ paddingLeft: 12 }}>
                        {row.values.map(line => (
                          <React.Fragment key={line}>
                            <li>{line}</li>
                            <br />
                          </React.Fragment>
                        ))}
                      </ul>
                    ) : (
                      row.values.split('\n').map(line => (
                        <React.Fragment key={line}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    )}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {row.example}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ChartsCustomisationPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | Charts Customisation | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Charts Customisation
          </Typography>

          <br />

          <Typography variant="body2">
            The{' '}
            <Link
              component={RouterLink}
              to="/apis/corelogic-apis/market-insights-au#/charts"
            >
              Charts service
            </Link>{' '}
            has a number of custom parameters that can be included in the
            request which will override the default settings. Use these
            parameters if you wish to make the Chart output more consistent with
            your branding.
          </Typography>

          <ul>
            <li>
              <Link href="#example-requests">Example requests</Link>
            </li>
            <li>
              <Link href="#custom-theme-parameters">
                Custom theme parameters
              </Link>
              <ul>
                <li>
                  <Link href="#chart-title">Chart title</Link>
                </li>
                <li>
                  <Link href="#credits-aka-explanatory-text">
                    Credits (aka: explanatory text)
                  </Link>
                </li>
                <li>
                  <Link href="#grid-lines">Grid lines</Link>
                </li>
                <li>
                  <Link href="#legend">Legend</Link>
                </li>
                <li>
                  <Link href="#series-effects">Series' effects</Link>
                </li>
                <li>
                  <Link href="#x-y-axis">X & Y axis</Link>
                </li>
                <li>
                  <Link href="#label">Label</Link>
                </li>
              </ul>
            </li>
          </ul>

          <br />

          <Typography
            id="example-requests"
            variant="h5"
            fontWeight={600}
            my={0.5}
          >
            Example requests
          </Typography>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Time Series Chart - Default
            </Typography>

            <Box
              component="img"
              src="/assets/images/time-series-default.png"
              alt="Time Series Chart - Default"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/v2/chart.png?chartSize=400x700&fromDate=2013-01-01&toDate=2015-01-01&s1.lId=30522&s1.lTId=8&s1.pTId=2&s1.mTId=21&s2.lId=30634&s2.lTId=8&s2.pTId=2&s2.mTId=21'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Time Series Chart - Custom
            </Typography>

            <Box
              component="img"
              src="/assets/images/time-series-custom.png"
              alt="Time Series Chart - Custom"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/v2/chart.png?chartSize=400x700&fromDate=2013-01-01&toDate=2015-01-01&s1.lId=30522&s1.lTId=8&s1.pTId=2&s1.mTId=21&s2.lId=30634&s2.lTId=8&s2.pTId=2&s2.mTId=21&backgroundColor=000000&titleBoxColor=000000&titleAlign=Center&titleValue=Custom
              Time Series Chart
              Example&chartColors=ff9900,ff0000&lineWidth=3&yAxisTitleValue=Price&xAxisTitleValue=Month&gridLineDashStyle=dot&markerRadius=5'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Category Chart - Default
            </Typography>

            <Box
              component="img"
              src="/assets/images/category-default.png"
              alt="Category Chart - Default"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/v2/chart.png?chartSize=400x700&s1.lId=30522&s1.lTId=8&s1.pTId=2&metricTypeGroupId=1&s2.lId=30634&s2.lTId=8&s2.pTId=2'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Category Chart - Custom
            </Typography>

            <Box
              component="img"
              src="/assets/images/category-custom.png"
              alt="Category Chart - Custom"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/v2/chart.png?chartSize=400x700&s1.lId=30522&s1.lTId=8&s1.pTId=2&metricTypeGroupId=1&s2.lId=30634&s2.lTId=8&s2.pTId=2&backgroundColor=000000&titleBoxColor=000000&titleAlign=Center&titleValue=Custom
              Category Chart
              Example&chartColors=ff9900,ff0000&gradientEffect=1&xAxisHorizontalLabel=1&gridLineDashStyle=dot'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Census Pie Chart - Default
            </Typography>

            <Box
              component="img"
              src="/assets/images/CensusPieDefault.png"
              alt=" Census Pie Chart - Default"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/census?s1.lTId=8&metricTypeGroupId=107&chartSize=500x500&s1.lId=16999'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Census Pie Chart - Custom
            </Typography>

            <Box
              component="img"
              src="/assets/images/CensusPieCustom.png"
              alt=" Census Pie Chart - Custom"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              {`GET '/charts/census?s1.lId=16999&s1.lTId=8&metricTypeGroupId=107&chartSize=500x500&backgroundColor=000000&titleBoxColor=000000&titleFontColor=ffffff&titleValue=Customized Household Structure&titleAlign=center&borderColor=1B4F72&borderWidth=2&legendEnabled=false&dataLabelFormat={point.name}: {y}&chartColors=581845,900c3f,c70039,ff5733,ffc30f&dataLabelColor=ffffff'`}
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Bar Chart with negative stacks - Default
            </Typography>

            <Box
              component="img"
              src="/assets/images/BarDefault.png"
              alt="Bar Chart with negative stacks - Default"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              {`GET '/charts/census?s1.lId=3672&s1.lTId=3&chartSize=500x600&chartTypeId=6&s1.metricTypeGroupId=120&s2.metricTypeGroupId=121&s2.lId=3672&xAxisHorizontalLabel=true&s2.lTId=3&s3.lId=19028&s3.lTId=8&s4.lId=19028&s4.lTId=8&s3.metricTypeGroupId=120&s4.metricTypeGroupId=121&yAxisTitleValue=Male%26emsp; %26emsp; %26emsp; %26emsp; %26emsp; %26emsp; Female'`}
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Stack alignItems="center" gap={2}>
            <Typography variant="h6" fontWeight={500} alignSelf="flex-start">
              Bar Chart with negative stacks - Custom
            </Typography>

            <Box
              component="img"
              src="/assets/images/BarCustom.png"
              alt="Bar Chart with negative stacks - Custom"
            />

            <SyntaxHighlighter
              language="bash"
              style={a11yDark}
              customStyle={{ width: '100%', maxWidth: '85vw' }}
            >
              GET
              '/charts/census?s1.lId=3672&s1.lTId=3&chartSize=500x600&chartTypeId=6&s1.metricTypeGroupId=120&s2.metricTypeGroupId=121&s2.lId=3672&xAxisHorizontalLabel=true
              &s2.lTId=3&s3.lId=19028&s3.lTId=8&s4.lId=19028&s4.lTId=8&s3.metricTypeGroupId=120&s4.metricTypeGroupId=121&yAxisTitleValue=Male
              %26emsp; %26emsp; %26emsp; %26emsp; %26emsp; %26emsp;
              Female&backgroundColor=ffffff&titleBoxColor=000000&titleAlign=Center&titleValue=Custom
              Time Series Chart Example&chartColors=ff9900,ff0000'
            </SyntaxHighlighter>
          </Stack>

          <br />

          <Typography
            id="custom-theme-parameters"
            variant="h5"
            fontWeight={600}
            my={0.5}
          >
            Custom theme parameters
          </Typography>

          <br />

          <ChartsCustomisationTable />
        </Box>
      </Box>
    </>
  );
};
