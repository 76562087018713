import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useIdentityStore } from '../stores/identityStore';

export const useLogout = () => {
  const isGuest = useIdentityStore(state => state.isGuest);
  const identity = useApi(identityApiRef);
  const config = useApi(configApiRef);

  return () => {
    identity.signOut().then(() => {
      // remove persisted swagger authorization
      localStorage.removeItem('authorized');

      if (!isGuest) {
        const appUrl = config.getOptionalString('app.baseUrl');
        const sloUrl = config.getOptionalString('app.baseSloUrl');

        window.location.href = `${sloUrl}${appUrl}`;
      }
    });
  };
};
