import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { ProductsContent } from './ProductsContent';
import { useRootStore } from '../../stores/rootStore';

export const ProductsPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Products | CoreLogic Developer Portal | {country.toUpperCase()}
        </title>
      </Helmet>

      <Stack flex={1} p={4} gap={4}>
        <Stack>
          <Typography variant="h3" fontWeight={600}>
            Products
          </Typography>
          <Typography variant="body2" color="textSecondary" fontWeight={500}>
            Collections of APIs that allow your apps to easily connect and
            interact with other services, making it easy to add new features and
            improve functionality without having to start from scratch.
          </Typography>
        </Stack>

        <ProductsContent />
      </Stack>
    </>
  );
};
