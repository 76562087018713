import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import { ApiEntity } from '@backstage/catalog-model';
import { Progress, WarningPanel } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { apiDocsConfigRef } from '@backstage/plugin-api-docs';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../stores/rootStore';
import { formatNamespace } from '../../utilities/formatNamespace';
import { useNavigate } from 'react-router';

export const ApiOverview = () => {
  const navigate = useNavigate();

  const country = useRootStore(state => state.country);
  const countryOnRender = useRef(country);

  const { entity, loading } = useAsyncEntity<ApiEntity>();
  const { getApiDefinitionWidget } = useApi(apiDocsConfigRef);

  if (countryOnRender.current !== country) {
    countryOnRender.current = country;
    navigate('/apis');
  }

  if (loading || !entity) {
    return <Progress />;
  }

  const definitionWidget = getApiDefinitionWidget(entity);

  if (!definitionWidget) {
    return (
      <WarningPanel severity="error" title="Could not load API definition" />
    );
  }

  const namespace = formatNamespace(entity.metadata.namespace ?? '');
  const apiName = entity.metadata.title ?? entity.metadata.name;

  const title =
    entity.metadata.namespace !== 'default'
      ? `APIs | ${namespace} | ${apiName} | CoreLogic Developer Portal | ${country.toUpperCase()}`
      : `APIs | ${apiName} | CoreLogic Developer Portal | ${country.toUpperCase()}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Stack p={4}>{definitionWidget.component(entity.spec.definition)}</Stack>
    </>
  );
};
