import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Progress } from '@backstage/core-components';
import { OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { SwaggerUIProps } from 'swagger-ui-react';
import { OpenApiApplicationsSelect } from './OpenApiApplicationsSelect';
import { OpenApiGuestWarning } from './OpenApiGuestWarning';
import { useIdentityStore } from '../../stores/identityStore';

const OpenApi = (props: OpenApiWidgetProps & SwaggerUIProps) => (
  <OpenApiDefinitionWidget {...props} />
);

type OpenApiWidgetProps = {
  definition: string;
};

export const OpenApiWidget = ({ definition }: OpenApiWidgetProps) => {
  const isGuest = useIdentityStore(state => state.isGuest);

  const [credentials, setCredentials] = useState<CLAccessTokenRequest>({
    client_id: '',
    client_secret: '',
  });

  const componentsPlugin = {
    wrapComponents: {
      errors: (Errors: React.FC, system: any) => () => {
        if (process.env.NODE_ENV === 'development') {
          return <Errors {...system} />;
        }

        return null;
      },
      operations: (Operations: React.FC, system: any) => () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [showError, setShowError] = useState(false);

        let showErrorTimeout;

        if (system.specSelectors.taggedOperations().size !== 0) {
          if (showErrorTimeout) clearTimeout(showErrorTimeout);

          return <Operations {...system} />;
        }

        if (system.errSelectors.allErrors().size > 0) {
          showErrorTimeout = setTimeout(() => setShowError(true), 5000);

          if (showError) {
            return (
              <Alert severity="error">
                <AlertTitle>Warning</AlertTitle>
                Something went wrong with fetching the paths.
              </Alert>
            );
          }

          return <Progress />;
        }

        return <Progress />;
      },
      ...(isGuest && { authorizeBtn: () => () => null }),
    },
  };

  return (
    <>
      <Box sx={{ px: 2.5, m: 'auto', width: '100%' }}>
        {isGuest ? (
          <OpenApiGuestWarning />
        ) : (
          <OpenApiApplicationsSelect setCredentials={setCredentials} />
        )}
      </Box>

      <OpenApi
        key={credentials.client_id}
        definition={
          // swagger paths with $ref has value of '/api/dev-portal/docs?path={swagggerPath}'
          // dev-portal app is calling this path (it calls itself - it becomes {dev-portal-host}/api/dev-portal/docs?path={swagggerPath}) to fetch the API swagger docs
          // in order for this to work in local development, since ports are different for dev-portal UI and backend,
          // we prepend the http://localhost:8080 (backend host) to the path so it can call the dev-portal backend locally
          process.env.NODE_ENV === 'development'
            ? definition.replaceAll(
                '/api/dev-portal',
                'http://localhost:8080/api/dev-portal',
              )
            : definition
        }
        onComplete={system =>
          system.initOAuth({
            clientId: credentials.client_id,
            clientSecret: credentials.client_secret,
          })
        }
        plugins={[componentsPlugin]}
        supportedSubmitMethods={isGuest ? [] : undefined}
        persistAuthorization={!isGuest}
      />
    </>
  );
};
