import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';

export const ApplicationSilentLoginPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Guides | Application Single Sign On and Silent Login | CoreLogic
          Developer Portal
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Application Single Sign On and Silent Login
          </Typography>

          <br />
          <Typography variant="body2">
            Silent Login deep linking is an alternative authentication mechanism
            to support platforms that wish to provide their users with a simple
            way to access CoreLogic data. This allows platforms to not only
            display CoreLogic data directly within their platform, it provides
            the ability to redirect their users to RP Data or Property Guru
            websites for additional data and functionality without explicitly
            logging in. User authentication is provided from within the clients
            platform, which can then be subsequently used to avoid repeated
            login requests.
          </Typography>

          <br />
          <Typography variant="body2">
            CoreLogic also offers{' '}
            <Link
              target="_blank"
              href="https://auhelpcentre.corelogic.com.au/hc/en-au/articles/8008725409423-SAML-Configuration-for-SP-Initiated-SSO-Flow"
            >
              SSO SAML authentication capability - see the Help Centre for
              details.
            </Link>
          </Typography>

          <br />
          <Typography variant="h6" fontWeight={600} my={0.5}>
            RP.Inside User Authentication Technical Guide
          </Typography>
          <Typography variant="body2">
            <Link href="/assets/files/RPInside%20-%20User%20Auth%20and%20Silent%20Login%20V4.0.pdf" target="_blank">
              RP.Inside Authentication v4.0 PDF
            </Link>
          </Typography>

          <br />

          <Typography variant="body2">
            Silent Login and deep linking requires the end user of the
            integrating platform to be a current subscriber of RP Data or
            Property Guru, and as such that they hold a valid username, password
            and account subscription. The technical difference from API
            Authentication is obtaining a User Token via an authorization code
            grant, rather than a more limited API token client credential grant.
          </Typography>

          <br />
          <Typography variant="body2">
            <Link
              download="CL RP.Inside Auth Example_postman_collection.json"
              title="Postman Example - CL RP.Inside Auth"
              href="https://api-uat.corelogic.asia/content/docs/public/deep-link-postman.json"
            >
              This sample postman collection
            </Link>{' '}
            demonstrates Authorization Code Grant and Application Silent login
            as documented above.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
