import { SxProps } from '@mui/material/styles';

export const styles = {
  button: {
    background: 'rgb(235,71,35)',
    color: 'white',
    width: 160,
    py: 1.75,
  },
  confirmButton: {
    width: 80,
    py: 0.5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  createButton: {
    alignSelf: 'flex-end',
  },
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
    width: 512,
    marginBottom: 24,
  },
  tableContainer: {
    width: '100%',
  },
} satisfies Record<string, SxProps>;
