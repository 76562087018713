import './TermsAndConditionsBody.css';
import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const TermsAndConditionsBody = () => {
  const listNumberStyle = {
    display: 'inline-block',
    width: 0,
    marginRight: 36,
  };

  return (
    <main>
      <ul>
        <li>
          <Link href="#integrator-portal-terms">
            Integrator Portal Terms of Use
          </Link>
        </li>
        <li>
          <Link href="#sandbox-environment-terms">
            Sandbox Environment Terms and Conditions
          </Link>
        </li>
      </ul>

      <br />

      <Stack gap={1.25}>
        <Typography
          id="integrator-portal-terms"
          variant="h6"
          fontSize={18}
          fontWeight={600}
          textAlign="center"
        >
          INTEGRATOR PORTAL TERMS OF USE
        </Typography>

        {/* CLAUSE 1 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>1.</span>

          <span>BINDING TERMS</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>1.1</span>

          <Stack gap={1.25}>
            <span>This Integrator Portal is operated by:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                in New Zealand, by CoreLogic NZ Limited (NZCN 1129102); and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                in Australia, by RP Data Pty Ltd trading as CoreLogic Asia
                Pacific (ABN 67 087 759 171) (in each case, <b>“CoreLogic”</b>,{' '}
                <b>“Our”</b>,<b>“Us”</b> or <b>“We”</b>).
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>1.2</span>

          <span>
            By using this Integrator Portal you agree to be bound by an
            agreement created between you and Us under these Integrator Portal
            Terms of Use. If you do not agree to these Integrator Portal Terms
            of Use, please do not use this Integrator Portal or any information
            derived as a result of the use of this Integrator Portal.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>1.3</span>

          <span>
            This Integrator Portal provides information about the various
            property information, analytics and data-enabled products and
            services offered by CoreLogic and its related bodies corporate.
          </span>
        </Stack>

        {/* CLAUSE 2 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>2.</span>

          <span>THE PARTIES</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>2.1</span>

          <span>
            If you are a natural person, then these Integrator Portal Terms of
            Use constitute a legally binding agreement between you and us and
            govern your use of our Integrator Portal.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>2.2</span>

          <span>
            If the user of our Integrator Portal uses it in his or her capacity
            as a representative of a body corporate (eg a corporation), then it
            is a condition of the user continuing to use our Integrator Portal
            that the user warrants that he or she has authority to accept these
            Integrator Portal Terms of Use on behalf of that body corporate, and
            these Integrator Portal Terms of Use specify the terms of a legally
            binding agreement between that body corporate (and <b>“You”</b> in
            these Integrator Portal Terms of Use means that body corporate) and
            us.
          </span>
        </Stack>

        {/* CLAUSE 3 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>3.</span>

          <span>GENERAL INFORMATION</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            The information appearing in this Integrator Portal, except for when
            it is described as a contract term or disclaimer, is for general
            information only and does not constitute an offer or an intention to
            enter into a legally binding contract, other than the agreement
            between Us and You for the use of this Integrator Portal.
          </span>
        </Stack>

        {/* CLAUSE 4 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>4.</span>

          <span>INTERNATIONAL USE</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            We make no representation that this Integrator Portal or the
            CoreLogic Services accessible through it are appropriate or
            available for use in locations outside Australia and New Zealand or
            in all States and Territories of Australia. Those who choose to
            access our Integrator Portal from other locations are responsible
            for compliance with local laws. Overseas residents may contact
            CoreLogic for further assistance regarding the availability of the
            CoreLogic Services accessible through this Integrator Portal.
          </span>
        </Stack>

        {/* CLAUSE 5 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>5.</span>

          <span>AMENDMENTS</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            We reserve the right to amend these Integrator Portal Terms of Use
            from time to time. Amendments will be effective immediately upon
            publication on this Integrator Portal. Your continued use of the
            Integrator Portal following such publication will represent an
            agreement by you to be bound by these Integrator Portal Terms of
            Use, as amended.
          </span>
        </Stack>

        {/* CLAUSE 6 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>6.</span>

          <span>PERMITTED USES OF PORTAL</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.1</span>

          <Stack gap={1.25}>
            <span>You acknowledge and agree</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                You must only use this Integrator Portal to use and access
                information about the CoreLogic Services, to obtain Login
                Details as well as the Data available through Integrator Portal
                (<b>Permitted Purpose</b>) and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                You must not access or attempt to access the CoreLogic Services
                or any Data unless You have entered into a current and valid
                agreement with CoreLogic for the CoreLogic Services.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.2</span>

          <span>
            You agree that any use of this Integrator Portal, except for the
            purposes described in paragraph 6.1, is prohibited.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.3</span>

          <Stack gap={1.25}>
            <span>
              You agree that you will not and will not attempt (either yourself
              or through any third party) to:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                use this Integrator Portal or any CoreLogic Services accessible
                through it, for any purpose other than the Permitted Purpose;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                host links to this portal, unless expressly authorised by
                CoreLogic in writing;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                decompile, disassemble, reverse engineer or otherwise attempt to
                discover any part of the source code, algorithms, methods or
                processes embodied in or used, in connection with this
                Integrator Portal, the CoreLogic Services, underlying
                infrastructure or other software;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                use any manual process (such as keying-in), robot, spider,
                screen scraper, injection techniques, data aggregation tool or
                use any other device or automated process (Scraping Process) to
                data mine, scrape, crawl, email harvest, aggregate, copy or
                extract any CoreLogic Services, processes, information, content,
                or data accessible through this Integrator Portal;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                use any Scraping Process to aggregate or combine information,
                content or data contained within or accessible through this
                Integrator Portal with information, content or data accessible
                via or sourced from any third party;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(f)</span>

              <span>
                use any information on or accessed through this Integrator
                Portal for any commercial purpose (including but not limited to
                market research, the provision of pricing estimates or 'shadow
                shopping') or otherwise (either directly or indirectly) for
                profit or gain;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(g)</span>

              <span>
                use any device, software, process or routine to interfere or
                attempt to interfere with the proper working of this Integrator
                Portal or any CoreLogic Services, transaction or process being
                conducted on or through it;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(h)</span>

              <span>
                take any action that imposes an unreasonable or
                disproportionately large load on the infrastructure of or
                bandwidth connecting to this Integrator Portal;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(i)</span>

              <span>
                copy, reproduce, alter, modify, create derivative works, or
                publicly display, any part of any content from this Integrator
                Portal without the prior written permission of CoreLogic;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(j)</span>

              <span>
                use another person’s login details or otherwise use any ‘brute
                force attack’ methods to gain access to any CoreLogic Platforms,
                products or services;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(k)</span>

              <span>
                use this Integrator Portal for any unlawful purpose or activity.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.4</span>

          <span>
            You agree to indemnify CoreLogic in respect of any liability
            incurred by CoreLogic for any Loss, howsoever caused, suffered by
            you or any Related Bodies Corporate as a result of your breach of
            this clause.
          </span>
        </Stack>

        {/* CLAUSE 7 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>7.</span>

          <span>LIMITATION OF LIABILTY</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>7.1</span>

          <span>
            You are responsible for establishing such procedures for data backup
            and virus checking as You consider necessary.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>7.2</span>

          <span>
            To the maximum extent not prohibited by law, neither You nor
            CoreLogic will be liable for any Consequential Loss in connection
            with the Integrator Portal or CoreLogic Services.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>7.3</span>

          <Stack gap={1.25}>
            <span>
              You (or your representative) are solely responsible for:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                ensuring compliance with any Laws or codes governing the conduct
                of Your business in relation to its use of the CoreLogic
                Services;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                Your acts or omissions (including those of your representatives
                and any other person not within CoreLogic’s reasonable control)
                in connection with the CoreLogic Services;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                any Loss or Claim caused by Your failure to comply with Your
                obligations under this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                any delay, defects, error, faults, failure, interruption or loss
                of service that arises from equipment, data, materials or
                services supplied by You or by a third party; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                Your inability to access or use the Integrator Portal for any
                reason.
              </span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 8 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>8.</span>

          <span>ALL OTHER WARRANTIES EXCLUDED</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.1</span>

          <span>
            Except as otherwise expressly provided in this Agreement, the
            CoreLogic Services are provided to You on an ‘as is, as available’
            basis without any representations or warranties of any kind, either
            express or implied, including any implied warranties of
            merchantability, fitness for particular purposes, title,
            non-infringement, security, availability, accuracy, or otherwise.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.2</span>

          <Stack gap={1.25}>
            <span>
              CoreLogic relies on third party suppliers, including third party
              data providers, to enable it to provide You with the CoreLogic
              Services and the Data. In addition, the CoreLogic Services, and
              the Data is of a general nature and may not be tailored to suit
              your needs. As such, and without limiting the rest of this clause,
              You acknowledge:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                that the Integrator Portal, CoreLogic Services or Data may not
                meet Your requirements or be fit for Your particular purpose,
                and You are responsible for assessing whether the relevant
                CoreLogic Services or Data is appropriate for Your needs;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                that the Integrator Portal, CoreLogic Services or Data may not
                be error-free, correct, accurate, complete, reliable, current,
                or up-to-date;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                the CoreLogic website, hosted platforms, or CoreLogic Services
                may not be error free or function in an uninterrupted manner, be
                available 24 hours a day, 7 days a week, or be fully secure or
                otherwise compatible with Your computer equipment, systems,
                environment, hardware of software, and You are responsible for
                ensuring the security and compatibility of these items;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                that errors or defects may not be corrected, although CoreLogic
                will use reasonable endeavours to correct errors within a
                reasonable time;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                that, while care has been taken in preparing the statistical
                methods on which the CoreLogic Services are based, these methods
                may not use appropriate or accurate assumptions, and may not be
                fit for Your particular purpose or otherwise be suitable for
                Your use; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(f)</span>

              <span>
                the CoreLogic Services should not be construed as specific
                advice and You are responsible for making further enquiries or
                seeking professional advice before making any decisions based on
                the CoreLogic Services.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.3</span>

          <Stack gap={1.25}>
            <span>
              Where legislation implies any condition or warranty, and that
              legislation prohibits us from excluding or modifying the
              application of, or our liability under, any such condition or
              warranty, that condition or warranty will be deemed included but
              our liability will be limited (as CoreLogic may elect in its sole
              discretion) to one or more of the following:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                to use commercially reasonable efforts to modify, correct or
                provide access to the relevant CoreLogic Service;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>the supplying of the CoreLogic Services again; or</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                the payment of the cost of having the CoreLogic Services
                supplied again, except as expressly provided elsewhere in this
                Agreement.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.4</span>

          <span>
            This disclaimer set out in these terms of use does not attempt or
            purport to exclude liability arising under statute if, and to the
            extent, such liability cannot be lawfully excluded.
          </span>
        </Stack>

        {/* CLAUSE 9 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>9.</span>

          <span>SECURITY</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            Unfortunately, no data transmission over the internet can be
            guaranteed to be totally secure but we endeavour to use our
            reasonable efforts to protect the personal information of our users.
            We use a variety of physical and electronic security measures,
            including restricting physical access to our offices and firewalls
            and secure databases, to keep personal information secure from
            misuse, loss or unauthorised use or disclosure. However, CoreLogic
            will not be responsible for any unauthorised access to that
            information. If you are not comfortable sending any information over
            the Internet, please contact the privacy officer to discuss
            alternative methods for providing any information. Any personal
            information you give us will be dealt with in accordance with our
            privacy policy located at{' '}
            <Link href="https://www.corelogic.com.au/legals/privacy-policy">
              https://www.corelogic.com.au/legals/privacy-policy
            </Link>
            .
          </span>
        </Stack>

        {/* CLAUSE 10 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>10.</span>

          <span>COPYRIGHT</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>10.1</span>

          <span>
            Except where otherwise stated, CoreLogic owns all rights, title and
            interest, or have licence rights, in all Intellectual Property
            Rights in the materials contained in or published on this Integrator
            Portal and the CoreLogic Services accessible through it.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>10.2</span>

          <span>
            Except as provided by the Copyright Act 1968 (Cth) or any other
            applicable laws, no part of this Integrator Portal or the CoreLogic
            Services accessible through it may be copied, reproduced, adapted,
            modified, used to create derivative works, published or transmitted
            in any form by any process without CoreLogic’s written consent.
          </span>
        </Stack>

        {/* CLAUSE 11 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>11.</span>

          <span>TRADE MARKS</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            The trademarks, service marks and logos (<b>Trade Marks</b>) used in
            this Integrator Portal are registered or unregistered Trade Marks of
            CoreLogic or any related parties. No Trade Mark may be copied,
            reproduced, adapted or transmitted in any form or process without
            the owner’s consent.
          </span>
        </Stack>

        {/* CLAUSE 12 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>12.</span>

          <span>CONFIDENTIAL INFORMATION</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.1</span>

          <Stack gap={1.25}>
            <span>
              Confidential Information may not be disclosed by the Recipient (or
              any other user) to any person or entity except:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>the extent expressly permitted;</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <Stack gap={1.25}>
                <span>
                  on a ‘needs to know’ basis to employees, officers, directors,
                  agents, subcontractors, auditors and regulators of the
                  Recipient and even then, only to:
                </span>

                <Stack direction="row">
                  <span style={listNumberStyle}>(i)</span>

                  <span>
                    only to the extent such persons require the information for
                    the purposes of the Agreement; and
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>(ii)</span>

                  <span>
                    provided that such persons are bound by the confidentiality
                    obligations no less onerous than those imposed in this
                    clause 12;
                  </span>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                to professional advisors who are bound by the confidentiality
                obligations no less onerous than those imposed in this clause 12
                and only to the extent necessary for the advisor to provide
                advice about matters relating to this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                to the extent the User is required to do so by applicable Law;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                to the extent required to do so in connection with legal
                proceedings or enforcing its rights relating to this Agreement;
                or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(f)</span>

              <span>as otherwise agreed between the parties.</span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.2</span>

          <Stack gap={1.25}>
            <span>Each Recipient must:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                notify the Discloser as soon as reasonably practicable upon
                becoming aware of any actual or suspected breach of this clause
                12;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                in the event of disclosure under clauses 12.1(d) or 12.1(e),
                limit disclosure to the extent reasonably necessary to satisfy
                the relevant Law, regulator, judicial body or Court;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                take any action that is necessary to prevent or remedy any
                breach of the confidentiality obligations set out under this
                clause 12 or other unauthorised disclosure of Confidential
                Information by the Recipient at its own cost;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                put in place procedures and systems for preservation of
                confidentiality that are no less rigorous than those adopted by
                the Recipient for the Recipient’s own confidential information
                (which must be no less than what is considered good industry
                practice); and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                on written request from the Discloser, return and securely and
                permanently delete any Confidential Information disclosed to the
                Recipient by the Discloser within a reasonable time of being
                asked to do so and provide a written certification to the
                Discloser of this effect.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.3</span>

          <Stack gap={1.25}>
            <span>
              If You are provided with or generates Login Details in connection
              with the CoreLogic Services, You must and must ensure that any
              users must:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                keep the Login Details confidential in accordance with this
                clause 12 and ensure that the Login Details are not disclosed to
                or used by any person other than the relevant user (unauthorised
                person);
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                do not keep such Login Details in any form (whether encoded or
                un-encoded) in a location where it is capable of being copied or
                used by any unauthorised person;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                do not encourage, aide, abet or otherwise permit any
                unauthorised person to use the Login Details to access the
                CoreLogic Services; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                promptly notify CoreLogic if it becomes aware, or has reason to
                suspect, that any of the Login Details have been disclosed to,
                or used by, an unauthorised person.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.4</span>

          <span>
            You may not make press or other announcements or releases relating
            to CoreLogic and the transactions that are the subject of these
            Terms and Conditions without CoreLogic’s prior written approval
            (which may not be unreasonably withheld) as to the form and manner
            of the announcement or release, unless and to the extent that the
            announcement or release is required to be made by You by applicable
            Law or by a stock exchange with which You are listed.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.5</span>

          <span>
            The parties acknowledges that due to the unique nature of the
            Confidential Information, any breach by the Recipient of their
            obligations under this clause 12 could result in irreparable harm to
            the Discloser for which there may be no adequate remedy; and
            therefore, upon any such breach or threat thereof, the Discloser
            will be entitled to seek injunctive and other appropriate equitable
            relief (without the necessity of proving damages, or posting bond or
            other security), in addition to whatever remedies the Discloser may
            have at Law.
          </span>
        </Stack>

        {/* CLAUSE 13 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>13.</span>

          <span>GENERAL</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.1</span>

          <span>
            The agreement created under these Integrator Portal Terms of Use
            constitutes the entire agreement between the parties and supersedes
            all prior representations, agreements, statements and
            understandings, whether verbal or in writing.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.2</span>

          <span>
            No failure to exercise or any delay in exercising any right, power
            or remedy by a party operates as a waiver. A single or partial
            exercise of any right, power or remedy does not preclude any other
            or further exercise of that or any other right, power or remedy. A
            waiver is not valid or binding on the party granting the waiver
            unless it is made in writing.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.3</span>

          <span>
            We may give you notice by electronic mail, conventional mail,
            facsimile or personal service. You may give us notice by email to
            the email address specified in the “Contact Us” section of our
            Integrator Portal.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.4</span>

          <span>
            If a provision of these Integrator Portal Terms of Use is invalid,
            illegal or unenforceable, then to the extent of the invalidity,
            illegality or unenforceability, that provision must be ignored in
            the interpretation of these Integrator Portal Terms of Use. All
            other provisions of these Integrator Portal Terms of Use remain in
            full force and effect.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.5</span>

          <span>
            These Integrator Portal Terms of Use are governed by the law
            applicable in New South Wales, Australia and each party irrevocably
            and unconditionally submits to the exclusive jurisdiction of the
            courts of that State.
          </span>
        </Stack>

        {/* CLAUSE 14 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>14.</span>

          <span>DEFINITIONS</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.1</span>

          <Stack gap={1.25}>
            <span>
              In these Integrator Portal Terms of Use, the following capitalised
              terms have the meaning given below:
            </span>

            <span>
              <b>CoreLogic</b> means CoreLogic NZ Limited and RP Data Pty Ltd
              trading as CoreLogic Asia Pacific (ABN 67 087 759 171) and its
              Related Bodies Corporate.
            </span>

            <span>
              <b>CoreLogic Services</b> means the products, services, Platforms
              and Data which may be:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>licenced or supplied to You by CoreLogic</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>the Integrator Portal; or</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                information about the products, services, Platforms and Data; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                Confidential Information accessed by You on or through the
                Integrator Portal.
              </span>
            </Stack>

            <span>
              <b>Confidential Information</b> means information (in any form)
              directly or indirectly disclosed by the Discloser to the Recipient
              (regardless of whether identified as such or not) and includes:
              the terms of the commercial arrangement between You and CoreLogic,
              The Discloser’s Intellectual Property Rights, CoreLogic materials
              and any non-public or proprietary information, regardless of how
              the information is stored, which is made available to the User
              before, on or after the date of User is granted access to the
              Integrator Portal, and includes any Confidential Information, but
              does not include the Excluded Information.
            </span>

            <span>
              <b>Consequential Loss</b> means
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                special, indirect, incidental or punitive loss or damage; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                any loss of profits, revenue or savings (other than Fees payable
                by You (if any) in relation to the CoreLogic Services and any
                other amount payable by You under the Agreement), loss of
                opportunity, loss or damage to or corruption of data, loss of
                goodwill, loss of reputation; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>loss which does not arise naturally from the breach,</span>
            </Stack>

            <span>
              in each case whether arising in equity, for breach of contract,
              tort (including negligence), breach of statutory duty, indemnity
              or otherwise, but does not include loss arising from Your breach
              of clause 6, any additional charges payable by You, or a breach of
              clause 12 by either party.
            </span>

            <span>
              <b>Data</b> means any results, data or meta data, including any
              property information, property attribute data, ownership
              information, property sales information, photographs, valuation,
              market share analyses, index results, alerts, or reports, which
              are either contained within, provided through, derived from,
              captured by, entered into or generated by the Integrator Portal
              and any Platforms or as otherwise provided in the usual course of
              business by CoreLogic.
            </span>

            <span>
              <b>Discloser</b> means the party disclosing Confidential
              Information and/or personal information (as applicable) to the
              other party (or its representatives) in connection with this
              Agreement.
            </span>

            <span>
              <b>Excluded Information</b> means any information to the extent
              which:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                is in or becomes part of the public domain other than through
                breach of this Agreement or an obligation of confidence owed to
                the Discloser;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                the Recipient can prove, by contemporaneous written
                documentation, was already known to it at the time of disclosure
                by the Discloser; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                the Recipient acquired it from a source other than the
                Disclosure or any of its representatives, where such source is
                entitled to disclose it on a non-confidential basis.
              </span>
            </Stack>

            <span>
              <b>Integrator Portal</b> means the CoreLogic APIs integrator
              portal located at the domain name: developer.corelogic.asia, and
              includes all content and webpages forming part of that domain
              name, including all information, opinions, reports, blogs and Data
              published on, contained in or accessed through this portal.
            </span>

            <span>
              <b>Intellectual Property Rights</b> means all industrial and
              intellectual property rights throughout the world including
              current and future registered and unregistered rights in respect
              of trade marks, copyright, source-code, databases, Data, circuit
              layouts, designs, patents, inventions and discoveries, trade
              secrets, know-how and confidential information and all other
              intellectual property as defined in article 2 of the convention
              establishing the World Intellectual Property Organisation 1967 and
              Intellectual Property has the corresponding meaning.
            </span>

            <span>
              <b>Login Details</b> means the user names and passwords provided
              to or created by the User for use by the User’s employees for the
              purpose of accessing the Integrator Portal.
            </span>

            <span>
              <b>Loss</b> means all liabilities, losses, damages, outgoings,
              costs and expenses (including reasonable legal costs assessed on a
              Solicitor-client basis) and any penalties or fines imposed by a
              regulatory authority.
            </span>

            <span>
              <b>Permitted Purpose</b> has the meaning given to that term under
              clause 6.1.
            </span>

            <span>
              <b>Platform</b> means any web-based platform or software provided
              by CoreLogic to deliver, enable or provide its customer’s access
              to its CoreLogic Services, and includes the Integrator Portal.
            </span>

            <span>
              <b>Recipient</b> means the party receiving Confidential
              Information and/or Personal Information (as applicable) from the
              other party (or its representatives) in connection with this
              Licence.
            </span>

            <span>
              <b>Related Body Corporate</b> has the meaning given to that term
              under the Corporations Act 2001 (Cth).
            </span>
          </Stack>
        </Stack>

        <b>Currency</b>
        <span>
          Version 3 of these Integrator Portal Terms of Use are current as and
          from 13 January 2025.
        </span>
        <span>
          © 2025 CoreLogic NZ Limited and RP Data Pty Ltd trading as CoreLogic
          Asia Pacific. All rights reserved.
        </span>
      </Stack>

      <br />
      <br />

      <Stack gap={1.25}>
        <Typography
          id="sandbox-environment-terms"
          variant="h6"
          fontSize={18}
          fontWeight={600}
          textAlign="center"
        >
          SANDBOX ENVIRONMENT TERMS AND CONDITIONS
        </Typography>

        <b>Background</b>

        <Stack direction="row">
          <span style={listNumberStyle}>A.</span>

          <span>
            The User is assessing the data available through the CoreLogic APIs
            and/or considering whether it can integrate the CoreLogic APIs into
            its operations before entering into a Licence Enabler Agreement with
            CoreLogic for the supply of Services through the CoreLogic API. As a
            result the User wishes to access CoreLogic’s Sandbox Environment.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>B.</span>

          <span>
            CoreLogic has agreed to provide the User access to the Sandbox
            Environment on the terms of this Agreement.
          </span>
        </Stack>

        <b>Operative Provisions</b>

        {/* CLAUSE 1 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>1.</span>

          <span>Definitions and Interpretations</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>1.1</span>

          <Stack gap={1.25}>
            <span>
              In this Agreement unless the context otherwise requires, the
              following words will bear the meaning set out hereunder:
            </span>

            <span>
              <b>Agreement</b> means this Agreement.
            </span>

            <span>
              <b>Confidential Information</b> means information (in any form)
              directly or indirectly disclosed by the Discloser to the Recipient
              (regardless of whether identified as such or not) and includes:
              the terms of the commercial arrangement between You and CoreLogic,
              The Discloser’s Intellectual Property Rights, CoreLogic materials
              and any non-public or proprietary information, regardless of how
              the information is stored, which is made available to the User
              before, on or after the date of User is granted access to the
              Integrator Portal, and includes any Confidential Information, but
              does not include the Excluded Information.
            </span>

            <span>
              <b>Consequential Loss</b> means
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                special, indirect, incidental or punitive loss or damage; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                any loss of profits, revenue or savings (other than Fees payable
                by You (if any) in relation to the CoreLogic Services and any
                other amount payable by You under the Agreement), loss of
                opportunity, loss or damage to or corruption of data, loss of
                goodwill, loss of reputation; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>loss which does not arise naturally from the breach,</span>
            </Stack>

            <span>
              in each case whether arising in equity, for breach of contract,
              tort (including negligence), breach of statutory duty, indemnity
              or otherwise, but does not include loss arising from Your breach
              of clause 5, any additional charges payable by You, or a breach of
              clause 11 by either party.
            </span>

            <span>
              <b>CoreLogic</b> means CoreLogic NZ Limited and RP Data Pty Ltd
              trading as CoreLogic Asia Pacific (ABN 67 087 759 171) and its
              Related Bodies Corporate.
            </span>

            <span>
              <b>CoreLogic API</b> means the relevant CoreLogic application
              programming interface, referring to a set of web services and web
              service calls that can be used to provide data or other services
              (and may include, but is not limited to, the Construction API, the
              Commercial API, CLAPI).
            </span>

            <span>
              <b>Data</b> means any data or results, including any property
              information, ownership information, sales information,
              photographs, valuation or market share analyses, index results or
              alerts, contained within or provided through the CoreLogic APIs
              Sandbox Environment.
            </span>

            <span>
              <b>Discloser</b> means the party disclosing Confidential
              Information and/or personal information (as applicable) to the
              other party (or its representatives) in connection with this
              Agreement.
            </span>

            <span>
              <b>Direct Marketing</b> means using Personal Information to
              identify, target or filter and then directly market products or
              services to an identified individual or group of individuals
              (whether or not addressed to that individual by name), by means,
              normally supported by a database, which uses one or more
              advertising media to affect a measurable response or transaction
              from an individual and includes, but is not limited to,
              telemarketing, bulk email messaging, postal canvassing and list
              brokering.
            </span>

            <span>
              <b>Effective Date</b> means the date on which this Agreement
              commences as set out on Page 1 of this Agreement.
            </span>

            <span>
              <b>Excluded Information</b> means any information to the extent
              which:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                is in or becomes part of the public domain other than through
                breach of this Agreement or an obligation of confidence owed to
                the Discloser;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                the Recipient can prove, by contemporaneous written
                documentation, was already known to it at the time of disclosure
                by the Discloser; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                the Recipient acquired it from a source other than the
                Disclosure or any of its representatives, where such source is
                entitled to disclose it on a non-confidential basis.
              </span>
            </Stack>

            <b>Law:</b>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                in <b>New Zealand</b> means all applicable common law,
                principles of equity, legislation, statutes, and regulations
                (and consolidations, amendments, re-enactments or replacements
                of any of them); and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                in <b>Australia</b> means common law, principles of equity, and
                laws made by parliament (laws made by parliament include State,
                Territory and Commonwealth laws and regulations and other
                instruments under them, and consolidations, amendments,
                re-enactments or replacements of any of them).
              </span>
            </Stack>

            <span>
              <b>Login Details</b> means the client id and client secret
              credentials provided to or created by the User for use by the
              User’s Permitted User’s for the purpose of accessing the Sandbox
              Environment.
            </span>

            <span>
              <b>Malware</b> means malicious software or other computer software
              or programming code including source and object code that could
              disrupt, impair, disable or otherwise adversely affect, shut down
              or deny users (including CoreLogic) access to all or any part of
              the Sandbox Environment.
            </span>

            <span>
              <b>Party</b> means a party to this agreement as set out on page 1
              of this Agreement.
            </span>

            <span>
              <b>Permitted Purpose</b> is for the User to assess the functional
              and non-functional aspects of the CoreLogic APIs and Data through
              the CoreLogic APIs and/or to conduct tests to assess its ability
              to integrate the CoreLogic APIs into its operations or software
              prior to entering into an agreement for the supply of services
              through the relevant CoreLogic API.
            </span>

            <span>
              <b>Personal Information</b> means 'personal information' as that
              term is defined in the Privacy Act, and which a Party holds,
              creates or receives for the User’s purposes under or in connection
              with this Agreement (including information forming part of a
              database).
            </span>

            <span>
              <b>Privacy Laws</b> means all relevant or applicable privacy or
              data protection laws relating to the collection, use, processing,
              disclosure, storage or granting of access to the Personal
              Information applicable to the relevant party and includes:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                <b>Australia</b>: the Privacy Act 1988 (Cth), the Australian
                Privacy Principles, the Spam Act 2003 (Cth) and the Do Not Call
                Register Act 2006 (Cth) and any State or Territory acts and
                regulations applicable in the relevant State or Territory; and
                all applicable binding privacy codes or policies;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                <b>New Zealand</b>: the Privacy Act 2020 (NZ) and the New
                Zealand Information Privacy Principles which form part of the
                Act, and the Unsolicited Electronic Messages Act 2007; and all
                applicable binding privacy codes or policies;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                <b>UK</b>: the Data Protection Act 2018 (UK); and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                <b>European Union</b>: the EU General Data Protection Regulation
                2016/679 (repealing Directive 95/46/EC) and Privacy and
                Electronic Communications Directive 2002/58/EC (as amended by
                Directive 2009/136/EC, and as may be superseded by the EU
                ePrivacy Regulation) and all national legislation implementing
                or supplementing the foregoing and all associated codes of
                practice and other guidance issued by any applicable data
                protection authority.
              </span>
            </Stack>

            <span>
              <b>Force Majeure</b> means any circumstances beyond a party’s
              control including strikes or industrial disputes, acts of God,
              epidemics and pandemics, acts of government, declared states of
              emergency, refusal of licence, failures or outages of any
              utilities (including telecommunications and data communication
              equipment or services), denial of service attacks, fire,
              explosion, floods, cyclone, tsunami or other extreme weather
              events, aircraft unserviceability or unavailability, war,
              terrorism or civil disturbance, or impossibility of obtaining
              material and/or data.
            </span>

            <span>
              <b>Intellectual Property Rights</b> means all industrial and
              Intellectual Property Rights throughout the world including
              current and future registered and unregistered rights in respect
              of trademarks, copyright, source-code, databases, Data, circuit
              layouts, designs, patents, inventions and discoveries, trade
              secrets, know-how and confidential information and all other
              intellectual property as defined in article 2 of the convention
              establishing the World Intellectual Property Organisation 1967 and
              Intellectual Property has the corresponding meaning.
            </span>

            <span>
              <b>Licence Enabler Agreement</b> means an agreement between the
              User and CoreLogic that provides the User a licence to enable them
              to use the CoreLogic API and Data sourced through the CoreLogic
              API for commercial purposes which may include providing or
              displaying the Data to customers or other third parties.
            </span>

            <span>
              <b>Recipient</b> means the party receiving Confidential
              Information and/or Personal Information (as applicable) from the
              other party (or its representatives) in connection with this
              Agreement.
            </span>

            <span>
              <b>Sandbox Environment</b> means the environment in which the
              CoreLogic APIs may be accessed upon initial application
              provisioning. It runs off specific servers and databases for this
              purpose, and includes the CoreLogic APIs and a restricted set of
              the Data provided via the CoreLogic APIs.
            </span>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>1.2</span>

          <span>
            The headings in this Agreement are for convenience of reference only
            and will not affect the interpretation hereof. The words “include”
            and “including” when introducing an example, do not limit the
            meaning of the words to which the example relates to that example or
            examples of a similar kind. A clause or schedule is a reference to a
            clause or schedule to this Agreement. Words importing the singular
            number will include the plural and vice versa, and words importing
            the masculine gender include all other genders.
          </span>
        </Stack>

        {/* CLAUSE 2 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>2.</span>

          <span>Terms of the Agreement</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            The User’s use of and access to the Sandbox Environment is subject
            to the terms and conditions set out in this Agreement.
          </span>
        </Stack>

        {/* CLAUSE 3 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>3.</span>

          <span>Duration of User Agreement</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            This Agreement will commence on the date it is signed and will
            continue for a limited term of 6 months, which may be extended at
            the discretion of CoreLogic, or until terminated in accordance with
            clause 12.
          </span>
        </Stack>

        {/* CLAUSE 4 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>4.</span>

          <span>CoreLogic Obligations</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>4.1</span>

          <Stack gap={1.25}>
            <span>
              Subject to the terms of this Agreement and the User's performance
              of its obligations, CoreLogic will:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                make the Sandbox Environment available to the User; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                grants to the User a non-exclusive, non-transferable and
                non-sub-licensable, limited license during the Term to access
                and use the Sandbox Environment and Data accessed from the
                Sandbox Environment,
              </span>
            </Stack>

            <span>solely for the Permitted Purpose.</span>
          </Stack>
        </Stack>

        {/* CLAUSE 5 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>5.</span>

          <span>User’s Obligations</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>5.1</span>

          <Stack gap={1.25}>
            <span>
              Without detracting from clause 5.1, the User agrees that its use
              of the Sandbox Environment as a method of accessing the Data may
              only be used subject to the following:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                use and access must be strictly in compliance of the terms of
                the licence granted in this Agreement
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                the Data must be contained in the User’s system and not released
                to a customer or end user of the User’s system at any time;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                The User must not publish, distribute or in way make available
                to any third party any username and password or session tokens;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <Stack gap={1.25}>
                <span>
                  the User and any employees and representatives of the User
                  will not, nor will they encourage or permit any person or
                  entity to:
                </span>

                <Stack direction="row">
                  <span style={listNumberStyle}>i.</span>

                  <span>
                    decompile, disassemble or otherwise reverse engineer all or
                    any portion of the Data, including any source code, object
                    code, algorithms, methods or techniques used or embodied
                    therein whether by scraping, harvesting or some other means;
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>ii.</span>

                  <span>
                    use a data extraction software or programme of any kind
                    whatsoever or attempt to harvest the Data for any purpose
                    whatsoever, including for the purpose of setting up or
                    adding another database;
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>iii.</span>

                  <span>
                    distribute, commercialise or otherwise exploit the Data;
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>iv.</span>

                  <span>
                    use any Data or the Sandbox Environment for any Direct
                    Marketing purposes or to encroach upon the privacy of an
                    individual
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>v.</span>

                  <span>
                    provide access or supply the Data to a third party, whether
                    for their end use, resale or otherwise or any indirect
                    supply of Data including incorporation of the Data into any
                    deliverables intended for any third party
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>vi.</span>

                  <span>
                    remove or alter any copyright, trademark, logo or other
                    proprietary notice or label appearing on or in the Data or
                    any means of delivery of the Data; or
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>vii.</span>

                  <span>
                    incorporate any portion of the Data into any other
                    materials, products or services unless for the Permitted
                    Purpose;
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>viii.</span>

                  <span>
                    use the Data except as strictly necessary for the Permitted
                    Purpose;
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>ix.</span>

                  <span>modify the Data in any way whatsoever; or</span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>x.</span>

                  <span>
                    disclose the Data to any third party except as permitted by
                    clause 11.
                  </span>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>5.2</span>

          <Stack gap={1.25}>
            <span>The User will:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                comply with all reasonable instructions of CoreLogic regarding
                the Sandbox Environment and the Data, including any security
                policies or requirements notified to the User by CoreLogic;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                ensure it does not alter or damage the Sandbox Environment or
                the Data and will be responsible for and shall be required to
                make good at its cost any loss or damage to the Sandbox
                Environment or the Data caused by the User or its employees or
                representatives;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                report any damage to the Sandbox Environment or the Data to
                CoreLogic promptly upon becoming aware of such damage; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                take all appropriate precautions to prevent the introduction of
                Malware to the Sandbox Environment.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>5.3</span>

          <Stack gap={1.25}>
            <span>The User acknowledges and agrees that it:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                will provide CoreLogic with complete and accurate information
                regarding it and its use of and access to the Sandbox
                Environment;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                bears sole responsibility for protecting all credentials
                supplied by CoreLogic to enable the User to access the Sandbox
                Environment;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>will not provide such credentials to any third party;</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                is responsible for all use of the Sandbox Environment by the
                User or its employees or representatives; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                will ensure that its employees and representatives do not
                knowingly or recklessly allow any other third party to access
                the Sandbox Environment using their credentials. If the User
                knows or has reason to believe that there has been or is about
                to be fraudulent or other unlawful use of the Sandbox
                Environment the User must immediately notify CoreLogic.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>5.4</span>

          <span>
            The User acknowledges that CoreLogic will have no obligation to
            assist the User in using or accessing the Sandbox Environment. For
            clarity, CoreLogic will not be liable for any delay, defect,
            deficiency and/or loss of access in connection with the Sandbox
            Environment.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>5.5</span>

          <span>
            The User will permit CoreLogic to check that the Sandbox Environment
            is being used in accordance with the terms of this Agreement, and
            for such purposes the User shall maintain complete and accurate
            records of the User's use of the Sandbox Environment.
          </span>
        </Stack>

        {/* CLAUSE 6 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>6.</span>

          <span>Sandbox Environment Changes</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.1</span>

          <span>
            CoreLogic reserves the right to perform network, hardware or
            maintenance services or upgrades, change, modify, suspend or
            discontinue any or all parts of the Sandbox Environment at any time,
            but will endeavour to advise the User in advance where possible.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>6.2</span>

          <span>
            Some of the Data is sourced from third party data providers.
            CoreLogic may at any time be required to amend or delete any data
            that is sourced from a third party data provider. This means that
            material which may have previously been available and which the User
            may have accessed, may no longer be available the next time the User
            accesses the Sandbox Environment.
          </span>
        </Stack>

        {/* CLAUSE 7 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>7.</span>

          <span>No Warranty</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>7.1</span>

          <span>
            Except as otherwise expressly provided in this Agreement, the Data
            and Sandbox Environment are provided to You on an ‘as is, as
            available’ basis without any representations or warranties of any
            kind, either express or implied, including any implied warranties of
            merchantability, fitness for particular purposes, title,
            non-infringement, security, availability, accuracy, or otherwise.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>7.2</span>

          <Stack gap={1.25}>
            <span>The User acknowledges and agrees that the Data</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                is not represented to be accurate or reliable and may include
                fictional entries; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                is only a limited and incomplete set of Data for evaluation
                purposes.
              </span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 8 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>8.</span>

          <span>Liability</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.1</span>

          <span>
            You are responsible for establishing such procedures for data backup
            and virus checking as You consider necessary.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.2</span>

          <span>
            To the maximum extent not prohibited by law, neither You nor
            CoreLogic will be liable for any Consequential Loss in connection
            with the Sandbox Environment or otherwise in connection with this
            Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>8.1</span>

          <Stack gap={1.25}>
            <span>
              You (or your representative) are solely responsible for:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                ensuring compliance with any Laws or codes governing the conduct
                of Your business in relation to its use of the Sandbox
                Environment;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                Your acts or omissions (including those of your representatives
                and any other person not within CoreLogic’s reasonable control)
                in connection with the Sandbox Environment;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                any Loss or Claim caused by Your failure to comply with Your
                obligations under this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                any delay, defects, error, faults, failure, interruption or loss
                of service that arises from equipment, data, materials or
                services supplied by You or by a third party; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                Your inability to access or use the Sandbox Environment for any
                reason.
              </span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 9 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>9.</span>

          <span>Indemnity</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            The User shall indemnify and hold harmless CoreLogic and its
            directors, officers and employees (each an Indemnified Party) from
            and against any and all losses, claims, liabilities, damages, costs
            and expenses (including any direct or indirect, Consequential Loss,
            loss of profit, loss of reputation and all interest, penalties and
            legal and other professional costs and expenses) that any
            Indemnified Party may suffer or incur by reason of or in connection
            with the provision of the Sandbox Environment to the User and/or any
            breach of this Agreement by the User or any wilful, unlawful or
            negligent act or omission of the User or any of its employees,
            agents or contractors.
          </span>
        </Stack>

        {/* CLAUSE 10 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>10.</span>

          <span>Intellectual Property Rights</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle} />

          <span>
            The User acknowledges and agrees that CoreLogic (or its licensors)
            is and remains the sole and exclusive owner of all right, title and
            interest in and to the Sandbox Environment, including all material
            published in the Sandbox Environment (including, but not limited to,
            the Data).
          </span>
        </Stack>

        {/* CLAUSE 11 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>11.</span>

          <span>Confidential Information</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>11.1</span>

          <Stack gap={1.25}>
            <span>
              Confidential Information may not be disclosed by the Recipient (or
              any other user) to any person or entity except:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>the extent expressly permitted;</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <Stack gap={1.25}>
                <span>
                  on a ‘needs to know’ basis to employees, officers, directors,
                  agents, subcontractors, auditors and regulators of the
                  Recipient and even then, only to:
                </span>

                <Stack direction="row">
                  <span style={listNumberStyle}>(i)</span>

                  <span>
                    only to the extent such persons require the information for
                    the purposes of the Agreement; and
                  </span>
                </Stack>

                <Stack direction="row">
                  <span style={listNumberStyle}>(ii)</span>

                  <span>
                    provided that such persons are bound by the confidentiality
                    obligations no less onerous than those imposed in this
                    clause 11;
                  </span>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                to professional advisors who are bound by the confidentiality
                obligations no less onerous than those imposed in this clause 11
                and only to the extent necessary for the advisor to provide
                advice about matters relating to this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                to the extent the User is required to do so by applicable Law;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                to the extent required to do so in connection with legal
                proceedings or enforcing its rights relating to this Agreement;
                or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(f)</span>

              <span>as otherwise agreed between the parties.</span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>11.2</span>

          <Stack gap={1.25}>
            <span>Each Recipient must:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                notify the Discloser as soon as reasonably practicable upon
                becoming aware of any actual or suspected breach of this clause
                11;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                in the event of disclosure under clauses 11.1(d) or 11.(e),
                limit disclosure to the extent reasonably necessary to satisfy
                the relevant Law, regulator, judicial body or Court;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                take any action that is necessary to prevent or remedy any
                breach of the confidentiality obligations set out under this
                clause 11 or other unauthorised disclosure of Confidential
                Information by the Recipient at its own cost;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                put in place procedures and systems for preservation of
                confidentiality that are no less rigorous than those adopted by
                the Recipient for the Recipient’s own confidential information
                (which must be no less than what is considered good industry
                practice); and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>
                on written request from the Discloser, return and securely and
                permanently delete any Confidential Information disclosed to the
                Recipient by the Discloser within a reasonable time of being
                asked to do so and provide a written certification to the
                Discloser of this effect.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>11.3</span>

          <Stack gap={1.25}>
            <span>
              If You are provided with or generates Login Details in connection
              with the Sandbox Environment, You must and must ensure that any
              users must:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                keep the Login Details confidential in accordance with this
                clause 11 and ensure that the Login Details are not disclosed to
                or used by any person other than the relevant user (unauthorised
                person);
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                do not keep such Login Details in any form (whether encoded or
                un-encoded) in a location where it is capable of being copied or
                used by any unauthorised person;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                do not encourage, aide, abet or otherwise permit any
                unauthorised person to use the Login Details to access the
                Sandbox Environment; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                promptly notify CoreLogic if it becomes aware, or has reason to
                suspect, that any of the Login Details have been disclosed to,
                or used by, an unauthorised person.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>11.4</span>

          <span>
            You may not make press or other announcements or releases relating
            to CoreLogic and the transactions that are the subject of these
            Terms and Conditions without CoreLogic’s prior written approval
            (which may not be unreasonably withheld) as to the form and manner
            of the announcement or release, unless and to the extent that the
            announcement or release is required to be made by You by applicable
            Law or by a stock exchange with which You are listed.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>11.5</span>

          <span>
            The parties acknowledges that due to the unique nature of the
            Confidential Information, any breach by the Recipient of their
            obligations under this clause 11 could result in irreparable harm to
            the Discloser for which there may be no adequate remedy; and
            therefore, upon any such breach or threat thereof, the Discloser
            will be entitled to seek injunctive and other appropriate equitable
            relief (without the necessity of proving damages, or posting bond or
            other security), in addition to whatever remedies the Discloser may
            have at Law.
          </span>
        </Stack>

        {/* CLAUSE 12 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>12.</span>

          <span>Termination</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.1</span>

          <span>
            Either party may terminate this Agreement upon 7 days’ written
            notice.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.2</span>

          <span>
            If CoreLogic believes the User is in breach of this Agreement
            CoreLogic may immediately terminate this Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>12.3</span>

          <Stack gap={1.25}>
            <span>Upon termination of this Agreement the User will:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>immediately cease using the Sandbox Environment; and</span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                on CoreLogic’s request destroy any Confidential Information
                (including any Data) and provide CoreLogic written certification
                to that effect.
              </span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 13 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>13.</span>

          <span>Audit Rights</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.1</span>

          <span>
            CoreLogic or its representatives may, from time to time, upon
            reasonable notice in writing, conduct an audit of the User’s records
            related to the Users use of the Sandbox Environment and compliance
            with this Agreement. CoreLogic and its representatives will treat as
            confidential any Confidential Information that is contained in the
            records or other information provided by the User during such audit,
            and only use such information for the purposes of ensuring
            compliance with this Agreement, and enforcing the conditions of this
            Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.2</span>

          <Stack gap={1.25}>
            <span>The User must:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                allow persons appointed by CoreLogic to carry out the audit to
                have full access to the records of the User relating to its use
                of the CoreLogic Services for the purposes of carrying out the
                audit; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                provide all cooperation and assistance to CoreLogic as
                reasonably necessary to facilitate the conduct of the audit.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>13.3</span>

          <Stack gap={1.25}>
            <span>
              Without limitation to any other rights CoreLogic may have under
              this Agreement or at law, if an audit review discloses that the
              CoreLogic Services have been used other than in accordance with
              this Agreement, then CoreLogic may undertake any or all of the
              following actions:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                CoreLogic may seek reimbursement for its reasonable costs of the
                audit;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                If any Data were misapplied or misused then the User must,
                within 14 days of receipt of a notice, pay to CoreLogic the Fees
                in respect of the misapplied or misused Data
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>may terminate this agreement pursuant to clause 12.2.</span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 14 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>14.</span>

          <span>Privacy</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.1</span>

          <span>
            The parties must ensure that they and their Representatives comply
            with all Privacy Laws in connection with Personal Information used,
            disclosed, stored, collected or received in connection with this
            Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.2</span>

          <span>
            You warrant and agree that, if you submit Personal Information to
            CoreLogic or the Sandbox Environment, you have the consent of any
            relevant individual to use and disclose that Personal Information in
            accordance with relevant Privacy Laws, even if it is not bound by
            those laws.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.3</span>

          <Stack gap={1.25}>
            <span>
              Subject to any express rights granted under this Agreement, the
              Recipient must not (except to the extent required by Law,
              including the Privacy Laws):
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                use or disclose any Personal Information supplied to it by the
                Discloser other than for the purposes of performing or
                exercising its rights and obligations under this Agreement; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                do anything with Personal Information that will cause the
                Discloser to breach its obligations under the Privacy Laws.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.4</span>

          <Stack gap={1.25}>
            <span>
              To the extent permitted by Law and in addition to any other
              privacy obligations in this Agreement, You (or a User) must:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                not disclose, transfer, store or process Personal Information
                outside the Territory without the written consent of CoreLogic;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                handle Personal Information in a manner as reasonably directed
                by CoreLogic in writing from time to time, provided that such
                direction will not cause You to breach any Laws, including
                Privacy Laws, by which the You are bound; and
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                permanently de-identify, delete or destroy all or any
                specifically identified Personal Information that is in Your (or
                a Users) possession or control within 30 days of such request
                and provide evidence of same in the manner requested by
                CoreLogic or as otherwise agreed by the parties.
              </span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.5</span>

          <Stack gap={1.25}>
            <span>
              You must notify CoreLogic without undue delay and no less than 48
              hours after becoming aware:
            </span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                that You may be required or authorised by Law to disclose or
                provide access to any Personal Information disclosed to You by
                CoreLogic in connection with this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>
                of any request by any person to confirm whether Personal
                Information is held or to access or correct any Personal
                Information;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(c)</span>

              <span>
                of any material complaint relating to Your non-compliance with
                the Privacy Laws in respect of Personal Information collected,
                use or disclosed in connection with this Agreement;
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(d)</span>

              <span>
                of any compliance notice issued by the Privacy Regulator; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(e)</span>

              <span>of any breach of this clause 14 or any Privacy Law.</span>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.6</span>

          <span>
            The Parties agree to use Personal Information only for the purposes
            of fulfilling its obligations under this Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>14.7</span>

          <Stack gap={1.25}>
            <span>Upon request by a Party, the other Party must:</span>

            <Stack direction="row">
              <span style={listNumberStyle}>(a)</span>

              <span>
                deliver and/or return Personal Information to the requesting
                Party; or
              </span>
            </Stack>

            <Stack direction="row">
              <span style={listNumberStyle}>(b)</span>

              <span>destroy Personal Information.</span>
            </Stack>
          </Stack>
        </Stack>

        {/* CLAUSE 15 */}
        <Stack direction="row" fontWeight={600}>
          <span style={listNumberStyle}>15.</span>

          <span>Miscellaneous</span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.1</span>

          <span>
            This Agreement (together with the Application and any relevant
            amendments) shall constitute the entire agreement of the parties
            about its subject matter and supersedes all previous agreements,
            understandings and negotiations on that subject matter.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.2</span>

          <span>
            The User may not assign, transfer, novate, subcontract or otherwise
            dispose of any benefits, rights, liabilities or obligations under
            this Agreement or any part of this Agreement without the prior
            written consent of CoreLogic.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.3</span>

          <span>
            CoreLogic is permitted to assign its interests, and novate its
            obligations (without recourse by the User) under this Agreement by
            notice in writing to the User.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.4</span>

          <span>
            All provisions of this Agreement intended to survive termination or
            expiry of this Agreement shall so survive and this includes clause
            6, 8, 9, 10, 11, 12 and 13.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.5</span>

          <span>
            If one or more of the provisions of this Agreement will be invalid,
            illegal or unenforceable, the validity, existence, legality and
            enforceability of the remaining provisions will not in any way be
            affected, prejudiced or impaired.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.6</span>

          <span>
            This Agreement shall not be amended or varied in any respect except
            in writing signed by both parties.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.7</span>

          <span>
            No waiver shall be deemed to arise unless it is in writing and
            signed by the relevant party providing it.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.8</span>

          <span>
            Each party is acting as an independent contractor and nothing in
            this Agreement shall be deemed to constitute either party as an
            agent, partner or joint venturer of the other.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.9</span>

          <span>
            Any notice given under this Agreement will be in writing delivered
            to the relevant party, sent by post, or email to the address as
            contained on the front page of this Agreement or such address as may
            be notified in writing by each party to the other from time to time.
            Any such notice will be deemed received at the time when the same is
            handed to or left at the address of the party to be served and if
            served by post two days after the day of posting and by email one
            hour after transmission unless the sender receives an error message.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.10</span>

          <span>
            This Agreement may be executed in any number of the counterparts
            which, when taken together, are to constitute one and the same
            Agreement.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.11</span>

          <span>
            The parties may enter into this Agreement by sending an executed
            copy of this Agreement or counterpart by email to the other party,
            and both parties will be bound by their signatures on the email
            copy.
          </span>
        </Stack>

        <Stack direction="row">
          <span style={listNumberStyle}>15.12</span>

          <span>
            This Agreement is governed by and is to be interpreted in accordance
            with the laws of New South Wales, Australia. Each party hereby
            submits to the non-exclusive jurisdiction of the Australian courts
            in all matters relating to this Agreement.
          </span>
        </Stack>

        <b>Currency</b>

        <span>
          Version 6 of these Sandbox Environment Terms and Conditions are
          current as and from 13 January 2025.
        </span>

        <span>
          © 2025 CoreLogic NZ Limited and RP Data Pty Ltd trading as CoreLogic
          Asia Pacific. All rights reserved.
        </span>
      </Stack>
    </main>
  );
};
