import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { useRootStore } from '../../../stores/rootStore';

export const MappingServicesPage = () => {
  const country = useRootStore(state => state.country);

  return (
    <>
      <Helmet>
        <title>
          Guides | Mapping Services | CoreLogic Developer Portal |{' '}
          {country.toUpperCase()}
        </title>
      </Helmet>

      <Box flex={1} bgcolor="white">
        <Box maxWidth={1440} m="auto" px={4} pt={4} pb={8}>
          <Typography
            variant="h3"
            color="primary.main"
            fontWeight={600}
            my={0.5}
          >
            Mapping Services
          </Typography>

          <Typography fontWeight={500}>
            Easily integrate CoreLogic’s premium geospatial data to your GIS
            system, website or app with our web mapping services.
          </Typography>

          <br />
          <br />

          <Typography variant="body2">
            Visualise detailed geospatial information including basemaps, aerial
            imagery, physical addresses, roads and a range of geospatial
            overlays to support your business needs. Before integrating with our
            geospatial capability here are a few things you should know:
          </Typography>

          <br />

          {country === 'au' ? (
            <Link
              href="/assets/files/AU Geospatial Catalog.pdf"
              target="_blank"
            >
              AU Geospatial Catalog.pdf
            </Link>
          ) : (
            <>
              <Link
                href="/assets/files/Address API Reference guide.pdf"
                target="_blank"
              >
                Address API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Basemaps API Reference guide.pdf"
                target="_blank"
              >
                Basemaps API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/CoreLogic Geospatial Service Catalogue.pdf"
                target="_blank"
              >
                CoreLogic Geospatial Service Catalogue.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/CoreLogic Parcel Overlay Annotation API Reference guide.pdf"
                target="_blank"
              >
                CoreLogic Parcel Overlay Annotation API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Flood API Reference guide.pdf"
                target="_blank"
              >
                Flood API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Geocoding API Reference guide.pdf"
                target="_blank"
              >
                Geocoding API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Greenfields API Reference guide.pdf"
                target="_blank"
              >
                Greenfields API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Logistics Network API Reference guide.pdf"
                target="_blank"
              >
                Logistics Network API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Routing API Reference guide.pdf"
                target="_blank"
              >
                Routing API Reference guide.pdf
              </Link>
              <br />
              <Link
                href="/assets/files/Suburb API Reference guide.pdf"
                target="_blank"
              >
                Suburb API Reference guide.pdf
              </Link>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
