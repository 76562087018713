import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import { SxProps } from '@mui/material/styles';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { SidebarLink, Props as SidebarLinkProps } from './SidebarLink';
import { useRootStore } from '../../stores/rootStore';

type Props = {
  children?: React.ReactNode;
} & SidebarLinkProps;

export const SidebarList = ({
  to,
  label,
  logo,
  activeLogo,
  children,
}: Props) => {
  const { pathname } = useLocation();
  const isSidebarExpanded = useRootStore(state => state.isSidebarExpanded);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLDivElement | SVGElement | null
  >(null);

  const chevronStyle: SxProps = {
    width: {
      xs: 16,
      lg: isSidebarExpanded ? 28 : 16,
    },
    height: {
      xs: 16,
      lg: isSidebarExpanded ? 28 : 16,
    },
    transform: {
      lg: `rotate(${expanded ? 90 : 0}deg)`,
    },
    p: {
      lg: isSidebarExpanded ? '4px' : 0,
    },
    '&:hover': {
      backgroundColor: {
        lg: isSidebarExpanded ? 'rgba(0, 0, 0, 0.06)' : '',
      },
    },
    borderRadius: '50%',
  };

  const insideListContainerStyle: SxProps = {
    display: {
      xs: 'none',
      lg: isSidebarExpanded ? 'flex' : 'none',
    },
  };

  const outsideListContainerStyle: SxProps = {
    display: {
      xs: 'flex',
      lg: isSidebarExpanded ? 'none' : 'flex',
    },
    mx: 2,
  };

  const listStyle: SxProps = {
    '& p': {
      textAlign: 'left',
      fontSize: 13,
      fontWeight: 400,
    },
  };

  useEffect(() => {
    if (isSidebarExpanded && to) {
      setExpanded(`${pathname}/`.startsWith(`${to}/`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <SidebarLink
        to={to}
        label={label}
        logo={logo}
        activeLogo={activeLogo}
        endLogo={
          <KeyboardArrowRightOutlined
            data-testid='chevron-right'
            sx={chevronStyle}
            onClick={e => {
              // prevent navigating to link
              e.preventDefault();
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setExpanded(!expanded);
            }}
          />
        }
        onClick={e => {
          setAnchorEl(e.currentTarget);
          setExpanded(true);
        }}
      />

      {/* The list inside the sidebar (when sidebar is expanded) */}
      {isSidebarExpanded && expanded && (
        <Stack direction="row" mt={0.5} pl={4} sx={insideListContainerStyle}>
          <Box
            my={1}
            borderLeft="1px solid rgba(0, 0, 0, 0.2)"
            borderRadius={8}
          />

          <Stack flex={1} gap={0.5} sx={listStyle}>
            {children}
          </Stack>
        </Stack>
      )}

      {/* The list outside the sidebar (when sidebar isn't expanded) */}
      <Popover
        open={expanded}
        anchorEl={anchorEl}
        onClose={() => setExpanded(false)}
        sx={outsideListContainerStyle}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
      >
        <Box px={0.5} py={1}>
          <Stack flex={1} sx={listStyle}>
            {/* hacky way to know if the clicked element is another sidebar list so it doesn't close the previous list */}
            {Array.isArray(children) &&
              children.map(child => (
                <Box
                  key={child.key}
                  onClick={e => {
                    const target = e.target as typeof e.currentTarget;

                    if (
                      !target.innerHTML.includes('sidebar-list') &&
                      target.nodeName !== 'svg' &&
                      target.nodeName !== 'path' &&
                      target.nextSibling === null
                    ) {
                      setExpanded(false);
                    }
                  }}
                >
                  {child}
                </Box>
              ))}
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
};
